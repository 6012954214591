import React, { FC, useEffect, useState } from "react";
import Papa, { ParseResult } from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Box } from "common";
import { sendResoures, setPromotionObject } from "store/actions/promotion.actions";
import { IState } from "store/store.interface";
import { FILE_UPLOAD_ERROR } from "store/store.types";

interface IProps {
  name?: string;
  disabled?: boolean,
  onCompleteUpload: (result: ParseResult<any>) => void;
  setError: (result: any) => void;
  fileSelected?: boolean;
  buttonTitle?: string;
  label?: string;
  testId?: string;
  size?: "small" | "medium";
  error?: string;
}
export const FileUpload: FC<IProps> = ({
  name,
  disabled,
  onCompleteUpload,
  setError,
  buttonTitle,
  label,
  fileSelected,
  testId,
  size = "medium",
  error,
}) => {
  
  const dispatch = useDispatch();
  const { resourceData } = useSelector((state: IState) => state.promotion);
  const { FileUploadMessage } = useSelector((state: IState) => state.promotion);
  const hiddenFileInput = React.useRef<any>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selected, setSelected] = useState(fileSelected || false);

  useEffect(() => {
    if(resourceData !== null) {
      dispatch(setPromotionObject(resourceData?.id, ["restrictions", "customerEmailGroup"]));
    }
  }, [dispatch, resourceData])
  
  useEffect(()=>{
    if(FileUploadMessage){
      if(name === 'customerEmailGroup'){
        setError(FileUploadMessage);
      }
    }
  },[FileUploadMessage,setError,name]);
  
  const onComplete = (result: ParseResult<any>) => {
    setSelected(true);
    setIsUploading(false);
    onCompleteUpload(result);
  };
  
  const importCSV = (csv: any) => {
    setIsUploading(true);
    Papa.parse(csv, {
      worker: true,
      complete: onComplete,
      skipEmptyLines: true,
    });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleChange = async (event: any) => {
    if (event.target.files[0]) {
      if(event.target.files[0]?.type.includes('csv')) {
        setError('');
        dispatch({
          type:FILE_UPLOAD_ERROR,
          payload:null
        })
        if(name === 'customerEmailGroup'){
          dispatch(setPromotionObject(null, ["restrictions", "customerEmailGroup"]));
          setIsUploading(true);
          const filePayload = new FormData();
          filePayload.append('type', 'email');
          filePayload.append('file', event.target.files[0], event.target.files[0].name);
          dispatch(sendResoures(filePayload));
          setSelected(true);
          setIsUploading(false);

        } else {
          importCSV(event.target.files[0]);
        }
      } else {
        setError('Please select .csv file')
      }
    }
  };

  return (
    <>
    <Box
      display="flex"
      alignItems="center"
      alignContent="center"
      width="100%"
      data-testid={testId || "file-upload-wrapper"}
    >
      <Input
        disabled={true}
        type="text"
        label={label}
        fontSize="16px"
        variant="filled"
        value={selected ? "File has been selected" : ""}
        onChange={onChangeHandler}
        size={size}
        width="100%"
        fullWidth
        error={!!error}
        errorMessage={error}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        accept=".csv"
        data-testid='input-file-upload'
      />
      <Button
        onClick={handleClick}
        margin="0 0 0 10px"
        round
        fontSize="12px"
        padding="10px"
        disabled={(isUploading || disabled)}
        variant="contained"
        color="primary"
        height="30px"
      >
        {buttonTitle || "Upload"}
      </Button>
    </Box>
    </>
  );
};

export default FileUpload;

const COLORS: { [key: string]: string } = {
  GRAY_SCALE_500: '#747474',
  ERROR_RED: '#CE3637',
  MINE_SHAFT: '#252525',
  BLACK:"#000",
  AQUA: '#0A437C',
  LIGHT_GRAY: '#EAEAEA',
  BLUE:'#0E5AA7',
  WHITE:'#fff'
  };
  
  export default COLORS;
  
import React, { useEffect } from "react";
import { Route, Switch } from "wouter";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Logout from "./pages/Logout";
import PromotionCreate from "./pages/PromotionCreate";
import Summary from "./pages/Summary";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { validateSession } from "store/actions/auth.actions";
import { useLocationUpdate } from "hooks";
import { AUTH_VERIFY_SESSION } from "store/store.types";
import { IState } from "store/store.interface";
import { getAppConfig } from "store/actions/config.actions";
import { apiEnd } from "store/actions/api.actions";
import MyProfile from "pages/MyProfile";
import Users from "pages/Users";

const App = () => {
  const dispatch = useDispatch();

  const { selectOptions, isAuthenticated } = useSelector((state: IState) => ({
    ...state.config,
    ...state.auth,
  }));

  useLocationUpdate();

  useEffect(() => {
    if (!selectOptions.countries && isAuthenticated) {
      dispatch(getAppConfig());
    }
  }, [selectOptions, dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(validateSession());
    } else {
      dispatch(apiEnd(AUTH_VERIFY_SESSION));
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <ProtectedRoute path="/" component={Dashboard} />
      <ProtectedRoute path="/promotion-create" component={PromotionCreate} />
      <ProtectedRoute path="/summary" component={Summary} />
      <ProtectedRoute path="/my-profile" component={MyProfile} />
      <ProtectedRoute path="/users" component={Users}  />
    </Switch>
  );
};

export default App;

import React from "react";
import { Text, Tooltip } from "common";

const variantDetails: { [key: string]: any } = {
  bold: 600,
  default: 500,
  blue: "#0E5AA7",
};
const UserHeaderTemplate = ({
  title,
  variant = "default",
  colorVariant = "#000",
  style,
  showTooltip = false,
}: {
  title: string;
  variant?: string;
  colorVariant?: string;
  style?: any;
  showTooltip?: boolean;
}) => {
  return (
    <Text
      fontSize="14px"
      fontWeight={variantDetails[variant]}
      lineHeight="16px"
      color={variantDetails[colorVariant]}
      style={style}
    >
      {showTooltip && title.length > 20 ? (
        <Tooltip title={title} arrow placement="top" blackThemed={true}>
          <> {title.substring(0, 20) + "..."} </>
        </Tooltip>
      ) : (
        title
      )}
    </Text>
  );
};

export default UserHeaderTemplate;

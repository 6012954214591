import React, { FC, useState, useMemo, useCallback } from "react";
import { Box, Button, Input, Snackbar, Text } from "common";
import { CarrefourLogo } from '@mafc/icon';
import { useApiInfo } from "hooks";
import { AUTH_GET_USER, AUTH_LOGIN } from "store/store.types";
import { EStores } from "store/reducers/auth.reducers";
import { SelectCountry, Badge } from "custom";
import Section from "Layout/Section";
import { useStyles } from "./Login.style";
import mafLogo from "assets/mafLogo.svg";
import { useDispatch } from "react-redux";
import { clearApi } from "store/actions/api.actions";

interface IProps {
  onNextHandler: (values: TLogin) => void;
  onCountrySelection: (storeId: string) => void;
  action: EAction;
  stores?: string[];
  role: string;
  name?: string;
}

export type TLogin = {
  email: string;
  password: string;
};

export enum EAction {
  "Auth",
  "CountrySelect",
}

const Login: FC<IProps> = ({
  onNextHandler,
  onCountrySelection,
  action,
  role,
  name = "",
}) => {
  const {
    dropDownWrapper,
    carrefourWrapperLogo,
    container,
    wrapper,
    boxWrapper,
    footerWrapper,
  } = useStyles();

  const dispatch = useDispatch();
  const [values, setValues] = useState<TLogin>({ email: "", password: "" });

  const [storeId, setStoreId] = useState("");

  const { isLoading: isLoginLoading = false, error: loginError } =
    useApiInfo(AUTH_LOGIN);
  const { isLoading: isGetUserLoading = false, error: getUserError } =
    useApiInfo(AUTH_GET_USER);

  const onCloseError = () => {
    dispatch(clearApi(AUTH_LOGIN));
    dispatch(clearApi(AUTH_GET_USER));
  };

  const isLoading = useMemo(
    () => isLoginLoading || isGetUserLoading,
    [isGetUserLoading, isLoginLoading]
  );

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setValues((values) => ({ ...values, [name]: value }));
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      onNextHandler(values);
    },
    [values, onNextHandler]
  );

  const onCountrySelectionNextHandler = useCallback(() => {
    if (storeId) {
      onCountrySelection(storeId as EStores);
    }
  }, [onCountrySelection, storeId]);

  const onChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStoreId(event.target.value);
  };

  const FieldsSet = useMemo(() => {
    if (action === EAction.CountrySelect) {
      return (
        <div>
          <div className={carrefourWrapperLogo}>
            <CarrefourLogo size="CUSTOM" fill="#10469b"/>
          </div>
          <Badge as={role} width="100px" height="22px" />
          <Text fontSize="16px" marginY="15px" fontWeight={600}>
            Welcome {name}
          </Text>
          <Box className={dropDownWrapper}>
            <SelectCountry
              onChangeCountry={onChangeCountry}
              label="Please Select Country"
              isTransparent={false}
              value={storeId}
              disableUnderLine={false}
              size="medium"
              width="100%"
              inputStartPadding="8px"
            />
          </Box>
          <Text color="text.secondary" fontSize="10px" fontWeight={500}>
            Country code promotions are restricted to 1 country
          </Text>
          <Box display="grid">
            <Button
              data-testid="country-selection-button"
              variant="outlined"
              round
              margin="15px 0"
              width="100%"
              onClick={onCountrySelectionNextHandler}
              color="primary"
            >
              Continue
            </Button>
          </Box>
        </div>
      );
    }
    return (
      <>
        <div className={carrefourWrapperLogo}>
          <CarrefourLogo size="CUSTOM" fill="#10469b" />
        </div>
        <Text fontSize="16px" fontWeight={600} marginTop="15px">
          Enter Details
        </Text>
        <Box gridTemplateColumns="1fr" gridGap="20px" marginY="20px">
          <Input
            name="email"
            label="Email"
            fontSize="16px"
            type="email"
            onChange={onChangeHandler}
            size="medium"
            width="100%"
            id="email-input-text"
            testId="email-text-input"
          />
          <Input
            name="password"
            label="Password"
            fontSize="16px"
            type="password"
            onChange={onChangeHandler}
            size="medium"
            width="100%"
            id="password-input-text"
          />
          <Button
            variant="outlined"
            round
            width="100%"
            type="submit"
            isLoading={isLoading}
            color="primary"
          >
            Continue
          </Button>
        </Box>
      </>
    );
  }, [
    action,
    isLoading,
    name,
    onCountrySelectionNextHandler,
    role,
    dropDownWrapper,
    storeId,
    carrefourWrapperLogo,
  ]);

  return (
    <Section height="100vh" margin="0px" width="100%" maxWidth="100%">
      <form
        className={container}
        onSubmit={onSubmitHandler}
        data-testid="login-form"
      >
        <div className={wrapper} data-testid="login-box-wrapper">
          <div className={boxWrapper}>
            {(loginError || getUserError) && (
              <Snackbar
                variant="standard"
                severity="error"
                vertical="top"
                horizontal="center"
                open={!!loginError || !!getUserError}
                handleClose={onCloseError}
              >
                {loginError || getUserError}
              </Snackbar>
            )}
            {FieldsSet}
          </div>
          <div className={footerWrapper}>
            <img src={mafLogo} alt="MAF Logo" />
          </div>
        </div>
      </form>
    </Section>
  );
};

export default Login;

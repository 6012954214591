import { endpoints } from "constant";
import {
  apiAction,
  replaceUrlVariables,
} from "store/middlewares/api.middleware.helper";
import { EMethods, IAction } from "store/store.interface";
import { ADD_NEW_USER, DELETE_USER_BY_ID, GET_USERS, GET_USER_BY_ID, SET_USERS, UPDATE_USER_BY_ID } from "store/store.types";
import { Dispatch } from "redux";

export const getUsers =
  (page: number, params: string = "") =>
  (dispatch: Dispatch<any>) => {
    const endpoint =
      replaceUrlVariables(endpoints.GET_USERS_LIST, { page }) + params;
    dispatch(
      apiAction({
        label: GET_USERS,
        method: EMethods.GET,
        url: endpoint,
        isAbsoluteUrl: false,
        data: {},
        withToken: true,
        onSuccess: ({ data }) => ({
          type: SET_USERS,
          payload: { data, page, params },
        }),
      })
    );
  };

export const deleteUserById = (id: string) => (dispatch: Dispatch<any>) => {
  const endpoint = replaceUrlVariables(endpoints.DELETE_USER_BY_ID, { id });
  dispatch(
    apiAction({
      label: DELETE_USER_BY_ID,
      method: EMethods.DELETE,
      url: endpoint,
      isAbsoluteUrl: false,
      data: {},
      withToken: true,
      onSuccess: () => ({
        type: DELETE_USER_BY_ID,
        payload: id,
      }),
    })
  );
};

export const addNewUser = (data:any) => (dispatch: Dispatch<any>) => {
  dispatch(
    apiAction({
      label: ADD_NEW_USER,
      method: EMethods.POST,
      url: endpoints.ADD_NEW_USER,
      isAbsoluteUrl: false,
      data,
      withToken: true,
      onSuccess: ({ data }) => ({
        type: ADD_NEW_USER,
        payload: data,
      }),
    })
  );
};

export const getUserById = (id: string): IAction => ({
  type: GET_USER_BY_ID,
  payload: id,
});

export const updateUserById = (data:any,id: string) => (dispatch: Dispatch<any>) => {
  const endpoint = replaceUrlVariables(endpoints.UPDATE_USER_BY_ID, { id });
  dispatch(
    apiAction({
      label: UPDATE_USER_BY_ID,
      method: EMethods.PUT,
      url: endpoint,
      isAbsoluteUrl: false,
      data,
      withToken: true,
      onSuccess: ({data}) => ({
        type: UPDATE_USER_BY_ID,
        payload:{data,id},
      }),
    })
  );
};
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EInputSize, EInputVariant } from "./common.interface";
export interface IInputStyle {
  fontColor?: string;
  fontSize?: string;
  fontWeight?: number;
  width?: string;
  minWidth?: string;
  height?: string;
  disableUnderline?: boolean;
  isSelect?: boolean;
  isLabeled?: boolean;
  inputStartPadding?: string;
  size?: EInputSize;
  inputVariant?: EInputVariant;
  isTransparent?: boolean;
  letterSpacing?: string;
  multiline?: boolean;
  rowsMax?: string | number;
  stickyLabel?: boolean;
}

export const useStyleLabelInput = makeStyles(({ palette }: Theme) => ({
  root: {
    fontSize: ({ fontSize }: IInputStyle) => `${fontSize} !important`,
    transform: ({ size }) => {
      switch (size) {
        case "small":
          return "translate(30px, 12px) scale(1) !important";
        case "medium":
          return "translate(30px, 16px) scale(1) !important";
        case "tiny":
          return "translate(18px, 7px) scale(1) !important";
        default:
          return "translate(30px, 22px) scale(1) !important";
      }
    },
  },
  shrink: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "250px",
    fontSize: "8px",
    transform: ({ size, inputVariant, stickyLabel }) => {
      if (stickyLabel) {
        switch (size) {
          case "small":
            return "translate(30px, 12px) scale(1) !important";
          case "medium":
            return "translate(30px, 16px) scale(1) !important";
          case "tiny":
            return "translate(20px, 9px) scale(1) !important";
          default:
            return "translate(30px, 22px) scale(1) !important";
        }
      }
      if (inputVariant === "outlined") {
        return "translate(14px, -6px) scale(0.75) !important";
      } else {
        switch (size) {
          case "small":
            return "translate(30px, 2px) scale(0.75) !important";
          case "medium":
            return "translate(30px, 3px) scale(0.75) !important";
          case "tiny":
            return "translate(30px, 1px) scale(0.75) !important";
          default:
            return "translate(30px, 5px) scale(0.75) !important";
        }
      }
    },
  },
  error: {
    color: `${palette.error.main} !important`,
  },
}));

export const useStyleFilledInput = makeStyles(({ palette }: Theme) => ({
  root: {
    letterSpacing: ({ letterSpacing = "normal" }) => letterSpacing,
    minWidth: ({ minWidth = "auto" }) => minWidth,
    width: ({ width = "auto" }) => width,
    color: ({ fontColor = palette.common.black }: IInputStyle) => fontColor,
    height: ({ size, multiline, rowsMax }) => {
      if (multiline) {
        const convertedMaxRows = Number(rowsMax);
        if (rowsMax && !isNaN(convertedMaxRows)) {
          switch (size) {
            case "small":
              return `${convertedMaxRows * 40}px`;
            case "medium":
              return `${convertedMaxRows * 50}px`;
            case "tiny":
              return `${convertedMaxRows * 30}px`;
            default:
              return `${convertedMaxRows * 60}px`;
          }
        }
      }
      switch (size) {
        case "small":
          return "40px";
        case "medium":
          return "50px";
        case "tiny":
          return "30px";
        default:
          return "60px";
      }
    },
    backgroundColor: ({ isTransparent }) =>
      isTransparent ? "inherit" : "#F8FAFC !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    paddingRight: "10px !important",
    paddingLeft: ({ inputStartPadding = "28px" }) =>
      `${inputStartPadding} !important`,
    borderBottomRightRadius: ({ disableUnderline }) =>
      disableUnderline ? "4px" : "0px",
    borderBottomLeftRadius: ({ disableUnderline }) =>
      disableUnderline ? "4px" : "0px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    
    "&:hover": {
      backgroundColor: ({ isTransparent }) =>
        isTransparent ? "inherit" : `#F8FAFC !important`,
    },
    "& legend": {
      width: ({ stickyLabel }) => (stickyLabel ? "0px" : "auto"),
    },
  },
  input: {
    overflowX: "hidden",
    width: "100%",
    padding: ({ size }) => {
      switch (size) {
        case "small":
          return "12px 10px 8px 0px !important";
        case "medium":
          return "12px 10px 8px 0px !important";
        default:
          return "15px 10px 12px 0px !important";
      }
    },
    backgroundColor: ({ isTransparent }) =>
      isTransparent ? "inherit !important" : "#F8FAFC !important",
    fontWeight: ({ fontWeight = 400 }) => fontWeight,
    fontSize: ({ fontSize = "14px" }) => fontSize,
    "&:hover": {
      backgroundColor: ({ isTransparent }) =>
        isTransparent ? "inherit" : "#F8FAFC",
    },
  },
  focused: {
    backgroundColor: ({ isTransparent }) =>
      isTransparent ? "inherit !important" : `#F8FAFC !important`,
  },
  underline: {
    "&:before": {
      borderBottomColor: "#0E5AA7",
    },
    "&:hover": {
      "&:before": {
        borderBottomColor: "#0E5AA7",
      },
    },
  },
}));

export const useStyleOutlinedInput = makeStyles(({ palette }: Theme) => ({
  root: {
    minWidth: ({ minWidth = "auto" }) => minWidth,
    width: ({ width = "auto" }) => width,
    color: ({ fontColor = palette.common.black }: IInputStyle) => fontColor,
    height: ({ size, multiline, rowsMax }) => {
      if (multiline) {
        const convertedMaxRows = Number(rowsMax);
        if (rowsMax && !isNaN(convertedMaxRows)) {
          switch (size) {
            case "small":
              return `${convertedMaxRows * 40}px`;
            case "medium":
              return `${convertedMaxRows * 50}px`;
            case "tiny":
              return `${convertedMaxRows * 30}px`;
            default:
              return `${convertedMaxRows * 60}px`;
          }
        }
      }
      switch (size) {
        case "small":
          return "40px";
        case "medium":
          return "50px";
        case "tiny":
          return "30px";
        default:
          return "60px";
      }
    },
    backgroundColor: "transparent",
    paddingRight: "10px",
    paddingLeft: ({ inputStartPadding = "20px" }) => inputStartPadding,
    "& legend": {
      width: ({ stickyLabel }) => (stickyLabel ? "0px" : "auto"),
    },
  },
  input: {
    overflowX: "hidden",
    width: "100%",
    padding: "10px 10px 10px 3px",
    backgroundColor: "transparent !important",
    fontWeight: ({ fontWeight = 400 }) => fontWeight,
    fontSize: ({ fontSize = "14px" }) => fontSize,
  },
}));

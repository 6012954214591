import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    position: "absolute",
    width: "100%",
    zIndex: 1000,
    backgroundColor: "#fff",
    boxShadow: theme.shadows[1],
    "& li": {
      borderBottom: `1px solid ${theme.palette.background.default}`,
      fontSize: "12px",
      fontWeight: 500,
      padding: "10px",
      width: "100%",
      cursor: "pointer",
      listStyleType: "none",
      "&:hover": {
        backgroundColor: "#e6f2ff",
      },
    },
  },
  noListWrapper: {
    position: "absolute",
    width: "100%",
    zIndex: 1000,
    backgroundColor: "#fff",
    boxShadow: theme.shadows[1],
    "& li": {
      fontSize: "12px",
      fontWeight: 500,
      padding: "10px",
      width: "100%",
      cursor: "pointer",
      listStyleType: "none",
    },
  },
  loaderWrapper: {
    position: "relative",
  },
  wrapper: {
    position: "relative",
    width: "100%",
  },
}));

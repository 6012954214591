import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Input } from "common";
import React from "react";

const PasswordInputField = ({
  name,
  label,
  value,
  error,
  onChangeHandler,
  showPassword,
  showPasswordHandler,
  testId,
}: {
  name: string;
  label: string;
  value: string;
  error: string;
  onChangeHandler: (e: any) => void;
  showPassword: boolean;
  showPasswordHandler: (type: string) => void;
  testId: string;
}) => {
  return (
    <Input
      testId={testId}
      error={error ? true : false}
      errorMessage={""}
      name={name}
      label={label}
      value={value}
      fontSize="16px"
      type={showPassword ? "text" : "password"}
      onChange={onChangeHandler}
      size="medium"
      variant="outlined"
      width="100%"
      id="outlined-password-input-text"
      autoComplete="off"
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            data-testid='show-password-icon'
            onClick={() => {
              showPasswordHandler(`show${name}`);
            }}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
export default PasswordInputField;

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "common";
import { SearchBox, SelectCountry } from "custom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, setStoreId } from "store/actions/auth.actions";
import { resetFilters } from "store/actions/promotion.actions"; //fetchCampaigns,
import { EStores } from "store/reducers/auth.reducers";
import { IState } from "store/store.interface";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "0 20px",
    justifyContent: "space-beween",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: "0px 15px",
      width: "100%",
    },
  },
}));
const Header: React.FC = () => {
  const { container } = useStyles();
  const dispatch = useDispatch();
  const { storeId = "" } = useSelector((state: IState) => state.auth);

  const onChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setStoreId(event.target.value as EStores));
    dispatch(resetFilters());
    dispatch(setLocation("/"));
  };

  return (
    <Box className={container}>
      <SearchBox placeholder="Search for a campaign" />
      <Box display="flex" justifyContent="flex-end">
        <SelectCountry
          onChangeCountry={onChangeCountry}
          value={storeId}
          width="230px"
          fullWidth={false}
          disableUnderLine={true}
        />
      </Box>
    </Box>
  );
};

export default Header;

import React, { useState } from "react";
import { Button, Box, Text } from "common";
import { ButtonGroup } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import MuiButton from "@material-ui/core/Button";
import { useStyles } from "./Users.style";
import MenuDropdown from "./FilterDropDown";
import AddUserComponent from "./AddUser";

const Filters = ({ params }: { params: string }) => {
  const { muiButton } = useStyles();
  const countries = [
    "UAE",
    "Jordan",
    "Lebanon",
    "Egypt",
    "Saudi Arabia",
    "Pakistan",
    "Kuwait",
    "Bahrain",
    "Oman",
    "Kenya",
    "Georgia",
    "Qatar",
    "Iraq",
    "Iran",
    "Uzbekistan",
  ];
  const campaigns = [
    "DISCOUNT_COUPON",
    "BADGE_CAMPAIGN",
    "BIN_CAMPAIGN",
    "BMSM_CAMPAIGN",
  ];

  const [anchorElCountries, setAnchorElCountries] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleCountryClick = () => {
    setAnchorElCountries(!anchorElCountries);
  };
  const [anchorElCampaign, setAnchorElCampaign] = useState(false);

  const handleCampaignClick = () => {
    setAnchorElCampaign(!anchorElCampaign);
  };

const handleAddNewUserClick = () => {
  setIsDrawerOpen(true);
}
  return (
    <>
      <Box
        display="flex"
        justifyContent={"flex-start"}
        alignItems="center"
        marginTop="px"
        width={"50%"}
      >
        <Text
          fontSize="16px"
          fontWeight={700}
          whiteSpace="nowrap"
          marginRight="20px"
        >
          Filter By
        </Text>
        <Box width={"100%"}>
          <ButtonGroup size="large">
            <MuiButton
              color="primary"
              className={muiButton}
              onClick={handleCountryClick}
            >
              Country <KeyboardArrowDown />
            </MuiButton>
            <MuiButton
              color="primary"
              className={muiButton}
              onClick={handleCampaignClick}
            >
              Campaign Type Access <KeyboardArrowDown />
            </MuiButton>
          </ButtonGroup>
          <MenuDropdown
            options={countries}
            isMenuOpen={anchorElCountries}
            menuOpenHandler={setAnchorElCountries}
            params={params}
          />
          <MenuDropdown
            options={campaigns}
            isMenuOpen={anchorElCampaign}
            menuOpenHandler={setAnchorElCampaign}
            params={params}
            campaign
          />
        </Box>
        <Button
          fontSize="14px"
          fontWeight={600}
          padding="10px"
          round
          width="80%"
          color="primary"
          variant="contained"
          margin="10px"
          onClick={handleAddNewUserClick}
        >
          Add new user
        </Button>
        <AddUserComponent isDrawerOpen={isDrawerOpen} drawerHandler={setIsDrawerOpen}/>
      </Box>
    </>
  );
};

export default Filters;

import { Box, Select } from "common";
import React, { FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EDiscountTypes, IState } from "store/store.interface";
import { setPromotionObject } from "store/actions/promotion.actions";

interface IProps {
  disable?: boolean;
  label?: string;
  error?: string;
  value?: string;
}

const ShipmentType: FC<IProps> = ({ disable, error, value = "" }) => {
  const [selectedShipmentType, setSelectedShipmentType] =
    useState<string>(value);

  const dispatch = useDispatch();

  const { selectOptions = {}, creationForm } = useSelector((state: IState) => ({
    ...state.config,
    ...state.promotion,
  }));

  const option = useMemo(() => {
    if (selectOptions["shipmentType"]) {
      const options = selectOptions["shipmentType"];
      let filteredOptions = selectOptions["shipmentType"];

      if (creationForm?.discount?.type === EDiscountTypes.FREE_DELIVERY) {
        filteredOptions = options.filter((res) => res.value !== "DEFAULT");
      }
      return filteredOptions;
    }
    return [];
  }, [selectOptions]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedShipmentType(value);
    dispatch(setPromotionObject(value, ["restrictions", "shipmentType"]));
    dispatch(setPromotionObject("Carrefour", ["restrictions", "sellerId"]));
  };

  return (
    <>
      <Box
        display="grid"
        width="100%"
        gridTemplateColumns="auto"
        gridGap="20px"
        alignItems="end"
        flexDirection="column"
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-start"
          mb="20px"
          flexDirection="column"
        >
          <Select
            disable={disable}
            options={option}
            onNativeChange={onChangeHandler}
            nativeValue={selectedShipmentType}
            label={`Shipment Type`}
            inputVariant="filled"
            disableUnderline={false}
            testId="select-shipment-type"
            variant="native"
            size="small"
            width="100%"
            fullWidth={true}
            stickyLabel={false}
            error={!!error}
            errorMessage={error}
          />
        </Box>
      </Box>
    </>
  );
};

export default ShipmentType;

import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
    "& svg": {
      marginRight: "5px",
    },
  },
});

import { Alert } from "common";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { clearApi } from "store/actions/api.actions";
import { CHANGE_PASSWORD } from "store/store.types";

const SuccessNotification = () => {
  const dispatch = useDispatch();

  const onCloseNotification = useCallback(() => {
    dispatch(clearApi(CHANGE_PASSWORD));
    window.location.reload()
  },[dispatch]);  
    return (
    <Alert
      data-testid="success-notification"
      padding="5px 15px"
      width="100%"
      onClose={onCloseNotification}
      margin="15px 0"
      color="success"
      fontSize="16px"
      variant="filled"
      closeText="close"
      severity="success"
    >
      Password successfully changed
    </Alert>
  )
};


export default SuccessNotification;

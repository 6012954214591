import React, { useMemo, useState } from "react";
import Checkbox from "../../TitledCheckbox";
import { useSelector } from "react-redux";
import { IState } from "store/store.interface";
import { Alert, Box, Button, Text} from "common";

interface IProps {
  onCancelHandler?: () => void;
  onSendForApproval?: () => void;
  onCloseNotification?: () => void;
  isLoading?: boolean;
  error?: string;
}
const ApprovalScreen: React.FC<IProps> = ({
  onCancelHandler,
  onSendForApproval,
  isLoading = true,
  error,
  onCloseNotification,
}) => {
  const [values, setValues] = useState<string[]>([]);
  const { formDetails } = useSelector((state: IState) => state.promotion);

  const { header, waringHeader,sections} = useMemo(() => {
    if (formDetails) {
      const {
        approval: { sections, header,waringHeader },
      } = formDetails;
      return { header, sections,waringHeader};
    }
    return {};
  }, [formDetails]);
  const onChange = (value: string) => () => {
    if (values.indexOf(value) > -1) {
      setValues((oldValues) =>
        oldValues.filter((oldValue) => oldValue !== value)
      );
    } else {
      setValues((oldValues) => [...oldValues, value]);
    }
  };

  const canSubmit = useMemo(() => {
    let result = [];
    if (sections) {
      sections.forEach(({ options }) =>
        options.forEach(({ required = true, value }) => {
          if (required && values.indexOf(value) < 0) {
            result.push(false);
          }
        })
      );
    }
    return result.length === 0;
  }, [values, sections]);

  if (!formDetails || !sections) {
    return null;
  }
  return (
    <Box px="20px" maxWidth={["auto", "800px", "800px", "800px"]} margin="auto">
      {error && (
        <Alert
          data-testid="error-notification"
          padding="5px 15px"
          width="100%"
          variant="outlined"
          severity="error"
          onClose={onCloseNotification}
          margin="15px 0"
        >
          {error || "Something went wrong"}
        </Alert>
      )}
      <Text mb="20px" fontWeight={700} fontSize="24px">
        {header}
      </Text>
      {waringHeader && 
        <Text mb="20px" color={"red"} fontWeight={600} fontSize="16px">
        {waringHeader}
        </Text>
      }
      {sections.map(({ header, layout, options,points}, index) => (
        <React.Fragment key={`${header}_${index}`}>
          <Text mb="20px" fontWeight={600} fontSize="16px">
            {header}
          </Text>
          <Box
            gridTemplateColumns={
              layout === "layout_2" ? "repeat(2, 1fr)" : "1fr"
            }
            gridGap="25px"
            my="30px"
          >
            {options.map(({ value, label, id }) => (
              <Checkbox
                key={id}
                value={value}
                onChange={onChange}
                checked={values.indexOf(value) > -1}
                title={label}
              />
            ))}
          </Box>
          <Box marginBottom={"30px"}>
            <ul style={{ listStyle:"disc"}}>
            {
              points?.map(({label})=>(
                <li>
                <Text mb="20px" fontWeight={600} fontSize="16px">
                  {label}
                </Text>
                </li>
              ))
            }
            </ul>
          </Box>
        </React.Fragment>
      ))}
      <Box
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr"]}
        gridGap="20px"
      >
        <Button
          data-testid="cancel-button"
          variant="outlined"
          round
          color="primary"
          width="100%"
          onClick={onCancelHandler}
        >
          Cancel
        </Button>
        <Button
          data-testid="send-for-approval-button"
          color="secondary"
          variant="contained"
          disabled={!canSubmit}
          round
          width="100%"
          onClick={onSendForApproval}
          isLoading={isLoading}
        >
          Send for approval
        </Button>
      </Box>
    </Box>
  );
};

export default ApprovalScreen;

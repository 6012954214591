import React, { useCallback, useEffect, FC } from "react";
import LoginComponent, { TLogin, EAction } from "components/Login";
import { useDispatch, useSelector } from "react-redux";
import { login, setLocation, setStoreId } from "store/actions/auth.actions";
import { IState } from "store/store.interface";
import { EStores } from "store/reducers/auth.reducers";

const Login: FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, storeId, roles = [], name, storeIds } = useSelector(
    (state: IState) => ({
      ...state.auth,
      ...state.config,
    })
  );

  useEffect(() => {
    if (isAuthenticated && storeId) {
      dispatch(setLocation("/"));
    }
  }, [isAuthenticated, dispatch, storeId]);

  const onNextClick = (values: TLogin) => {
    dispatch(login(values.email, values.password));
  };

  const onCountrySelectHandler = useCallback(
    (storeId: string) => {
      dispatch(setStoreId(storeId as EStores));
    },
    [dispatch]
  );

  return (
    <LoginComponent
      onNextHandler={onNextClick}
      onCountrySelection={onCountrySelectHandler}
      action={isAuthenticated ? EAction.CountrySelect : EAction.Auth}
      role={roles[0]}
      stores={storeIds}
      name={name}
    />
  );
};

export default Login;

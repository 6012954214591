import { Alert, Box, Button, Link, Text } from "common";
import { useStyles } from "components/MyProfile/MyProfile.style";
import React, { useCallback, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getUsers } from "store/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "./UserTable";
import { IState } from "store/store.interface";
import Filters from "./Filters";
import {
  ADD_NEW_USER,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
} from "store/store.types";
import { useApiInfo } from "hooks";
import { clearApi } from "store/actions/api.actions";

const Users = () => {
  const { styledButton, notification } = useStyles();
  const dispatch = useDispatch();
  const {
    users,
    page,
    params,
    success = false,
  } = useSelector((state: IState) => ({
    ...state.users,
    ...state.api[ADD_NEW_USER],
  }));
  const { success: isEditScreenSuccess = false } =
    useApiInfo(UPDATE_USER_BY_ID);
  const { success: isUserDeleted = false } = useApiInfo(DELETE_USER_BY_ID);

  useEffect(() => {
    dispatch(getUsers(0));
  }, [dispatch]);

  const onCloseNotification = useCallback(() => {
    dispatch(clearApi(UPDATE_USER_BY_ID));
    dispatch(clearApi(DELETE_USER_BY_ID));
    dispatch(clearApi(ADD_NEW_USER));
    window.location.reload()
  }, [dispatch]);
  return (
    <Box marginTop={3}>
      {(success || isEditScreenSuccess || isUserDeleted) && (
        <Alert
          className={notification}
          onClose={onCloseNotification}
          data-testid="success-notification"
          padding="5px 15px"
          width="60%"
          margin="4px auto"
          color="success"
          fontSize="14px"
          variant="filled"
          severity="success"
        >
          {success && "User Created successfully"}
          {isEditScreenSuccess && "User Updated Successfully"}
          {isUserDeleted && "User Deleted Successfully"}
        </Alert>
      )}
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Text fontSize="24px" fontWeight={700} lineHeight="32px" mt={1}>
          Users
        </Text>
        <Filters params={params} />
      </Box>
      <UserTable userDetails={users} pageNumber={page} params={params} />
      <Box display="flex" flexDirection="row-reverse" mt={2}>
        <Link to="/">
          <Button
            className={styledButton}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back to Campaigns
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Users;

import React, { FC } from "react";
import { IProps } from "./Button.interface";
import MuiButton from "@material-ui/core/Button";
import {
  useButtonStyles,
  useCustomButtonStyles,
  useWrapperStyle,
} from "./Button.style";
import LinearProgress from "@material-ui/core/LinearProgress";

const Button: FC<IProps> = (props) => {
  const {
    children,
    isLoading,
    color,
    disableShrink,
    round,
    loaderVariant,
    disabled,
    loaderWithLogo,
    custom,
    minWidth,
    id,
    ...rest
  } = props;
  const classes = useButtonStyles(props);
  const customClasses = useCustomButtonStyles(props);
  const { root } = useWrapperStyle(props);
  return (
    <div className={root}>
      {isLoading && (
        <LinearProgress
          variant="indeterminate"
          color={color === "primary" ? "primary" : "secondary"}
        />
      )}
      <MuiButton
       data-testid={id}
        disabled={disabled || isLoading}
        classes={custom ? customClasses : classes}
        color={color}
        {...rest}
      >
        {children}
      </MuiButton>
    </div>
  );
};

Button.defaultProps = {
  loaderVariant: "linear",
  loaderWithLogo: false,
  fontSize: "16px",
  fontWeight: 500,
};

export default Button;

import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  overFlow: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    maxHeight: "100px",
    overflow: "scroll",
    maxWidth: ({ listing }) => (listing === "grid" ? "500px" : "900px"),
    [theme.breakpoints.down("lg")]: {
      maxWidth: ({ listing }) => (listing === "grid" ? "250px" : "350px"),
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px !important",
    },
  },
  wrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: ({ listing }) => (listing === "grid" ? "200px" : "500px"),
    [theme.breakpoints.down("lg")]: {
      maxWidth: ({ listing }) => (listing === "grid" ? "250px" : "350px"),
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px !important",
    },
  },
  buttonWrapper: {
    "& span": {
      fontWeight: 600,
      marginLeft: "10px",
    },
    "& svg": {
      fill: "#fff",
    },
  },
  item: {
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    borderTop: ({ color }: { color?: string; listing?: any }) =>
      color ? `4px solid ${color}` : "",
  },
  icon: {
    width: "30px",
    height: "30px",
  },
  dateText: {
    "& span": {
      fontWeight: 500,
      color: "#4C5862",
      margin: "0 4px",
    },
  },
  loadMore: {
    cursor: "pointer",
    "& span": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      "& span": {
        color: theme.palette.primary.dark,
      },
      "& svg": {
        color: theme.palette.primary.dark,
      },
    },
  },
  progressWrapper: {
    boxShadow: "0px 2px 10px 0px rgb(0 0 0 / 20%)",
    borderRadius: "50px",
    padding: "4px 10px",
  },
  progressIcon: {
    width: "20px",
    height: "20px",
  },
  progressHilightText: {
    display: "flex",
    fontWeight: 500,
    color: "#4C5862",
    margin: "0 4px",
    "& span": {
      fontSize: "16px",
      fontWeight: 700,
      color: theme.palette.success.main,
      marginLeft: "8px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
}));

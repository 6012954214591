import { CheckCircle } from "@mafc/icon";
import { Divider } from "@material-ui/core";
import { Box, Card, Text } from "common";
import COLORS from "common/Theme/colors";
import React from "react";
import { EStores, IPermission } from "store/reducers/auth.reducers";
import { getIcon } from "./helpers/MyProfile.helper";
import {
  campaignNameMapping,
  countryNameMapping,
  ECampaignsType,
} from "./MyProfile.interface";

const CountryAccess = ({
  permissions,
  storeIds,
}: {
  permissions: {
    [storeId: string]: IPermission[];
  };
  storeIds: EStores[];
}) => {
  return (
    <Card
      padding="20px"
      borderRadius="8px"
      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.15);"
      marginTop="15px"
    >
      <Box
        display="flex"
        flexDirection={["column", "row", "row"]}
        alignItems="center"
        mb={1}
        p={1}
      >
        <Text
          fontSize="16px"
          fontWeight={700}
          mt={["10px", "0px", "0px", "0px"]}
          lineHeight="20px"
          width={"20%"}
        >
          Campaigns
        </Text>
        <Box
          display="flex"
          ml={1}
          flexWrap="wrap"
          justifyContent="space-between"
          width="80%"
        >
          {storeIds.map((item: EStores) => {
            return (
              <Box display="flex" alignItems="center" m={1}>
                {getIcon(item, "20px", "20px")}
                <Text fontSize="14px" ml={1} fontWeight={700}>
                  {countryNameMapping[item]}
                </Text>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Divider />
      {ECampaignsType.map((campaign: string) => {
        const isBulkCoupon = campaign === "DISCOUNT_COUPON2";
        return (
          <>
            <Box
              display="flex"
              flexDirection={["column", "row", "row"]}
              alignItems="center"
              my={1}
              p={1}
            >
              <Text
                fontSize="16px"
                mt={["10px", "0px", "0px", "0px"]}
                lineHeight="20px"
                width={"20%"}
                color={COLORS.MINE_SHAFT}
              >
                {campaignNameMapping[campaign]}
              </Text>
              <Box
                display="flex"
                ml={3}
                flexWrap="wrap"
                justifyContent="space-between"
                width="80%"
              >
                {storeIds.map((item: EStores) => {
                  return (
                    <Box display="flex" alignItems="center" m={1} ml={2}>
                      {permissions && permissions[item] && permissions[item][0].value.includes(
                        isBulkCoupon ? ECampaignsType[0] : campaign
                      ) && <CheckCircle width={24} height={24} size="M" />}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            {!isBulkCoupon && <Divider />}
          </>
        );
      })}
    </Card>
  );
};

export default CountryAccess;

import React from "react";
import { Box, Card } from "common";
import { Skeleton } from "@material-ui/lab";

const SkeletonItem: React.FC = () => {
  return (
    <Card
      padding="15px"
      width="100%"
      display="grid"
      marginY="10px"
      boxShadow="rgb(0 0 0 / 9%) 0px 0px 16px 0px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignContent="center"
        marginBottom="10px"
        flexDirection={["column", "row", "row", "row"]}
      >
        <Box
          marginBottom="10px"
          display="flex"
          alignContent="center"
          flexDirection={["column", "row", "row", "row"]}
        >
          <Skeleton
            variant="rect"
            height={20}
            width="150px"
            animation="pulse"
            style={{ marginRight: "10px" }}
          />
          <Skeleton variant="text" width="150px" animation="pulse" />
        </Box>
        <Skeleton variant="rect" height={20} width="150px" animation="pulse" />
      </Box>
      <Skeleton variant="text" width="250px" animation="pulse" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        marginBottom="10px"
        flexDirection={["column", "row", "row", "row"]}
      >
        <Skeleton variant="text" width="250px" animation="pulse" />
        <Skeleton variant="rect" width="55px" height={30} animation="pulse" />
      </Box>
    </Card>
  );
};
const DashboardLoader: React.FC = () => {
  return (
    <Box
      gridTemplateColumns="1fr"
      gridGap="15px"
      width="100%"
      margin="20px auto"
    >
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </Box>
  );
};

export default DashboardLoader;

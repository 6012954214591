import React, { useMemo } from "react";
import { useStyles } from "./Field.style";
import emptyPicture from "assets/emptyPicture.svg";
import { Box } from "common";

interface IBadgePreviewProps {
  color?: string;
  bgColor?: string;
  boldText?: string;
  normalText?: string;
  placement?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  withMargin?: boolean;
}
const BadgePreview: React.FC<IBadgePreviewProps> = ({
  color,
  bgColor,
  boldText,
  normalText = "",
  placement,
  width,
  height,
  fontSize,
  withMargin,
}) => {
  const classes = useStyles({
    bgColor,
    color,
    fontSize,
    height,
    withMargin,
    width,
  });
  const text = useMemo(() => {
    if (boldText) {
      return (
        <>
          <span>{boldText}</span> {normalText}{" "}
        </>
      );
    }
    return normalText;
  }, [normalText, boldText]);
  return (
    <div className={classes.badgePreviewContainer}>
      {placement === "top" && text && (
        <div className={classes.badgeText} data-testid="top-text-placement">
          {text}
        </div>
      )}
      <div className={classes.badgeEmptyPicture}>
        <img src={emptyPicture} alt="empty badge" width="100%" />
      </div>
      {placement === "bottom" && text && (
        <div className={classes.badgeText} data-testid="top-text-placement">
          {text}
        </div>
      )}
      <Box
        marginBottom="2px"
        marginTop="10px"
        className={classes.badgePrviewRect}
      />
      <Box marginBottom="20px" className={classes.badgePrviewRect} />
      <Box marginBottom="20px" className={classes.badgePrviewRect} />
      <Box className={classes.badgePreviewSquare}>
        <div />
      </Box>
    </div>
  );
};

BadgePreview.defaultProps = {
  withMargin: true,
};

export default BadgePreview;

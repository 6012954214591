import "date-fns";
import React, { FC } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  useStyleFilledInput,
  useStyleOutlinedInput,
  useStyleLabelInput,
} from "../common.style";
import { IDatePickerProps } from "./DatePicker.interface";

const DatePicker: FC<IDatePickerProps> = (props) => {
  const {
    disableUnderLine,
    fullWidth,
    color,
    error,
    errorMessage,
    inputVariant,
    testId,
    id,
    ...rest
  } = props;
  const filledInputClasses = useStyleFilledInput({
    ...props,
    letterSpacing: "2px",
    isLabeled: props.label ? true : false,
  });

  const outlinedInputClasses = useStyleOutlinedInput({
    ...props,
    isLabeled: props.label ? true : false,
  });

  const labelClasses = useStyleLabelInput(props);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        color={error ? "primary" : color}
        inputVariant={inputVariant}
        fullWidth={fullWidth}
        InputLabelProps={{
          id: id,
          classes: labelClasses,
        }}
        InputProps={{
          id: id,
          autoComplete: "off",
          disableUnderline: error ? false : disableUnderLine,
          fullWidth,
          classes:
            inputVariant === "filled"
              ? filledInputClasses
              : outlinedInputClasses,
        }}
        margin="none"
        id="date-picker-inline"
        error={error}
        helperText={error ? errorMessage : ""}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        data-testid={testId || "date-picker-common"}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.defaultProps = {
  disableUnderLine: true,
  fullWidth: true,
  variant: "inline",
  format: "dd/MM/yyyy",
  inputVariant: "filled",
  autoOk: true,
};

export default DatePicker;

import { IconButton, Theme, withStyles } from "@material-ui/core";

export const StyledIconButton = withStyles(({ breakpoints }: Theme) => ({
  root: {
    display: "none",
    [breakpoints.down("md")]: {
      display: "block",
    },
    "&:focus": {
      outline: "none",
    },
  },
}))(IconButton);

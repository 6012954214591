import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import createStore from "./store/createStore";
import reducers from "./store/reducers";
import { ThemeProvider as MuiThemeProvider } from "common";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createGenerateClassName,
  StylesProvider,
} from "@material-ui/core/styles";

const store = createStore({}, reducers);

const generateClassName = createGenerateClassName({
  productionPrefix: "mafcarrefour-",
});

ReactDOM.render(
  <StylesProvider injectFirst generateClassName={generateClassName}>
    <MuiThemeProvider>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

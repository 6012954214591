import React, { useCallback, FC } from "react";
import { List } from "@material-ui/core";
import { Link } from "common";
import {
  useCustomStyles,
  IconWrapper,
  StyledListItem,
} from "./DrawerList.style";
import Logo from "../Logo";
import { TRoute } from "../Header.interface";
import { EAnchors, ETheme } from "common/common.interface";

export interface IProps {
  routes?: TRoute[];
  toggleDrawer: (
    status: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  dropMenuOptions?: TRoute[];
  anchor?: EAnchors;
  color?: ETheme;
  children?: React.ReactNode;
}
const DrawerList: FC<IProps> = ({
  routes = [],
  anchor = "left",
  toggleDrawer,
  dropMenuOptions = [],
  color = "primary",
  children,
}) => {
  const classes = useCustomStyles();

  const getNavigationLinkComp = useCallback(
    (route: { label: string, to: string}) => {
      const { label, to } = route;
      return (
        <StyledListItem
          button
          key={route.label}
          disableTouchRipple={true}
          onClick={toggleDrawer(false)}
        >
          <Link to={to} color={color}>
            {label}
          </Link>
        </StyledListItem>
      );
    },
    [color, toggleDrawer]
  );

  const getListItems = useCallback(() => {
    const { list, fullList } = classes;
    const combinedRoutes = [...routes, ...dropMenuOptions];
    if (combinedRoutes && combinedRoutes.length > 0) {
      return (
        <div
          className={["top", "bottom"].includes(anchor || "") ? fullList : list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {[...routes, ...dropMenuOptions].map((route) =>
              getNavigationLinkComp(route)
            )}
          </List>
        </div>
      );
    }
  }, [
    getNavigationLinkComp,
    anchor,
    classes,
    routes,
    toggleDrawer,
    dropMenuOptions,
  ]);

  return (
    <>
      <IconWrapper>
        <Logo />
      </IconWrapper>
      {children}
      {getListItems()}
    </>
  );
};

export default DrawerList;

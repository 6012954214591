import React, { useState, useCallback, useEffect } from "react";
import Logo from "./Logo";
import {
  StyledAppBar,
  StyledToolBar,
  DesktopNavigationContainer,
  MobileNavigationContainer,
} from "./Header.style";
import { Menu, Drawer } from "common";
import Navigations from "./Navigations";
import DrawerList from "./DrawerList";
import { IProps } from "./Header.interface";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const Header: React.FC<IProps> = ({
  routes,
  dropMenuRoutes = [],
  menuLabel,
  mobileMenuAnchor,
  onLogoClick,
  children,
}) => {
  const defaultTheme = useTheme();

  const isDesktop = useMediaQuery(defaultTheme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  
  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    },
    []
  );  

  useEffect(() => {
    if (isDesktop && open) {
      setOpen(false);
    }
  }, [isDesktop, open]);

  return (
    <StyledAppBar position="static" color="primary">
      <StyledToolBar style={{ padding: "0px" }}>
        <Logo onClick={onLogoClick} />
        {isDesktop && (
          <>
            <DesktopNavigationContainer>
              <Navigations routes={routes} color="primary" />
              {children}
            </DesktopNavigationContainer>
            {dropMenuRoutes.length > 0 && (
              <Menu
                menuLabel={menuLabel as any}
                options={dropMenuRoutes}
                theme="primary"
              />
            )}
          </>
        )}
        {!isDesktop && (
          <MobileNavigationContainer>
            <Drawer
              anchor={mobileMenuAnchor}
              open={open}
              theme="primary"
              toggleDrawer={toggleDrawer}
            >
              <DrawerList
                routes={routes}
                anchor="left"
                toggleDrawer={toggleDrawer}
                color="primary"
                dropMenuOptions={dropMenuRoutes}
              >
                {children}
              </DrawerList>
            </Drawer>
          </MobileNavigationContainer>
        )}
      </StyledToolBar>
    </StyledAppBar>
  );
};

export default Header;

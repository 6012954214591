import React from "react";
import { StyledToolTip, Wrapper, useHelpIconStyles } from "./Tooltip.style";
import HelpIcon from "@material-ui/icons/InfoOutlined";
import { IProps } from "./Tooltip.interface";

const Tooltip: React.FC<IProps> = ({
  children,
  title,
  arrow,
  placement,
  withHelpIcon,
  iconPlacement,
  wrapperAlignment,
  wrapperMargin,
  wrapperWidth,
  isIconOnly,
  color,
  iconSize,
  width,
  height,
  blackThemed
}) => {
  const iconClasses = useHelpIconStyles({
    iconPlacement,
    color,
    iconSize,
    width,
    height,
  });
  if (withHelpIcon) {
    if (isIconOnly) {
      if (iconPlacement === "start") {
        return (
          <Wrapper
            justifyContent={wrapperAlignment}
            margin={wrapperMargin}
            width={wrapperWidth}
          >
            <StyledToolTip title={title} arrow={arrow} placement={placement}>
              <HelpIcon classes={iconClasses} />
            </StyledToolTip>
            {children}
          </Wrapper>
        );
      } else if (iconPlacement === "end") {
        return (
          <Wrapper
            justifyContent={wrapperAlignment}
            margin={wrapperMargin}
            width={wrapperWidth}
          >
            {children}
            <StyledToolTip title={title} arrow={arrow} placement={placement}>
              <HelpIcon classes={iconClasses} />
            </StyledToolTip>
          </Wrapper>
        );
      }
    }
    return (
      <StyledToolTip title={title} arrow={arrow} placement={placement}>
        {iconPlacement === "end" ? (
          <Wrapper
            justifyContent={wrapperAlignment}
            margin={wrapperMargin}
            width={wrapperWidth}
          >
            {children}
            <HelpIcon classes={iconClasses} />
          </Wrapper>
        ) : (
          <Wrapper
            justifyContent={wrapperAlignment}
            margin={wrapperMargin}
            width={wrapperWidth}
          >
            <HelpIcon classes={iconClasses} />
            {children}
          </Wrapper>
        )}
      </StyledToolTip>
    );
  }
  return (
    <StyledToolTip title={title} arrow={arrow} placement={placement} blackThemed={blackThemed}>
      <Wrapper
        justifyContent={wrapperAlignment}
        margin={wrapperMargin}
        width={wrapperWidth}
      >
        {children}
      </Wrapper>
    </StyledToolTip>
  );
};

Tooltip.defaultProps = {
  placement: "bottom",
  iconPlacement: "end",
  wrapperAlignment: "flex-start",
  wrapperMargin: "0px",
};

export default Tooltip;

import { API_END, API_START, API_ERROR, API_CLEAR } from "../store.types";
import { IAction } from "../store.interface";
export interface IAPiState {
  [key: string]: {
    isLoading?: boolean;
    error?: string;
    done?: boolean;
    success?: boolean;
  };
}

export default (state: IAPiState = {}, action: IAction) => {
  switch (action.type) {
    case API_END:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          done: true,
          error: null,
          success: true,
        },
      };
    case API_START:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true,
          error: null,
          done: false,
          success: false,
        },
      };
    case API_ERROR:
      return {
        ...state,
        [action.payload.label]: {
          ...state[action.payload],
          isLoading: false,
          done: true,
          error: action.payload.error,
          success: false,
        },
      };
    case API_CLEAR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          error: null,
          done: false,
          success: false,
        },
      };
    default:
      return state;
  }
};

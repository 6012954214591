import { IAction } from "store/store.interface";
import {
  AUTH_LOGIN,
  AUTH_VERIFY_SESSION,
  AUTH_SET_COUNTRY,
  AUTH_UPDATE_LOCATION,
  AUTH_LOGOUT,
} from "../store.types";
import Cookie from "universal-cookie";

const cookie = new Cookie();

export enum ERoles {
  CREATOR = "creator",
  MANAGER = "manager",
  READONLY = 'readonly',
  ADMIN = 'admin'
}
export enum EStores {
  mafuae = "mafuae",
  mafkwt = "mafkwt",
  mafbhr = "mafbhr",
  mafqat = "mafqat",
  mafegy = "mafegy",
  maflbn = "maflbn",
  mafjor = "mafjor",
  mafsau = "mafsau",
  mafpak = "mafpak",
  mafomn = "mafomn",
  mafken = "mafken",
  mafirn = "mafirn",
  mafirq = "mafirq",
  mafgeo = "mafgeo",
  mafuzb = "mafuzb",
  mafinstorejor = "mafinstore-jor",
  mafinstoreuae = "mafinstore-uae",
  mafinstorelbn = "mafinstore-lbn",
  mafinstorepak = "mafinstore-pak",
  mafinstorekwt = "mafinstore-kwt",
  mafinstorebhr = "mafinstore-bhr",
  mafinstoreomn = "mafinstore-omn",
  mafinstoreegy = "mafinstore-egy",
}

export enum ETokenTypes {
  BEARER = "Bearer",
}

export interface IPermission {
  type: "campaign";
  value: string;
}

export interface ILoginState {
  access_token: string;
  refresh_token: string;
  token_type: ETokenTypes;
  expires_in: number;
  email: string;
  name: string;
  roles: ERoles[];
  storeIds: EStores[];
  forceChangePassword:boolean;
  permissions: {
    [storeId: string]: IPermission[];
  };
}

export interface IAuthState extends Partial<ILoginState> {
  isAuthenticated: boolean;
  location?: string;
  storeId?: EStores;
  timeZone: string;
}

const storeId = cookie.get("pe_storeid");
const timeZone = cookie.get("pe_tz") || "Asia/Dubai";
const access_token = cookie.get("pe_access_token");
const refresh_token = cookie.get("pe_refresh_token");
const expires_in = cookie.get("pe_expires_at");
const isAuthenticated =
  access_token && refresh_token && new Date().getTime() < expires_in;

export const INITIAL_STATE = {
  isAuthenticated,
  storeId,
  timeZone,
  refresh_token,
  access_token,
  expires_in,
};

export default (state: IAuthState = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case AUTH_VERIFY_SESSION:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case AUTH_SET_COUNTRY:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case AUTH_LOGOUT:
      return { isAuthenticated: false };
    default:
      return state;
  }
};

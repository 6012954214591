import React, { FC, useState, useMemo } from "react";
import { useStyles } from "./PromotionForm.style";
import { Box,Snackbar, Text ,Button} from "common";
import { Badge } from "custom";
import CampaignExtend from "./CampaignExtend";
import { useApiInfo } from "hooks";
import { PROMOTION_EXTEND } from "store/store.types";
import { useDispatch, useSelector } from "react-redux";
import { clearApi } from "store/actions/api.actions";
import { IState } from "store/store.interface";

interface IProps {
  title: string;
  header: string;
  testId?: string;
  isSummary?: boolean;
  status?: string;
  id?: string;
  object?: string;
  children?: React.ReactNode;
}
const Layout: FC<IProps> = ({
  title,
  header,
  testId = "layout",
  isSummary = false,
  children,
  status,
  id,
  object,
}) => {
  const classes = useStyles();
  const { roles = [], creationForm ,campaign} = useSelector((state: IState) => ({...state.auth, ...state.promotion}));
  const [openExtendDialog, setOpenExtendDialog] = useState(false);

  const { error, success, isLoading } = useApiInfo(PROMOTION_EXTEND);
  const dispatch = useDispatch();

  const onCloseSnackbar = () => {
    dispatch(clearApi(PROMOTION_EXTEND));
  };

  const closeExtendDialogHandler = () => {
    if (!isLoading) {
      setOpenExtendDialog(false);
    }
  };

  const openExtendDialogHandler = () => {
    setOpenExtendDialog(true);
  };

  const allowedToExtend = useMemo(
    () => campaign?.type !== "BADGE_CAMPAIGN" && status === "completed" && roles[0] === "creator" && isSummary,
    [status, isSummary, roles,campaign]
  );

  const showSnackbar = useMemo(() => !!error || !!success, [error, success]);

  return (
    <Box display="flex" flexDirection="column" width="100%" minHeight="550px">
      {showSnackbar && (
        <Snackbar
          open={showSnackbar}
          autoHideDuration={2000}
          horizontal="center"
          vertical="top"
          severity={!!error ? "error" : "success"}
          variant="standard"
          handleClose={onCloseSnackbar}
        >
          {error || "Your campaign has been extended successfully"}
        </Snackbar>
      )}
      {openExtendDialog && object && id && (
        <CampaignExtend
          open={openExtendDialog}
          onCancel={closeExtendDialogHandler}
          defaultTime="23:59:00"
          object={object}
          id={id}
        />
      )}
      <Box
        pb="15px"
        borderBottom="1px solid #E6E6E6"
        display="flex"
        flexDirection={["column", "row", "row", "row"]}
        justifyContent="space-between"
        alignItems={["flex-start", "flex-end", "flex-end", "flex-end"]}
      >
        <Box>
          <Text
            fontSize="28px"
            marginBottom="10px"
            fontWeight={600}
            data-testid={`header-${testId}`}
          >
            {header}
          </Text>
          <Text
            fontSize="16px"
            marginBottom="10px"
            data-testid={`title-${testId}`}
          >
            {title}
          </Text>
        </Box>
        {(status || allowedToExtend) && (
          <Box mb="12px" flexDirection="row" display="flex" alignItems="center">
            {!creationForm.edit && status && (
              <Badge
                as={status}
                height="26px"
                width="120px"
                borderRadius="5px"
              />
            )}
            {allowedToExtend && !creationForm.edit && (
              <Button
                variant="outlined"
                color="primary"
                onClick={openExtendDialogHandler}
                margin="0 0 0 20px"
                round
              >
                Extend
              </Button>
            )}
          </Box>
        )}
      </Box>
      {isSummary ? (
        <Box className={classes.contentContainerSummary}>{children}</Box>
      ) : (
        <Box className={classes.contentContainer}>{children}</Box>
      )}
    </Box>
  );
};

export default Layout;

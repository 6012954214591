import { IAction } from "store/store.interface";
import {
  CONFIG_RESET_BRAND_AND_CATEGORY,
  CONFIG_SET,
  CONFIG_SET_BRANDS,
  CONFIG_SET_CATEGORIES,
  CONFIG_SET_SINGLE_BRAND,
  CONFIG_SET_SINGLE_CATEGORY,
} from "../store.types";
import { EStores } from "./auth.reducers";
import { ECampaignActions } from "./promotion.reducers";

export type TCategories = {
  id: string;
  levelFourName: string | null;
  levelFourcode: string | null;
  levelThreeName: string | null;
  levelThreeCode: string | null;
  levelTwoName: string | null;
  levelTwoCode: string | null;
  levelOneName: string;
  levelOneCode: string;
};

export type TBrands = {
  id: string;
  code: string;
  name: string;
};
export interface IOption {
  id: string;
  value: string;
  label: string;
  when?:string[];
  priority?: number;
  iconName?: string;
  storeId?: EStores[];
  placement?: string;
  timeZone?: string;
}

export interface IChipStyle {
  text: string;
  bgColor: string;
  fontColor: string;
}

export interface IBadgeStyle {
  color: string;
  bgColor: string;
}

interface IUserPermission {
  title: string;
  action: ECampaignActions;
}
export interface IConfig {
  selectOptions: {
    [key: string]: IOption[];
  };
  scopes?: {
    [key: string]: IChipStyle;
  };
  chipsStyles?: {
    [key: string]: IChipStyle;
  };
  userPermissions: {
    [key: string]: IUserPermission[];
  };
  statusPermissions: {
    [key: string]: ECampaignActions[];
  };
  limit: number;
  categories?: IOption[];
  brands?: IOption[];
  selectedBrands?: IOption[];
  selectedCategories?: IOption[];
  canCreateCampaigns?: string[];
  badgeStylesMapping?: { [key: string]: IBadgeStyle };
  summaryMapping?: { [key: string]: any };
}

export const INITIAL_STATE = {
  selectOptions: {},
  userPermissions: {},
  statusPermissions: {},
  limit: 5,
};
const configReducers = (state: IConfig = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case CONFIG_SET:
      return {
        ...state,
        ...action.payload,
      };
    case CONFIG_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case CONFIG_SET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case CONFIG_SET_SINGLE_BRAND:
      return {
        ...state,
        selectedBrands: [...(state.selectedBrands || []), action.payload],
      };
    case CONFIG_SET_SINGLE_CATEGORY:
      return {
        ...state,
        selectedCategories: [
          ...(state.selectedCategories || []),
          action.payload,
        ],
      };
    case CONFIG_RESET_BRAND_AND_CATEGORY:
      return {
        ...state,
        selectedBrands: [],
        selcetedCategories: [],
      };
    default:
      return state;
  }
};

export default configReducers;

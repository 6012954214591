import React from "react";
import { Box, Button, Link, Text } from "common";
import { IState } from "store/store.interface";
import { useSelector } from "react-redux";
import ProfileDetails from "./ProfileDetails";
import CountryAccess from "./CountryAccess";
import { useStyles } from "./MyProfile.style";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SuccessNotification from "./ChangePassword/SuccessNotification";
import { CHANGE_PASSWORD } from "store/store.types";

const MyProfile = () => {
  const {
    name = "",
    email = "",
    permissions,
    storeIds,
    roles = [],
    success = false,
  } = useSelector((state: IState) => ({
    ...state.auth,
    ...state.api[CHANGE_PASSWORD],
  }));
  const { styledButton } = useStyles();
  return (
    <Box marginTop={3}>
      <Text fontSize="24px" fontWeight={700} lineHeight="32px" mt={1}>
        Account Information
      </Text>
      {success && <SuccessNotification />}
      <ProfileDetails name={name} email={email} role={roles[0]} />
      <Text fontSize="24px" fontWeight={700} lineHeight="32px" mt={1}>
        User Access
      </Text>
      {permissions && storeIds && (
        <CountryAccess permissions={permissions} storeIds={storeIds} />
      )}
      <Box display="flex" flexDirection="row-reverse" mt={2}>
        <Link to="/">
          <Button
            className={styledButton}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back to Campaigns
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default MyProfile;

import React, { FC, useMemo } from "react";
import Layout from "Layout";
import DashboardComponent from "components/Dashboard";
import { useApiInfo } from "hooks";
import { PROMOTION_CREATE, PROMOTION_UPDATE_STATUS } from "store/store.types";
import { useDispatch } from "react-redux";
import { clearApi } from "store/actions/api.actions";
import Section from "Layout/Section";
import { Snackbar } from "common";

const Dashboard: FC = () => {
  const { error: createCampaignError, done: isCreateCampaignDone } =
    useApiInfo(PROMOTION_CREATE);
  const { error: updateStatusError, done: isUpdateStatusDone } = useApiInfo(
    PROMOTION_UPDATE_STATUS
  );
  const dispatch = useDispatch();

  const successMessage = useMemo(() => {
    if (!createCampaignError && isCreateCampaignDone) {
      return "Campaign has been created successfully";
    } else if (!updateStatusError && isUpdateStatusDone) {
      return "Campaign Status has been updated successfully";
    }
  }, [
    createCampaignError,
    isCreateCampaignDone,
    updateStatusError,
    isUpdateStatusDone,
  ]);
  const onCloseNotification = () => {
    dispatch(clearApi(PROMOTION_CREATE));
    dispatch(clearApi(PROMOTION_UPDATE_STATUS));
  };
  return (
    <Layout>
      {successMessage && (
        <Snackbar
          severity="success"
          variant="filled"
          vertical="top"
          horizontal="center"
          autoHideDuration={4000}
          handleClose={onCloseNotification}
        >
          {successMessage}
        </Snackbar>
      )}
      <Section>
        <DashboardComponent />
      </Section>
    </Layout>
  );
};

export default Dashboard;

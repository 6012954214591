import React, { FC } from "react";
import { Link as NavigationLink } from "common";
import { NavigationsWrapper } from "../Header.style";
import { TRoute } from "../Header.interface";
import { ETheme } from "common/common.interface";

const Navigations: FC<{ routes?: TRoute[]; color?: ETheme }> = ({
  routes = [],
  color,
}) => {
  if (routes && routes.length > 0) {
    return (
      <NavigationsWrapper>
        {routes.map(({ label, to }) => (
          <NavigationLink
            marginRight="30px"
            key={label}
            to={to}
            color={color}
            variant="header"
          >
            {label}
          </NavigationLink>
        ))}
      </NavigationsWrapper>
    );
  }
  return <></>;
};

export default Navigations;

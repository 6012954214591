import React from "react";
import { Text, Box } from "common";
import MuiRadioButton, { RadioProps } from "@material-ui/core/Radio";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";

type TOptions = {
  label: string;
  value: string;
  hint?: string;
};

const useStyles = makeStyles({
  formControlWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
export interface IRadioGroupProps extends RadioProps {
  disabled?: boolean;
  options: TOptions[];
  label?: string;
  hint?: string;
  value?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
}

const RadioGroup: React.FC<IRadioGroupProps> = ({
  disabled,
  options,
  label,
  hint,
  value,
  onChange,
  testId,
}) => {
  const { formControlWrapper } = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      data-testid={testId || "radio-group-custom"}
    >
      {label && (
        <Box display="flex" flexDirection="column">
          <Text>{label}</Text>
          {hint && <Text>{hint}</Text>}
        </Box>
      )}
      <MuiRadioGroup
        data-testid="radio-group"
        color="primary"
        value={value}
        onChange={onChange}
        className={formControlWrapper}
      >
        {options.map(({ label, value: optionValue, hint }) => (
          <FormControlLabel
            disabled={disabled}
            value={optionValue}
            key={optionValue}
            data-testid="radio-button-label"
            control={
              <MuiRadioButton color="primary" data-testid="radio-button" />
            }
            label={
              <Box display="flex" flexDirection="column">
                <Text color="text.secondary" fontWeight={600} fontSize="13px">
                  {label}
                </Text>
                {hint && (
                  <Text color="text.secondary" fontWeight={500} fontSize="11px">
                    {hint}
                  </Text>
                )}
              </Box>
            }
          />
        ))}
      </MuiRadioGroup>
    </Box>
  );
};

export default RadioGroup;

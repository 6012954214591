import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  useNativeSelectStyles,
  useNativeSelectRootStyles,
} from "./Select.style";
import MenuItem from "@material-ui/core/MenuItem";
import { ISelectProps } from "./Select.interface";
import {
  useStyleFilledInput,
  useStyleOutlinedInput,
  useStyleLabelInput,
} from "../common.style";

const NativeSelect: React.FC<ISelectProps> = (props) => {
  const {
    disable,
    error,
    errorMessage,
    disableUnderline,
    color,
    label,
    options,
    onNativeChange,
    nativeValue,
    placeholder,
    inputVariant,
    id,
    fullWidth = true,
    testId,
  } = props;

  // filled input styles
  const filledClasses = useStyleFilledInput({
    ...props,
    isLabeled: props.label ? true : false,
  });

  // list styles
  const nativeSelectClasses = useNativeSelectRootStyles(props);

  // outlined input styles
  const outlinedClasses = useStyleOutlinedInput({
    ...props,
    isLabeled: props.label ? true : false,
  });

  // native select wrapper styles
  const selectClasses = useNativeSelectStyles(props);

  // common label styles
  const labelFilledClasses = useStyleLabelInput({
    ...props,
    inputVariant: "filled",
  });
  const labelOutlinedClasses = useStyleLabelInput({
    ...props,
    inputVariant: "outlined",
  });

  const commonSelectProps = {
    id,
    helperText: error ? errorMessage : "",
    error,
    inputProps: {
      autoComplete: "new-password",
    },
    InputLabelProps: {
      classes:
        inputVariant === "filled" ? labelFilledClasses : labelOutlinedClasses,
    },
    label,
    placeholder,
    color: error && inputVariant === "filled" ? "primary" : color,
    onChange: onNativeChange,
    value: nativeValue,
    fullWidth: fullWidth,
  };

  if (inputVariant === "filled") {
    return (
      <TextField
        disabled={disable}
        select
        {...commonSelectProps}
        data-testid={testId || "common-filled-native-select-input"}
        InputProps={{
          disableUnderline: error ? false : disableUnderline,
          error: error,
          classes: filledClasses,
        }}
        variant="filled"
        SelectProps={{
          classes: selectClasses,
          MenuProps: {
            PaperProps: {
              className: nativeSelectClasses.paper,
            },
          },
        }}
      >
        {options.map(({ label, value }, index) => (
         label && value && <MenuItem
            key={value}
            value={value}
            data-testid={testId ? `${testId}-item-${index}` : `item-${index}`}
          >
            {label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return (
    <TextField
      disabled={disable}
      {...commonSelectProps}
      InputProps={{
        error: error,
        classes: outlinedClasses,
      }}
      data-testid={testId || "common-outlined-native-select-input"}
      variant="outlined"
      select
    >
      {options.map(({ label, value }, index) => (
       label && value && <MenuItem
          key={value}
          value={value}
          data-testid={testId ? `${testId}-item-${index}` : `item-${index}`}
        >
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default NativeSelect;

import { BoxProps } from "@material-ui/core";
import React from "react";
import { Box } from "common";

const Section: React.FC<BoxProps> = ({ children, ...rest }) => {
  return <Box {...rest}>{children}</Box>;
};

Section.defaultProps = {
  width: ["95%", "95%", "90%", "90%"],
  margin: "20px auto 20px",
  maxWidth: "1520px",
};

export default Section;

import { Dispatch, MiddlewareAPI, AnyAction } from "redux";
import { apiStart, apiError, apiEnd } from "../actions/api.actions";
import { API } from "../store.types";

import { IApiMiddlewareAction, IState } from "../store.interface";
import { NetworkRequest, PostFileRequest } from "./api.middleware.helper";
import { setLocation } from "store/actions/auth.actions";

/**
 * middleware make the initiative load and inject the required parameters
 * @param {MiddlewareAPI} dispatch
 * @param {Dispatch} next
 * @param {any} action
 */
const apiMiddleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: Dispatch<IApiMiddlewareAction>
) => async (action: any) => {
  next(action);

  /**
   * on single request
   */
  if (action.type === API) {
    const {
      forFileSend,
      label,
      url,
      data,
      method,
      headers,
      onSuccess,
      onFailure,
      onFinally,
      isAbsoluteUrl,
      withToken,
      withStoreId,
      requestConfig,
      isStatic,
    } = action.payload;

    /**
     * initial
     */
    if (label) {
      dispatch(apiStart(label));
    }

    const state = getState() as IState;

    const {
      auth: { token_type, access_token, storeId },
    } = state;

    const token = `${token_type || "Bearer"} ${access_token}`;
    /**
     * do the network request
     */
    try {
      if(forFileSend) {
        const { data: incomingData } = await PostFileRequest({
          url,
          method,
          headers,
          data,
          isAbsoluteUrl,
          storeId: withStoreId ? storeId : "",
          token: withToken ? token : "",
          requestConfig,
          isStatic,
        });

        if (onSuccess) {
          dispatch(onSuccess(incomingData));
        }
        dispatch(apiEnd(label));
      } else {
        const { data: incomingData } = await NetworkRequest({
          url,
          method,
          headers,
          data,
          isAbsoluteUrl,
          storeId: withStoreId ? storeId : "",
          token: withToken ? token : "",
          requestConfig,
          isStatic,
        });

        if (onSuccess) {
          dispatch(onSuccess(incomingData));
        }
        dispatch(apiEnd(label));
      }
    } catch (error) {
      /**
       * function to handle mutiple kinds of errors
       * @param {any} error could be response or network error
       * @param {function} onFailure
       * @param {string} label
       * @param {Dispatch} dispatch
       */
      handleError(error, onFailure, label, dispatch);
    }

    if (onFinally) {
      /**
       * network request end
       */
      dispatch(onFinally());
    }
  }
};

const handleError = (
  error: any,
  onFailure: (errorMessage: string) => AnyAction,
  label: string,
  dispatch: Dispatch
) => {
  const errorResponseStatus = (error.response || {}).status || 0;

  let errorMessage;

  // In case of "Network Error" error should show "Network connection..." message

  if (
    [401].indexOf(errorResponseStatus) > -1 &&
    window.location.pathname !== "/login"
  ) {
    dispatch(setLocation("/logout"));
  }
  if (/network\serror/gi.test(error)) {
    // To be localised later!
    errorMessage =
      "Oops, something went wrong! Please check your network connection and try again.";
  }

  // In case of 5* error should show "Something went wrong..." message
  // String interpolation to convert code into String
  else if (`${errorResponseStatus}`.charAt(0) === "5") {
    errorMessage = "Oops! Something went wrong, please try again later.";

    // Anything else
  } else if (error && error.response && error.response.data) {
    const { error: responsedError = {} } = error.response.data;
    const { message } = responsedError;
    errorMessage = message;
  } else {
    errorMessage = error.message;
  }

  if (onFailure) {
    dispatch(onFailure(errorMessage || error.message));
  }
  dispatch(apiError(errorMessage, label));
};

export default apiMiddleware;

import { Box } from "common";
import { withStyles } from "@material-ui/core/styles";

export const Spacer = withStyles({
  root: {
    width: "100%",
    maxWidth: "160px",
    height: "1px",
    border: "1px dashed #979797",
    display: "flex",
  },
})(Box);

import { makeStyles, Theme } from "@material-ui/core/styles";
import COLORS from "common/Theme/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  deleteModal: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 16,
    width: "396px",
    background: theme.palette.common.white,
    border: "1px solid #E6E6E6",
    boxShadow: " 0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
    outline: "none",
    gap: 14,
    textAlign: "center",
  },
  normalText: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  boldText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
  },
  muiButton: {
    textTransform: "capitalize",
  },
  dropdownBox: {
    background: theme.palette.common.white,
    position: "absolute",
    zIndex: 1,
    top: "170px",
    width: "20%",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    maxHeight: "450px",
    overflow: "scroll",
  },
  arrowButton: {
    "&:disabled": {
      background: "none",
      color: "#797D7F",
      border: "none",
    },
    "&:hover": {
      background: "none",
    },
  },
  addCountryButton: {
    margin: "15px",
    width: "97%",
    borderRadius: 4,
    padding: "16px 48px",
    color: COLORS.BLUE,
    fontSize: 16,
    lineHeight: "28px",
    fontWeight: 700,
    cursor: "pointer",
    height: 100,
  },
  campaignNameChip: {
    background: "#F2F5F8",
    border: "1px solid #0E5AA7",
    borderRadius: 4,
    color: "#0E5AA7",
    padding:6,
    margin:4,
    marginLeft:0,
    marginRight:8
  },
}));

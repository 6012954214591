import React from "react";
import { CarrefourLogo } from "@mafc/icon";
import { styled } from "@material-ui/core/styles";

const Container = styled("div")({
  flexShrink: 0,
  height: "36px",
  width: "142px",
  marginTop: "-2px",
  display: "flex",
  cursor: "pointer",
  "& svg": {
    height: "100%",
    width: "100%",
  },
  "& path:first-of-type": {
    fill: "#0E5AA7",
  },
  "& path:nth-of-type(2)": {
    fill: '#ee2527'
  }
});

const Logo: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <Container data-testid="carrefour_logo" onClick={onClick}>
    <CarrefourLogo size="XL"/>
  </Container>
);

export default Logo;

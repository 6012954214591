import { IEntry } from 'components/SearchableInput';

import { Box, Select ,Input} from "common";
import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store/store.interface';
import { ERestrictionsSellerType } from '../FieldRenderer';
import { setPromotionObject } from 'store/actions/promotion.actions';

interface IProps {
    disable?: boolean;
    onChange?: (item: IEntry) => void;
    selectedItem?: string;
    label?: string;
    error?: string;
    value?: string;
}

const SellerType: FC<IProps> = ({
    disable,
    value = '',
    onChange, 
    selectedItem, 
    label, 
    error 
}) => {
    const sellerIdValue = useSelector((state:IState) => state?.promotion?.creationForm?.restrictions?.sellerId || '');
    const [selectedSellerType,setSelectedSellerType] = useState<string>(value);

    const [sellerId,setSellerId] = useState<string>(sellerIdValue);

    const dispatch = useDispatch();

    const {
        selectOptions,
        creationForm
    } = useSelector((state: IState) => ({
        ...state.config,
        ...state.promotion
    }))

    const option = useMemo(()=>{

        if(selectOptions['sellerType']){
            const value = selectOptions['sellerType'].filter(data => data.value !== 'BOTH').map((res)=>({ label : res.label , value: res.value }))
            
            return value.map((data) => ({ label : data.label , value:data.value}));

        }
        return  selectOptions['sellerType'];
    },[selectOptions])
    
    const onChangeHandler = (event:React.ChangeEvent<HTMLInputElement>) => {
        const { value } =event.target;
        setSelectedSellerType(value);
        setSellerId(value === ERestrictionsSellerType.RETAIL ? 'Carrefour' : '' )
        dispatch(setPromotionObject(value === ERestrictionsSellerType.RETAIL ? 'Carrefour' : '' , [creationForm.type === 'BADGE_CAMPAIGN' ? "badge" : "restrictions", creationForm.type === 'BADGE_CAMPAIGN' ? "seller"  :  "sellerId"]))
    }

    const onChangeInputHandler = (event:React.ChangeEvent<HTMLInputElement>) =>{
        const { value } = event.target;
        if(creationForm.type === 'BADGE_CAMPAIGN') {
            dispatch(setPromotionObject(value, ["badge", "seller"]))
        } else {
            dispatch(setPromotionObject(value, ["restrictions", "sellerId"]))
        }
        setSellerId(value);
    };  

    
    return (
        <>
        <Box 
        display="grid" 
        width="100%"
        gridTemplateColumns={selectedSellerType ? "1fr 1fr " : "auto"}
        gridGap="20px"
        alignItems="end"
        flexDirection="column"
        >

        <Box
            display="flex"
            width="100%"
            justifyContent="flex-start"
            mb="20px"
            flexDirection="column"
          >
            <Select
                disable={disable}
                options={option}
                onNativeChange={onChangeHandler}
                nativeValue={selectedSellerType}
                label={`Seller Type`}
                inputVariant="filled"
                disableUnderline={false}
                testId='select-seller-type'
                variant="native"
                size="small"
                width="100%"
                fullWidth={true}
                stickyLabel={false}
                error={!!error}
                errorMessage={error}
            />
        </Box>
        {selectedSellerType.length > 0 &&
            <Box
                display="flex"
                width="100%"
                justifyContent="flex-start"
                mb="20px"
                flexDirection="column"
            >
                <Input
                    disabled={(selectedSellerType === ERestrictionsSellerType.RETAIL || disable)}
                    value={sellerId}
                    placeholder="Seller Id"
                    onChange={onChangeInputHandler}
                    fontSize="16px"
                    size="medium"
                    testId='input-1-field-sellerid'
                />
            </Box>
        }
        </Box>
        </>
    )
};

export default SellerType;
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EDiscountTypes, IState } from "store/store.interface";
import Stepper from "components/Stepper";
import Layout from "Layout";
import PromotionForm, { EFormTypes } from "components/PromotionForm";
import _ from "lodash";
import { ECampaignStatus, IFormDetails, TFieldType } from "store/reducers/promotion.reducers";
import { setLocation } from "store/actions/auth.actions";
import Loader from "Loader/Form";
import { fetchFieldsDetails, setPromotionObject } from "store/actions/promotion.actions";
import Section from "Layout/Section";

const PromotionCreate: FC = () => {
  const {
    creationForm,
    formDetails = {},
    roles = [],
    canCreateCampaigns = [],
  } = useSelector((state: IState) => ({
    ...state.promotion,
    ...state.auth,
    ...state.config,
  }));
  const [currentStep, setCurrentStep] = useState(1);

  const dispatch = useDispatch();
  const stepsOptions = [
    { stepNumber: 1, title: "Basic Information" },
    { stepNumber: 2, title: "Restrictions" },
    { stepNumber: 3, title: "Summary" },
  ];

  const canCreateCampagin = useMemo(() => {
    const role = roles[0];
    return canCreateCampaigns && canCreateCampaigns.indexOf(role) > -1;
  }, [roles, canCreateCampaigns]);

  const [formErrors, setFormErrors] = useState<{
    [key: string]: { isError: boolean; message?: string };
  }>({});
  const { formValid ,incrementalData,incrementalType} = useSelector((state: IState) => state.promotion);
  const [disable, setDisable] = useState<boolean>(false);

  const [registeredFields, setRegisteredFields] = useState([] as TFieldType[]);

  const onRegisterField = (field: TFieldType) => {
    if (registeredFields.filter(({ id }) => id === field.id).length === 0) {
      setRegisteredFields((oldState: TFieldType[]) => [...oldState, field]);
    }
  };

  useEffect(() => {
    const form = JSON.parse(JSON.stringify(creationForm));
    if (
      creationForm.edit &&
      [
        ECampaignStatus.active,
        ECampaignStatus.completed,
        ECampaignStatus.paused,
      ].includes(form?.status)
    ) {
      setDisable(true);
    }
  }, [creationForm])



  const onUnRegisterField = (id: string) => {
    // not checking if was exist , because the new state not accessible from the useState
    // but exist inside the setRegisteredFields only using the oldState variable
    setRegisteredFields((oldState: TFieldType[]) => {
      return oldState.filter((field) => field.id !== id);
    });
  };

  const rangeValueHandler = useCallback(()=>{
    let updateValues : any[] =[];
    let maxValues = "";
    if(incrementalData){
     incrementalData && incrementalData.forEach((res:any,index:number)=>{
      maxValues = res.maxAmount;
      updateValues.push({
        discount:{
          type: incrementalType,
          value: parseInt(res.value)
        },
        slot:[parseInt(res?.min),parseInt(res?.max)]
      })
      dispatch(setPromotionObject(updateValues, ['discount', 'rules', 'metaData', 'discountRanges']));
      if(incrementalType ==="PERCENTAGE"){
        dispatch(setPromotionObject(parseInt(maxValues), ['discount', 'rules', 'metaData', 'discountRanges', `${incrementalData.length-1}`,'discount', 'maxAmount']));
      }
    })
  }
  },[dispatch,incrementalData,incrementalType]);
  const onValidateInputs = (
    errorObj: { [key: string]: { isError: boolean; message?: string } } = {}
  ) => {
    setFormErrors((oldState) => ({ ...oldState, ...errorObj }));
  };
  
  const onNextHandler = () => {
    if (currentStep < 3 && canGoNext) {
      setCurrentStep((oldStep) => oldStep + 1);
    }
    if(currentStep===1){
      rangeValueHandler();
    }
  };

  const onBackHandler = () => {
    if (currentStep > 1) {
      setCurrentStep((oldStep) => oldStep - 1);
    } else {
      dispatch(setLocation("/"));
    }
  };

  const getValue: any = useCallback(
    (keys = []) => {
      const value = _.get(creationForm, keys.join(".")) || "";
      return value;
    },
    [creationForm]
  );

  // /**
  //  * function to check if the registered values is required and has value
  //  */
  const validateFields = useCallback(
    (shouldRegisterErrors: boolean) => {
      let updatedErrors: {
        [key: string]: { isError: boolean; message?: string };
      } = { ...formErrors };
      registeredFields.forEach((field) => {
        const { isRequired, keys, startDateKeys, endDateKeys, name, atLeast } = field;
        let isError = false;
        if (isRequired) {
          const value = getValue(keys);
          const startDateValue = getValue(startDateKeys);
          const endDateValue = getValue(endDateKeys);
          if (name === "dateRange") {
            if (
              !startDateValue ||
              !endDateValue ||
              startDateValue < new Date()
            ) {
              isError = true;
            }
          } else if (!value) {
            isError = true;
          }
        }
        if(atLeast) {
          const haveValue = atLeast?.map(res => getValue(res.keys).length > 0 ? true : false);
          if(haveValue.includes(true)) {
            isError = false;
          }
        }
        if (
          (formErrors &&
            formErrors[name] &&
            !formErrors[name].isError &&
            !isError) ||
          isError
        ) {
          updatedErrors = { ...updatedErrors, ...{ [name]: { isError } } };
        }
      });
      if (shouldRegisterErrors) {
        setFormErrors((oldErrors) => ({
          ...oldErrors,
          ...updatedErrors,
        }));
      }
      return (
        Object.keys(updatedErrors).filter(
          (key) => updatedErrors[key as string].isError
        ).length === 0
      );
    },
    [getValue, registeredFields, formErrors]
  );

  const FormValidationHandler = useCallback(()=>{
    let isError = false;
    const validationValue = validateFields(false);
    if(creationForm.discount?.type === "INCREMENTAL"){
      if(formValid){
        if(validationValue){
          isError = true;
        }else{
          isError = false;
        }
      }else{
        isError = false;
      }
    }else{
      if(validationValue){
        isError= true;
      }else{
        isError = false
      }
    }
    return isError;
  },[creationForm.discount,formValid,validateFields]);

  const canGoNext = useMemo(() => {
    if (currentStep === 1) {
      return FormValidationHandler();
    } else if (currentStep === 2) {
      const { restrictions, discount } = creationForm;
      const { restrictions: formRestrictions } = (formDetails ||
        {}) as IFormDetails;
      if (restrictions && Object.keys(restrictions).length > 0) {
        let validations = [] as boolean[];
        formRestrictions.forEach((restriction) => {
          const { fields } = restriction;
          fields.forEach((field) => {
            const { isRequired, name, id, when } = field;
            if (isRequired) {
              const value = getValue(["restrictions", name]);
              if (value) {
                validations.push(true);
              } else {
                if(id === 'bin_lock_coupon_1') {
                  if(when.length > 0) {
                    when.forEach(({keys, values}: any)=> {
                      if(values.indexOf(creationForm.couponType) > -1) {
                        validations.push(false)
                      } else {
                        validations.push(true)
                      }
                    })
                  }
                } else {
                  validations.push(false);
                }
              }
            } else if (
              (discount?.type === EDiscountTypes.FREE_DELIVERY &&
                name === "shipmentType" &&
                !restrictions["shipmentType"]) ||
              (name === "minOrderValue" && restrictions["minOrderValue"] == 0)
            ) {
              validations.push(false);
            }
            validations.push(true);
          });
        });
        return validations.filter((validation) => !validation).length === 0;
      }
    }
    return false;
  }, [creationForm, FormValidationHandler,currentStep, formDetails, getValue]);

  const goToBasicInfoSection = () => {
    setCurrentStep(1);
  };

  const goToRestrictionsSection = () => {
    setCurrentStep(2);
  };

  const formType = useMemo(() => {
    switch (currentStep) {
      case 2:
        return EFormTypes.RESTRICTIONS;
      case 3:
        return EFormTypes.SUMMARY;
      default:
        return EFormTypes.BASIC;
    }
  }, [currentStep]);

  const noForm = useMemo(
    () => !formDetails || Object.keys(formDetails).length === 0,
    [formDetails]
  );

  const campaignType = useMemo(() => {
    return creationForm.type;
  }, [creationForm]);

  useEffect(() => {
    setRegisteredFields([]);
    setFormErrors({});
  }, [campaignType]);

  useEffect(() => {
    const { type } = creationForm || {};
    if (!type) {
      dispatch(setLocation("/"));
    } else if (noForm) {
      setCurrentStep(1);
      dispatch(fetchFieldsDetails());
    }
  }, [noForm, dispatch, creationForm]);

  useEffect(() => {
    if (!canCreateCampagin) {
      dispatch(setLocation("/"));
    }
  }, [canCreateCampagin, dispatch]);

  if (noForm) {
    return (
      <Layout>
        <Section>
          <Loader withStepper />
        </Section>
      </Layout>
    );
  }
  return (
    <Layout>
      <Section>
        <Stepper currentStep={currentStep} steps={stepsOptions} />
        <PromotionForm
          disable={disable}
          formType={formType}
          onNext={onNextHandler}
          onBack={onBackHandler}
          onValidateInputs={onValidateInputs}
          registerField={onRegisterField}
          unRegisterField={onUnRegisterField}
          fieldsErrors={formErrors}
          getValue={getValue}
          canGoNext={canGoNext}
          onEditBasicInfo={goToBasicInfoSection}
          onEditRestrictions={goToRestrictionsSection}
          isLastStep={
            currentStep === stepsOptions[stepsOptions.length - 1].stepNumber
          }
        />
      </Section>
    </Layout>
  );
};

export default PromotionCreate;

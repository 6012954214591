import Dialog from "@material-ui/core/Dialog";
import {
  spacing,
  palette,
  sizing,
  display,
  borders,
  flexbox,
  grid,
  positions,
  shadows,
  compose,
} from "@material-ui/system";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { styled, makeStyles, Theme } from "@material-ui/core/styles";
import { IDialogProps } from "./Dialog.interface";
import { EPositions } from "common/common.interface";

const closeIconPositionHandler = (closeIconPosition:EPositions|undefined) =>{
  if (closeIconPosition === "start"){
    return "flex-start"
  }else{
    return "flex-end"
  }
};

export const useDialogStyles = makeStyles({
  root: {
    boxShadow: ({ boxShadow }: IDialogProps & { isMobile?: boolean }) =>
      boxShadow,
    borderRadius: ({ borderRadius, isMobile }) =>
      isMobile ? "0px" : borderRadius,
    padding: ({ padding, withColoredHeader }) =>
      withColoredHeader ? "0px" : padding,
    maxWidth: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
  },
  icon: {
    color: "#fff",
  },
});

export const useDialogTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: ({ withColoredHeader, color }: IDialogProps) => {
      if (withColoredHeader) {
        switch (color) {
          case "primary":
            return theme.palette.primary.main;
          case "secondary":
            return theme.palette.secondary.main;
          default:
            return "inherit";
        }
      }
      return "inherit";
    },
    padding: ({ titlePadding = "0px" }) => titlePadding,
    "& h2": {
      margin: "0px",
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: ({ closeIconPosition, dialogTitle }: IDialogProps) =>
        dialogTitle
          ? "space-between"
          : closeIconPositionHandler(closeIconPosition),
    },
  },
}));
export const StyledDialog = styled(Dialog)(
  compose(
    palette,
    spacing,
    borders,
    display,
    sizing,
    grid,
    flexbox,
    positions,
    shadows
  )
);
export const StyledDialogActions = styled(DialogActions)(
  compose(palette, spacing, borders, display, sizing, grid, flexbox, positions)
);

export const StyleDialogContent = styled(DialogContent)(
  compose(
    palette,
    spacing,
    borders,
    display,
    sizing,
    grid,
    flexbox,
    positions,
    shadows
  )
);

import { useApiInfo } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCreationForm } from "store/actions/promotion.actions";
import {
  CHANGE_PASSWORD,
  PROMOTION_FETCH_CAMPAIGNS,
  PROMOTION_UPDATE_STATUS,
} from "store/store.types";
import { useStyles } from "./Dashboard.style";
import PromotionListing from "./PromotionListing";
import Filters from "./Filters";
import { Box, Button, Card, Dialog, Snackbar, Text } from "common";
import SelectCampaign from "./Modal";
import { IState } from "store/store.interface";
import { Add } from "@material-ui/icons";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { clearApi } from "store/actions/api.actions";
import ListIcon from "@material-ui/icons/Reorder";
import GridIcon from "@material-ui/icons/Apps";
import LoadMore from "./LoadMore";
import ChangePassword from "components/MyProfile/ChangePassword/ChangePassword";
import SuccessNotification from "components/MyProfile/ChangePassword/SuccessNotification";

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { canCreateCampaigns = [], roles = [],forceChangePassword=false,success=false } = useSelector(
    (state: IState) => ({
      ...state.auth,
      ...state.config,
      ...state.api[CHANGE_PASSWORD]
    })
  );

  const { buttonWrapper } = useStyles({});

  const [listing, setListing] = useState<any>("list");
  const { dashboard } = useSelector(
    (state: IState) => state.promotion
  );

  const canCreateCampaign = useMemo(() => {
    const role = roles[0];
    return canCreateCampaigns && canCreateCampaigns.indexOf(role) > -1;
  }, [roles, canCreateCampaigns]);

  const [isSelectCampaignOpen, setIsSelectCampaignOpen] = useState(false);
  const onClickHandler = () => {
    setIsSelectCampaignOpen((old) => !old);
  };

  useEffect(() => {
    dispatch(clearCreationForm());
  }, [dispatch]);

  const { error } = useApiInfo(PROMOTION_FETCH_CAMPAIGNS);
  const { error: updateStatusError } = useApiInfo(PROMOTION_UPDATE_STATUS);

  const onCloseError = () => {
    dispatch(clearApi(PROMOTION_FETCH_CAMPAIGNS));
    dispatch(clearApi(PROMOTION_UPDATE_STATUS));
  };

  const onClickList = () => {
    setListing("list");
  };

  const onClickGrid = () => {
    setListing("grid");
  };

  return (
    <>
      {(error || updateStatusError) && (
        <Snackbar
          horizontal="center"
          vertical="top"
          variant="filled"
          severity="error"
          handleClose={onCloseError}
          open={!!error || !!updateStatusError}
        >
          {error || updateStatusError}
        </Snackbar>
      )}
      {isSelectCampaignOpen && (
        <Dialog
          open={isSelectCampaignOpen}
          onBackdropClick={onClickHandler}
          onClose={isMobile ? onClickHandler : undefined}
        >
          <SelectCampaign onFinish={onClickHandler} />
        </Dialog>
      )}
        {success && <SuccessNotification />}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={["column-reverse", "row", "row", "row"]}
        my={["0px", "15px", "15px", "15px"]}
      >
        <Text
          fontSize="22px"
          fontWeight={600}
          mt={["10px", "0px", "0px", "0px"]}
        >
          Promotions
        </Text>
        {canCreateCampaign && (
          <Button
            fontSize="14px"
            fontWeight={600}
            minWidth="220px"
            padding="10px"
            round
            color="secondary"
            onClick={onClickHandler}
            variant="contained"
          >
            <Box className={buttonWrapper} display="flex" alignItems="center">
              <Add color="inherit" /> <span>Create Campaign</span>
            </Box>
          </Button>
        )}
      </Box>
      <Box>
        <Card
          padding={["30px 15px", "30px"]}
          borderRadius="5px"
          boxShadow="0px 0px 16px 0px rgb(0 0 0 / 9%)"
          marginTop="15px"
        >
          <Box
            display="flex"
            padding="0 0 25px 0"
            borderBottom={`1px solid ${theme.palette.background.default}`}
            justifyContent="space-between"
            alignItems="center"
          >
            <Filters />
            {!isTablet && (
              <Box
                gridTemplateColumns="1fr 1fr"
                gridGap="10px"
                height="fit-content"
                margin="20px 0px 0px"
              >
                <IconButton onClick={onClickList}>
                  <ListIcon color="primary" />
                </IconButton>
                <IconButton onClick={onClickGrid}>
                  <GridIcon color="primary" />
                </IconButton>
              </Box>
            )}
          </Box>
          <PromotionListing listing={listing} />
          {dashboard && dashboard.length > 0 && (
            <LoadMore />
          )}
        </Card>
        {forceChangePassword && <ChangePassword isOpen={forceChangePassword} forceChange={true}/>}
      </Box>
    </>
  );
};

export default Dashboard;

import React from "react";
import Layout from "Layout";
import Section from "Layout/Section";
import ProfileComponent from "components/MyProfile";


const MyProfile: React.FC = () => {
  
  return (
    <Layout>
      <Section>
        <ProfileComponent />
      </Section>
    </Layout>
  );
};

export default MyProfile;

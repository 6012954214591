import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/store.interface";
import FilterIcon from "@material-ui/icons/FilterList";
import {
  addFilters,
  removeFilters,
  resetFilters,
} from "store/actions/promotion.actions";
import { Button, Box, DatePicker, Select, Text, Dialog, Chip } from "common";
import { format, isValid } from "date-fns";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
const Filters: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);


  
  const {
    selectOptions,
    filters,
    storeId,
    permissions = {},
  } = useSelector((state: IState) => ({
    ...state.config,
    ...state.promotion,
    ...state.auth,
  }));

  const permittedPromotions = useMemo(() => {
    const storePermission = permissions[storeId || ""]; 
    let selectedCampagins = selectOptions.promotionTypes;
    if (storePermission) {
      const allowedCampaigns = (
        storePermission.find((permission) => permission.type === "campaign") ||
        {}
      ).value?.split(",");
      if(allowedCampaigns){
        if(allowedCampaigns.find(permission => permission === "DISCOUNT_COUPON")){
          allowedCampaigns.push("BULK_COUPON");
        }
      }
      if (allowedCampaigns && allowedCampaigns.length > 0) {
        selectedCampagins = selectedCampagins.filter((campaign) => {
          const campaignName =campaign.value === "COUPON" ? "DISCOUNT_COUPON" : campaign.value;
          return !!allowedCampaigns.find(
            (campaign) => campaign.trim() === campaignName
          );
        });
      }
    }
    return selectedCampagins || [];

  }, [permissions, selectOptions, storeId]);


  const [dateRange, setDateRange] = useState({
    startDate: tomorrow,
    endDate: tomorrow,
  });
  const dispatch = useDispatch();

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onChangeStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(addFilters({ name: "Status", value: event.target.value }));
    },
    [dispatch]
  );

  const onChangeTypes = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "COUPON") {
        dispatch(addFilters({ name: "Object", value: event.target.value }));
      } else {
        dispatch(addFilters({ name: "type", value: event.target.value }));
      }
    },
    [dispatch]
  );

  const onClearFilter = useCallback(
    (data: any) => () => {
      if (data.label === "Date") {
        setDateRange({ startDate: tomorrow, endDate: tomorrow });
      }
      dispatch(removeFilters({ name: data.label, value: data.value }));
    },
    [dispatch]
  );

  const onChangeStartDateHandler = useCallback(
    (date: any) => {
      setDateRange((oldDates) => ({ ...oldDates, startDate: date }));
      if (isValid(date)) {
        dispatch(
          addFilters({
            name: "startDate",
            value: `${date.toISOString()}`,
          })
        );
      }
    },
    [dispatch]
  );

  const onChangeEndtDateHandler = useCallback(
    (date: any) => {
      setDateRange((oldDates) => ({ ...oldDates, endDate: date }));
      if (isValid(date)) {
        dispatch(
          addFilters({
            name: "endDate",
            value: `${date.toISOString()}`,
          })
        );
      }
    },
    [dispatch]
  );

  const onResetFilters = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const list = useMemo(() => {
    let updatedList = [] as any;
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        updatedList = [
          ...updatedList,
          ...filter.value.map((value) => {
            if (filter.name === "startDate" || filter.name === "endDate") {
              return {
                label: filter.name,
                value: `${format(new Date(value), "MMMM d','yyyy")}`,
              };
            }
            return {
              label: filter.name,
              value: `${value}`,
            };
          }),
        ];
      });
      return updatedList;
    }
  }, [filters]);

  const filtersCapsules = useMemo(() => {
    return (
      <>
        {list && list.length > 0 && (
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" margin="40px 0px 0px">
              <Button
                data-testid="clear-filters-button"
                variant="outlined"
                padding="10px"
                round
                minWidth="135px"
                margin="0 10px 0 0"
                onClick={onResetFilters}
                color="primary"
                size="medium"
                height="38px"
              >
                Clear filters
              </Button>
              <Box display="flex" flexWrap="nowrap" overflow="scroll">
                {list.map(({ label, value }: any) => (
                  <Chip
                    key={`${label}-${value}`}
                    label={
                      <Text mx="8px" fontWeight={600}>
                        {value}
                      </Text>
                    }
                    icon={
                      <Text fontSize="12px" margin="0px" color="text.secondary">
                        {label}:
                      </Text>
                    }
                    onDelete={onClearFilter({ label, value })}
                    borderRadius="18.5px"
                    backgroundColor="#fff"
                    border="1px solid #CFCFCF"
                    height="32px"
                    margin="0 10px 0 0"
                    data-testid="tag-wrapper"
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}
        {isMobile && (
          <Box marginTop="15px">
            <Button
              onClick={onCloseModal}
              variant="contained"
              round
              height="38px"
              color="secondary"
            >
              {list && list.length > 0 ? "Apply" : "Close"}
            </Button>
          </Box>
        )}
      </>
    );
  }, [list, onClearFilter, isMobile, onResetFilters]);

  const filtersFileds = useMemo(() => {
    return (
      <Box
        gridTemplateColumns={isMobile ? "1fr" : "repeat(4, 1fr)"}
        width={["100%", "100%", "70%", "70%"]}
        gridGap="10px"
      >
        <Select
          options={selectOptions["statusTypes"]}
          onNativeChange={onChangeStatus}
          label="Status"
          nativeValue=""
          stickyLabel={true}
          width="100%"
          size="small"
          variant="native"
          inputVariant="outlined"
          testId="filter-select-status"
        />
        <Select
          options={permittedPromotions}
          label="Type"
          stickyLabel={true}
          nativeValue=""
          onNativeChange={onChangeTypes}
          width="100%"
          size="small"
          variant="native"
          inputVariant="outlined"
        />
        <DatePicker
          onChange={onChangeStartDateHandler}
          width="100%"
          size="small"
          inputVariant="outlined"
          value={dateRange.startDate}
          testId="date-picker-start"
        />
        <DatePicker
          onChange={onChangeEndtDateHandler}
          width="100%"
          size="small"
          inputVariant="outlined"
          value={dateRange.endDate}
          testId="date-picker-end"
        />
      </Box>
    );
  }, [
    dateRange,
    selectOptions,
    isMobile,
    onChangeEndtDateHandler,
    onChangeStartDateHandler,
    onChangeStatus,
    onChangeTypes,
    permittedPromotions,
  ]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {isMobile && open && (
        <Dialog open={open} onBackdropClick={onCloseModal} padding="20px">
          <Box>{filtersFileds}</Box>
          {filtersCapsules}
        </Dialog>
      )}
      <Box
        display="flex"
        justifyContent={isMobile ? "space-between" : "flex-start"}
        alignItems="center"
        margin="20px 0px 0px"
      >
        <Text
          fontSize="16px"
          fontWeight={500}
          whiteSpace="nowrap"
          marginRight="20px"
        >
          Filter By
        </Text>
        {isMobile ? (
          <IconButton onClick={onOpenModal}>
            <FilterIcon color="primary" />
          </IconButton>
        ) : (
          filtersFileds
        )}
      </Box>
      {!isMobile ? filtersCapsules : null}
    </Box>
  );
};

export default Filters;

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Box, Button, Input, Select } from "common";
import { TSelectOption } from "common/Select/Select.interface";
import React from "react";
import { useSelector } from "react-redux";
import { IState } from "store/store.interface";

export const TextFieldInput = ({
  error,
  name,
  value,
  label,
  id,
  disabled = false,
  onChangeHandler,
}: {
  error: string;
  name: string;
  value: string;
  label: string;
  id?: string;
  disabled?: boolean;
  onChangeHandler: (e: any) => void;
}) => {
  return (
    <Input
      data-testid={id}
      error={error ? true : false}
      errorMessage=""
      type="text"
      size="medium"
      name={name}
      label={label}
      value={value}
      onChange={onChangeHandler}
      variant="outlined"
      formControlMargin="8px"
      autoComplete="off"
      disabled={disabled}
    />
  );
};

export const PasswordFieldInput = ({
  error,
  name,
  value,
  label,
  showPassword,
  onChangeHandler,
  showPasswordHandler,
}: {
  error: string;
  name: string;
  value: string;
  label: string;
  showPassword: boolean;
  onChangeHandler: (e: any) => void;
  showPasswordHandler: (type: string) => void;
}) => {
  return (
    <Input
      data-testid="password"
      error={error ? true : false}
      errorMessage=""
      name={name}
      label={label}
      value={value}
      fontSize="16px"
      formControlMargin="8px"
      type={showPassword ? "text" : "password"}
      onChange={onChangeHandler}
      size="medium"
      variant="outlined"
      width="100%"
      autoComplete="email"
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            data-testid="showPassword"
            onClick={() => {
              showPasswordHandler(
                `show${name.substring(0, 1).toUpperCase()}${name.substring(1)}`
              );
            }}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export const CountryCampaignFieldSet = ({
  countryChangeHandler,
  campaignChangeHandler,
  countryValue,
  campaignValues,
  index,
}: {
  countryChangeHandler: (e: any, index: number) => void;
  campaignChangeHandler: (
    e: any,
    value: TSelectOption[],
    index: number
  ) => void;
  countryValue: string;
  campaignValues: string[];
  index: number;
}) => {
  const {
    selectOptions: { countries = [], promotionTypes = [] },
  } = useSelector((state: IState) => ({
    ...state.config,
  }));
  const options = countries.filter((item) => !item.value.includes("instore"));
  let selectedCampaigns = promotionTypes.filter((item) =>
    campaignValues?.includes(item.value)
  );
  if (campaignValues?.includes("DISCOUNT_COUPON")) {
    selectedCampaigns.unshift(promotionTypes[0]);
  }

  const handleCampaignChange = (e: any, value: TSelectOption[]) => {
    campaignChangeHandler(e, value, index);
  };
  const handleCountryChange = (e: any) => {
    countryChangeHandler(e, index);
  };
  return (
    <>
      <Box mt={2} ml={2}>
        <Select
          testId="country-select"
          name={"country"}
          options={options}
          label={"Country"}
          size="medium"
          inputVariant="outlined"
          variant="native"
          width="100%"
          nativeValue={countryValue}
          onNativeChange={handleCountryChange}
        />
      </Box>
      <Box mt={2} ml={2}>
        <Autocomplete
          multiple
          data-testid="tags-standard"
          defaultValue={selectedCampaigns}
          options={promotionTypes}
          getOptionLabel={(option) => option.label}
          onChange={handleCampaignChange}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Campaigns" />
          )}
        />
      </Box>
    </>
  );
};

export const BottomButtonTemplate = ({
  onCancelClick,
  isDisabled = false,
  onAddUserHandler,
  title,
  testid,
}: {
  onCancelClick: () => void;
  isEditable?: boolean;
  isDisabled?: boolean;
  onAddUserHandler: () => void;
  title: string;
  testid?: string;
}) => {
  return (
    <Box
      display="flex"
      ml={-1}
      style={{ position: "fixed", bottom: "0" }}
      bgcolor="#fff"
      width={"30%"}
    >
      <Button
        fontSize="14px"
        fontWeight={600}
        padding="10px"
        round
        width="95%"
        color="primary"
        variant="outlined"
        margin="10px"
        onClick={onCancelClick}
        data-testid="cancel-click-btn"
      >
        Cancel
      </Button>
      <Button
        disabled={isDisabled}
        fontSize="14px"
        fontWeight={600}
        padding="10px"
        width="95%"
        round
        color="primary"
        variant="contained"
        margin="10px"
        onClick={onAddUserHandler}
        data-testid={testid}
      >
        {title}
      </Button>
    </Box>
  );
};

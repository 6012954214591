import React, { FC } from "react";
import Step from "./Step";
import { Spacer } from "./Stepper.style";
import { Box } from "common";

interface ISteps {
  stepNumber: number;
  title: string;
}
interface IProps {
  currentStep: number;
  steps: ISteps[];
}

const Stepper: FC<IProps> = ({ currentStep, steps }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      maxWidth="800px"
      width="100%"
      margin="auto"
    >
      {steps.map(({ stepNumber, title }, index) => (
        <React.Fragment key={`${title}_${index}`}>
          <Step
            stepNumber={stepNumber}
            isActive={currentStep === stepNumber}
            isDone={currentStep > stepNumber}
            title={title}
          />
          {index < steps.length - 1 && <Spacer />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Stepper;

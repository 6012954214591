import React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { IProps, TThemeModes } from "./Theme.interface";

const values: { [key: string]: number } = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};
export const theme = (themeMode: TThemeModes, extraTheme: any) =>
  responsiveFontSizes(
    createTheme({
      overrides: {
        MuiCssBaseline: {
          "@global": {
            html: {
              msScrollbar3dlightColor: "none",
              msOverflowStyle: "none",
              "& #root": {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                minHeight: "100%",
                backgroundColor: "transparent",
                height: "100vh",
              },
              "& ::-webkit-scrollbar": {
                display: "none",
              },
              "& body": {
                margin: 0,
                padding: 0,
                boxSizing: "border-box",
                display: "flex",
                backgroundColor: "transparent",
              },
              "& ul": {
                listStyle: "none",
              },
              "& p": {
                margin: "0px",
              },
              "& a": {
                textDecoration: "none",
                color: "#017DFF",
                "&:hover": {
                  color: "#0161C7",
                },
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "rgba(0, 0, 0, 0.55)",
              },
              "& .MuiBackdrop-container": {
                filter: "blur(1.5px)",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              },
              "& .MuiFilledInput-root": {
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                fontSize: "16px",
              },
              "& section": {
                margin: 0,
                padding: 0,
              },
            },
          },
        },
      },
      props: {
        MuiTypography: {
          gutterBottom: true,
          noWrap: true,
        },
        MuiButton: {
          disableElevation: true,
          disableRipple: true,
        },
      },
      typography: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 14,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
          fontSize: "26px",
          fontWeight: 600,
          color: "#000000",
        },
        h2: {
          fontSize: "14px",
          fontWeight: 500,
          color: "#808080",
        },
        button: {
          fontSize: "14px",
          fontWeight: 400,
          minWidth: "127px",
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
        keys: ["xs", "sm", "md", "lg"],
        up: (key) => `@media (min-width:${values[key as string]}px)`,
        down: (key) => `@media (max-width: ${values[key as string]}px)`,
      },
      shape: {
        borderRadius: 4,
      },
      palette: {
        type: themeMode,
        background: {
          default: "#F5F6F8",
        },
        primary: {
          light: "#1279e2",
          main: "#0E5AA7",
          dark: "#084482",
        },
        secondary: {
          light: "#f15a5c",
          main: "#DD2326",
          dark: "#d81215",
        },
      },
      ...extraTheme,
    })
  );

const CustomTheme: React.FC<IProps> = ({
  children,
  extraTheme = {},
  themeMode = "light",
}) => (
  <ThemeProvider theme={theme(themeMode, extraTheme)}>{children}</ThemeProvider>
);
export default CustomTheme;

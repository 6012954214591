import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  ReducersMapObject,
} from "redux";
import thunk from "redux-thunk";
import apiMiddleware from "./middlewares/api.middleware";
import { INITIAL_STATE as CONFIG_INITIAL_STATE } from "./reducers/config.reducers";
import { INITIAL_STATE as PROMOTION_INITIAL_STATE } from "./reducers/promotion.reducers";
import { USERS_INITIAL_STATE } from "./reducers/users.reducers";
import { AUTH_LOGOUT } from "./store.types";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const configureStore = (
  preloadedState = {},
  reducers: ReducersMapObject<any>
) => {
  let combinedMiddlewares = [thunk, apiMiddleware];
  const appReducer = combineReducers({
    ...reducers,
  });
  const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === AUTH_LOGOUT) {
      state = undefined;
    }

    return appReducer(state, action);
  };

  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...combinedMiddlewares))
  );
};

export const INITIAL_STATE = {
  api: {},
  auth: { access_token: "", refresh_token: "", storeId: "", expired_in: 0 },
  config: CONFIG_INITIAL_STATE,
  promotion: PROMOTION_INITIAL_STATE,
  users: USERS_INITIAL_STATE
};

export default configureStore;

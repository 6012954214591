import React, { FC, useCallback } from "react";
import { useStyles } from "./SearchBox.style";
import { MagnifyGlass } from "@mafc/icon";
import { Input } from "common";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCampaigns,
  searchForCampaigns,
  setSearchTerm,
} from "store/actions/promotion.actions";
import { IState } from "store/store.interface";
import { setLocation } from "store/actions/auth.actions";

interface IProps {
  placeholder: string;
}
const SearchBox: FC<IProps> = ({ placeholder }) => {
  const { iconWrapper } = useStyles();
  const { searchTerm = "" } = useSelector((state: IState) => state.promotion);
  const dispatch = useDispatch();
  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(e.target.value));
  };

  const onSearchButtonClick = useCallback(() => {
    if (searchTerm) {
      dispatch(searchForCampaigns({ searchTerm }));
    } else {
      dispatch(fetchCampaigns());
    }
    dispatch(setLocation("/"));
  }, [dispatch, searchTerm]);

  const onKeyPressHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keyCode = event.which || event.keyCode;
      if (keyCode === 13) {
        onSearchButtonClick();
      }
    },
    [onSearchButtonClick]
  );

  return (
    <Input
      name="inputText"
      value={searchTerm}
      testId="header_search__inp"
      type="text"
      placeholder={placeholder}
      onChange={onChangeSearchText}
      autoComplete="off"
      onKeyDown={onKeyPressHandler}
      size="small"
      variant="outlined"
      width="300px"
      endAdornment={
        <a
          data-testid="header_search__button"
          onClick={onSearchButtonClick}
          className={iconWrapper}
          href="/#/"
        >
          <MagnifyGlass size="M" fill="#fff" />
        </a>
      }
    />
  );
};

export default SearchBox;

import { Drawer } from "@material-ui/core";
import { Add, Error } from "@material-ui/icons";
import { Box, Button, Text } from "common";
import { TSelectOption } from "common/Select/Select.interface";
import COLORS from "common/Theme/colors";
import RadioGroup from "components/RadioGroup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser, updateUserById } from "store/actions/user.actions";
import { IUserData } from "store/reducers/users.reducers";
import { EPromotionTypes, IState } from "store/store.interface";
import { ADD_NEW_USER, UPDATE_USER_BY_ID } from "store/store.types";
import { refineValues } from "../user.helper";
import { useStyles } from "../Users.style";
import {
  BottomButtonTemplate,
  CountryCampaignFieldSet,
  PasswordFieldInput,
  TextFieldInput,
} from "./InputFieldTemplate";

const AddUserComponent = ({
  isDrawerOpen,
  drawerHandler,
  isEditable = false,
}: {
  isDrawerOpen: boolean;
  drawerHandler: any;
  isEditable?: boolean;
}) => {
  const {
    userToEdit: userDetails = {
      name: "",
      email: "",
      id: "",
      roles: [],
      storeIds: [],
      permissions: {},
    },
  } = useSelector((state: IState) => state.users);
  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showPassword: false,
    selectedCountry: [],
    role: "",
    permissions: {},
  };
  const setInitialValues = (userDetails: IUserData) => {
    const { name, email, storeIds, permissions, roles } = userDetails;
    return {
      firstName: name.substring(0, name.indexOf(" ")),
      lastName: name.substring(name.indexOf(" ") + 1),
      email: email,
      selectedCountry: storeIds,
      role: roles[0],
      permissions: permissions || {},
    };
  };

  const [values, setValues] = useState<any>(initialFormState);
  const dispatch = useDispatch();
  const { addCountryButton } = useStyles();
  const { error = "", success = false } = useSelector((state: IState) => ({
    ...state.api[ADD_NEW_USER],
    ...state.api[UPDATE_USER_BY_ID],
  }));

  useEffect(() => {
    if (isEditable && userDetails.id) {
      const updatedValues = setInitialValues(userDetails);
      setValues((values: any) => ({ ...values, ...updatedValues }));
      setCountryCampaignSet(
        new Array(updatedValues.selectedCountry.length).fill(1)
      );
    }
  }, [userDetails, isEditable]);

  const [countryCampaignSet, setCountryCampaignSet] = useState(
    new Array(userDetails.storeIds.length || 1).fill(1)
  );
  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setValues((values: any) => ({ ...values, [name]: value }));
  };
  const onChangeRadioHandler = (e: any) => {
    if (e.target && e.target.value)
      setValues((values: any) => ({ ...values, role: e.target.value }));
  };
  const handleClickShowPassword = (type: string) => {
    setValues({ ...values, [type]: !values[type] });
  };

  const countryChangeHandler = (e: any, index: number) => {
    const { value } = e.target;
    const prevValue = userDetails.storeIds[index];
    const newArr = values.selectedCountry;
    newArr[index] = value;
    setValues({
      ...values,
      selectedCountry: newArr,
      permissions: {
        ...values.permissions,
        [value]: userDetails.permissions[prevValue] || [],
        [prevValue]: undefined,
      },
    });
  };
  const campaignChangeHandler = (
    e: any,
    value: TSelectOption[],
    index: number
  ) => {
    const campaignArr = value.map((item) =>
      item.value === EPromotionTypes.COUPON
        ? EPromotionTypes.DISCOUNT_COUPON
        : item.value
    );
    setValues({
      ...values,
      permissions: {
        ...values.permissions,
        [values.selectedCountry[index]]: campaignArr,
      },
    });
  };

  const onAddCountryHandler = () => {
    setCountryCampaignSet([...countryCampaignSet, 1]);
  };
  const onCancelClick = () => {
    drawerHandler(false);
    setValues(!isEditable ? initialFormState : setInitialValues(userDetails));
  };

  const isCreateUserButtonDisabled =
    !values.firstName ||
    !values.lastName ||
    !values.email ||
    (!isEditable && !values.password) ||
    !values.role ||
    !Object.keys(values.permissions).length;

  const onAddUserHandler = () => {
    const reqBody = {
      ...(!isEditable && { password: values.password, email: values.email }),
      roles: [values.role],
      profile: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
      permissions: refineValues(values.permissions),
    };
    dispatch(
      isEditable ? updateUserById(reqBody, userDetails.id) : addNewUser(reqBody)
    );
    if (success) drawerHandler(false);
  };

  return (
    <Drawer open={isDrawerOpen && !success} anchor={"right"}>
      <Box
        display="flex"
        flexDirection="column"
        width={"580px"}
        padding={3}
        justifyContent="space-between"
      >
        <Text fontSize={24} fontWeight={700}>
          {isEditable ? "EDIT USER" : "NEW USER"}
        </Text>
        <Text fontSize={16} fontWeight={700} my={2}>
          Basic Details
        </Text>
        {error && (
          <Box
            color={COLORS.ERROR_RED}
            display="flex"
            alignItems="center"
            m={1}
          >
            <Error />
            <Text
              ml={1}
              color={COLORS.ERROR_RED}
              fontWeight={600}
              fontSize={14}
            >
              {error}
            </Text>
          </Box>
        )}
        <TextFieldInput
          error={error}
          name="firstName"
          label="First Name"
          value={values.firstName}
          onChangeHandler={onChangeHandler}
          id="firstName"
        />
        <TextFieldInput
          error={error}
          name="lastName"
          label="Last Name"
          value={values.lastName}
          onChangeHandler={onChangeHandler}
          id="lastName"
        />
        <TextFieldInput
          error={error}
          name="email"
          label="Email"
          value={values.email}
          onChangeHandler={onChangeHandler}
          id="email"
          disabled={isEditable}
        />
        {!isEditable && (
          <>
            <PasswordFieldInput
              error={error}
              name="password"
              label="Password"
              value={values.password}
              showPassword={values.showPassword}
              onChangeHandler={onChangeHandler}
              showPasswordHandler={handleClickShowPassword}
            />
          </>
        )}
        <Text fontSize={16} fontWeight={700} m={2}>
          Access Information
        </Text>
        <Box ml={3}>
          <RadioGroup
            testId="radio-group-role"
            name="role"
            value={values.role}
            onChange={onChangeRadioHandler}
            options={[
              { label: "Read Only User", value: "readonly" },
              { label: "Creator", value: "creator" },
              { label: "Manager", value: "manager" },
              { label: "Admin", value: "admin" },
            ]}
          />
        </Box>
        {countryCampaignSet.map((item, index) => {
          return (
            <CountryCampaignFieldSet
              countryChangeHandler={countryChangeHandler}
              campaignChangeHandler={campaignChangeHandler}
              countryValue={values.selectedCountry[index]}
              campaignValues={values.permissions[values.selectedCountry[index]]}
              index={index}
            />
          );
        })}
        <Button
          variant="outlined"
          data-testid="add-country-btn"
          className={addCountryButton}
          onClick={onAddCountryHandler}
          style={{ marginBottom: 32 }}
        >
          <Add style={{ marginRight: 4 }} /> Add Country
        </Button>
        <BottomButtonTemplate
          onCancelClick={onCancelClick}
          isDisabled={isCreateUserButtonDisabled}
          onAddUserHandler={onAddUserHandler}
          title={isEditable ? "Save" : "Create new User"}
          testid="add-user-btn"
        />
      </Box>
    </Drawer>
  );
};

export default AddUserComponent;

import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Dialog, Text, Button, Box, DatePicker, Chip } from "common";
import { Timing } from "custom";
import { format } from "date-fns-tz";
import { useApiInfo, useTimeZoneSupport } from "hooks";
import { PROMOTION_EXTEND } from "store/store.types";
import { useDispatch } from "react-redux";
import { extendCampaign } from "store/actions/promotion.actions";

interface ICampaignExtendProps {
  open: boolean;
  onCancel: () => void;
  defaultTime: string;
  maxYearsAllowed?: number;
  object: string;
  id: string;
}

const tomorrow = new Date(
  new Date(new Date().setHours(0, 0, 0, 0)).getTime() + 24 * 60 * 60 * 1000
);

const CampaignExtend: React.FC<ICampaignExtendProps> = ({
  open,
  onCancel,
  defaultTime,
  maxYearsAllowed,
  object,
  id,
}) => {
  const { timeZone, timeZoneToDate } = useTimeZoneSupport();
  const { isLoading, done } = useApiInfo(PROMOTION_EXTEND);
  const [endDate, setEndDate] = useState<Date | null>(tomorrow as Date);
  const [endTime, setEndTime] = useState("");
  const [endTimeErr, setEndTimeErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const dispatch = useDispatch();
  const dialogTitle = (
    <Text color="common.white" fontSize="16px" fontWeight={600}>
      Campaign Extend
    </Text>
  );

  useEffect(() => {
    if (done) {
      onCancel();
    }
  }, [done, onCancel]);

  const onSumbitHandler = () => {
    const convertedEndDate = timeZoneToDate(validatedEndDate, {
      isIsoString: true,
    }) as Date;
    dispatch(
      extendCampaign({
        endDate: convertedEndDate,
        campaignId: id,
        object: object,
      })
    );
  };
  const actions = (
    <>
      <Button
        onClick={onCancel}
        fullWidth
        color="secondary"
        variant="outlined"
        round
        disabled={isLoading}
      >
        Cancel
      </Button>
      <Button
       data-testid='extend-btn'
        onClick={onSumbitHandler}
        fullWidth
        color="primary"
        variant="contained"
        round
        disabled={!!endTimeErr || !!endDateErr}
        isLoading={isLoading}
      >
        Extend
      </Button>
    </>
  );

  const onChangeEndTime = (timing: string) => {
    setEndTime(timing);
  };

  const onChangeEndDate = (date: Date | null) => {
    setEndDateErr("");
    if (date) {
      setEndDate(new Date(date.setHours(0, 0, 0, 0)));
    } else {
      setEndDateErr("Please enter correct date");
      setEndDate(null);
    }
  };

  // set redux store value or default timing
  const endDefaultTime = useMemo(() => {
    if (endDate && !defaultTime) {
      const [endHours, endMinutes, endSeconds] = [
        endDate.getHours(),
        endDate.getMinutes(),
        endDate.getSeconds(),
      ];
      return `${endHours < 10 ? `0${endHours}` : endHours}:${
        endMinutes < 10 ? `0${endMinutes}` : endMinutes
      }:${endSeconds < 10 ? `0${endSeconds}` : endSeconds}`;
    } else {
      return defaultTime;
    }
  }, [endDate, defaultTime]);

  const validateDate = useCallback(() => {
    let isValid = true;
    let endDateTime = 0;
    const maxYears = tomorrow.getFullYear() + (maxYearsAllowed || 10);
    setEndDateErr("");
    const today = new Date();
    const todayTime = today.getTime();
    if (!endDate || isNaN(endDate.getTime())) {
      isValid = false;
      setEndDateErr("Please enter correct date");
    }
    if (endDate) {
      endDateTime = endDate.getTime();
    }
    // end Date Errors validation
    if (endDate && endDate.getFullYear() > maxYears) {
      setEndDateErr(
        `End date year has to be earlier than or equal to ${maxYears}`
      );
      isValid = false;
    } else if (endDateTime && endDateTime <= todayTime) {
      setEndDateErr("End date cannot be earlier than or equal to today date");
      isValid = false;
    }
    return isValid;
  }, [endDate, maxYearsAllowed]);

  const validatedEndDate = useMemo(() => {
    let newEndDate = endDate;
    const endDateTime = endDate ? endDate.getTime() : 0;
    if (endDate) {
      if (validateDate()) {
        if (endTime) {
          const [endHours, endMinutes, endSeconds] = endTime.split(":");
          if (endDateTime) {
            newEndDate = new Date(
              new Date(endDate).setHours(+endHours, +endMinutes, +endSeconds)
            );
            setEndDateErr("");
            setEndTimeErr("");
          }
        } else {
          if (!endTime) {
            setEndTimeErr("Please enter the end time");
          }
          newEndDate = null;
        }
      } else {
        newEndDate = null;
      }
    }
    return newEndDate;
  }, [validateDate, endDate, endTime]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onCancel}
      borderRadius="12px"
      withColoredHeader={true}
      onClose={onCancel}
      color="primary"
      dialogTitle={dialogTitle}
      titlePadding="5px 15px"
      actions={actions}
    >
      <Box
        gridTemplateColumns="1fr"
        gridGap="15px"
        padding="20px"
        minWidth={["100%", "500px"]}
      >
        <DatePicker
          id="end-date-picker"
          label="End date"
          value={endDate}
          onChange={onChangeEndDate}
          error={!!endDateErr}
          errorMessage={endDateErr}
          variant="inline"
          inputVariant="filled"
          disableUnderLine={false}
          width="100%"
          size="medium"
          disablePast={true}
          testId="date-picker-end"
        />
        <Timing
          timingVariant="end"
          onChange={onChangeEndTime}
          errorMsg={endTimeErr}
          defaultValue={endDefaultTime}
          testId="timing"
          id="end-timing"
        />
        {validatedEndDate && !isNaN(validatedEndDate.getTime()) && (
          <Chip
            color="primary"
            borderRadius="10px"
            padding="3px"
            testId="chip-end-date-time"
            label={
              <Text fontSize="13px" fontWeight={600} color="common.white">
                {format(validatedEndDate, "MMMM d','yyyy 'at' HH:mm:ss XX", {
                  timeZone,
                })}
              </Text>
            }
          />
        )}
      </Box>
    </Dialog>
  );
};

export default CampaignExtend;

export const convertArrayOfKeysToObject = (keys?: string[], value?: any) => {
  let initialObject = {};
  if (keys) {
    for (let i = keys.length - 1; i >= 0; i--) {
      if (i === keys.length - 1) {
        initialObject = { [keys[i]]: value };
      } else {
        initialObject = { [keys[i]]: { ...initialObject } };
      }
    }
  }
  return initialObject;
};

export const regEx: { [key: string]: any } = {
  number: /^(\d+)$/,
  float: /^(\d+(\.\d+)?)$/,
  percentage: /^[1-9]\d?$|^100$/,
  text: /^[A-Za-z\s]*$/,
  textWithNumbers: /^[A-Za-z0-9\s]*$/,
  noSpecialCharacters: /^\w+(?:\s+\w+)*$/,
  noSpace: /^[\S]+$/,
  minOneSpecialChar: /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=_-]).*$/,
  email:
    /^[A-Za-z0-9]+?([A-Za-z0-9_\-.]+)[A-Za-z0-9-_]+[@]+[A-Za-z0-9]+?([A-Za-z0-9.]+)[.]([a-z]+)$/,
};

export const isEmpty = (value: any) => {
  return (
    typeof value === "undefined" ||
    value === "" ||
    value === null ||
    ({}.constructor === value.contrusctor && Object.keys(value).length === 0) ||
    (Array.isArray(value) && value.length === 0)
  );
};

export const isTime12 = (value: any) => {
  return /([0][1-9]|1[012]):[0-5][0-9]:[0-5][0-9]/.test(value);
  // /([0][1-9]|1[012]):[0-5]\d:[0-5]\d/.test(value);
};

export const isTime24 = (value: any) => {
  return /([0][0-9]|1[0-9]|2[0123]):[0-5][0-9]:[0-5][0-9]/.test(value);
  // /([0]\d|1 \d |2[0123]):[0-5]\d:[0-5]\d/.test(value);
};

export const isEmail = (email: any) => {
  return regEx.email.test(email);
};

export const downloadCSV = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/octet-stream" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode && link.parentNode.removeChild(link);
};

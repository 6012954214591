import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { IProps } from "./Menu.interface";

export const Wrapper = withStyles({
  root: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
})(Box);

export const StyledMenu = withStyles(({ shadows }: Theme) => ({
  root: {
    boxShadow: shadows[0],
    padding: "5px",
  },
}))(MenuList);

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    color: ({ active, theme }: IProps) => {
      if (active) {
       if(theme === "secondary"){
          return palette.secondary.main;
        }else{
          return palette.primary.main;
        }
      }
      return "#898989";
    },
    margin: "0px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "transparent",
      color: ({ theme }) => {
        if(theme === "secondary"){
          return palette.secondary.main;
        }else{
          return palette.primary.main;
        }
      },
    },
    "& span": {
      textTransform: "capitalize",
      fontSize: ({ fontSize = "14px" }: IProps) => fontSize,
      fontWeight: ({ fontWeight = 600 }: IProps) => fontWeight,
    },
  },
  listWrapper: {
    minWidth: "150px",
    zIndex: 10000,
  },
}));

export const useMenuItemStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    padding: "10px",
    fontSize: ({ fontSize = "14px" }: IProps) => fontSize,
    color: "#898989",
    fontWeight: ({ fontWeight = 600 }) => fontWeight,
    textTransform: "capitalize",
  },
  selected: {
    color: ({ theme }: IProps) => {
      if(theme === "secondary"){
        return palette.secondary.main;
      }else{
        return palette.primary.main;
      }
    },
  },
}));

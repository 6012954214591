import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLocation } from "store/actions/auth.actions";
import { IChipStyle } from "store/reducers/config.reducers";
import { ECampaignStatus } from "store/reducers/promotion.reducers";
import { IState } from "store/store.interface";
import { isEmpty } from "utils/app.utils";
import { useLocation } from "wouter";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const useApiInfo = (type: string) => {
  const api = useSelector((state: IState) => state.api);
  return useMemo(() => {
    const typeInfo = api[type];
    if (typeInfo) {
      const { isLoading, error, done, success } = typeInfo;
      return { isLoading, error, done, success };
    }
    return {};
  }, [type, api]);
};

export const useLocationUpdate = () => {
  const { location } = useSelector((state: IState) => state.auth);
  const dispatch = useDispatch();
  const [currentLocation, updateLocation] = useLocation();
  if (location && location !== currentLocation) {
    updateLocation(location);
    dispatch(setLocation(""));
  }
};

export const usePermissions = (status?: ECampaignStatus) => {
  const { userPermissions, statusPermissions, roles } = useSelector(
    (state: IState) => ({ ...state.config, ...state.auth })
  );
  const availableActions = useMemo(() => {
    if (!isEmpty(userPermissions) && !isEmpty(statusPermissions) && status) {
      const role = (roles || [])[0];
      const availableUserPermissions = userPermissions[role] || [];
      const availableStatusPermissions = statusPermissions[status] || [];
      const availableActions = availableUserPermissions.filter(
        (permission) =>
          availableStatusPermissions.indexOf(permission.action) > -1
      );
      return availableActions;
    }
    return [];
  }, [userPermissions, statusPermissions, status, roles]);
  return availableActions;
};

export const useBadgeInfo = (as: string) => {
  const { chipsStyles = {} } = useSelector((state: IState) => state.config);
  const {
    text = as || "",
    fontColor = "#fff",
    bgColor = "#000",
  } = useMemo(
    () => (chipsStyles[as || ""] || {}) as IChipStyle,
    [chipsStyles, as]
  );

  return { text, fontColor, bgColor };
};

export const useTimeZoneSupport = () => {
  const { timeZone } = useSelector((state: IState) => state.auth);
  const dateToTimeZone = (
    date: Date | null,
    options?: { isIsoString?: boolean; isUTCString?: boolean }
  ) => {
    const { isIsoString, isUTCString } = options || {};
    if (date && !isNaN(new Date(date).getTime())) {
      const convertedDate = utcToZonedTime(date, timeZone);
      if (!isIsoString && !isUTCString) {
        return convertedDate;
      }
      if (isIsoString) {
        return convertedDate.toISOString();
      }
      if (isUTCString) {
        return convertedDate.toUTCString();
      }
    }
    return date;
  };
  const timeZoneToDate = (
    date: Date | null,
    options?: { isIsoString?: boolean; isUTCString?: boolean }
  ) => {
    const { isIsoString, isUTCString } = options || {};
    if (date && !isNaN(new Date(date).getTime())) {
      const convertedDate = zonedTimeToUtc(date, timeZone);
      if (!isIsoString && !isUTCString) {
        return convertedDate;
      }
      if (isIsoString) {
        return convertedDate.toISOString();
      }
      if (isUTCString) {
        return convertedDate.toUTCString();
      }
    }
    return date;
  };
  return {
    dateToTimeZone,
    timeZoneToDate,
    timeZone,
  };
};

import React, { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { Box } from "common";
import MuiButton from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { ISplitButtonProps } from "./SplitButton.interface";

const SplitButton: React.FC<ISplitButtonProps> = ({
  isLoading,
  options,
  testId,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const selectedOption = useMemo(() => {
    const value = options.filter((option, index) => index === selectedIndex)[0];
    if(value){
      if(value.title !==""){
        return options.filter((option, index) => index === selectedIndex)[0];
      }else{
        return options.filter((option, index) => index === 1)[0];  
      }
    }else{
      return options.filter((option, index) => index === 1)[0];
    }
  }, [options, selectedIndex]);

  const filteredOptions = useMemo(() => {
      return options.filter((option) => option.title !== '' && selectedOption?.title);
  }, [options, selectedOption]);

  const handleClick = () => {
    selectedOption.onClick();
  };

  const handleMenuItemClick = (title: string, index: number) => () => {
    setSelectedIndex(index);
    setOpen(false);
    const onClick = options.filter((option) => option.title === title)[0]
      .onClick;
    if (onClick) {
      onClick();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center" data-testid={testId}>
      <Box>
        <ButtonGroup
          variant="outlined"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          data-testid={`${testId}-group-button`}
          size="small"
        >
          {selectedOption && (
            <MuiButton
              data-testid={`${testId}-default-button`}
              onClick={handleClick}
              variant="outlined"
              disabled={isLoading}
              size="small"
            >
              {selectedOption?.title}
            </MuiButton>
          )}
          {filteredOptions.length > 0 && (
            <MuiButton
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              variant="outlined"
              data-testid={`${testId}-arrow`}
            >
              <ArrowDropDownIcon />
            </MuiButton>
          )}
        </ButtonGroup>
        {filteredOptions.length > 0 && (
          <Popper
            open={!isLoading && open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      id="split-button-menu"
                      data-testid={`${testId}-list`}
                    >
                      {filteredOptions.map(({ title }, index) => (
                        <MenuItem
                          key={title} 
                          disabled={title.length === 0}
                          // disabled={index === 2}
                          selected={index === selectedIndex}
                          onClick={handleMenuItemClick(title, index) as any}
                          data-testid={`${testId}-menu-item-${index}`}
                        >
                          {title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </Box>
    </Grid>
  );
};

export default SplitButton;

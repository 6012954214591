import React, { FC, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearCreationForm,
  clearForm,
  setPromotionType,
} from "store/actions/promotion.actions";
import { EPromotionTypes, IState } from "store/store.interface";
import { setLocation } from "store/actions/auth.actions";
import { Box, Button, Card, Select, Text } from "common";

interface SelectCampaignProps {
  onFinish: () => void;
}
const SelectCampaign: FC<SelectCampaignProps> = ({ onFinish }) => {
  const {
    selectOptions,
    creationForm,
    storeId,
    permissions = {},
  } = useSelector((state: IState) => ({
    ...state.config,
    ...state.promotion,
    ...state.auth,
  }));
  const { type = "" } = useMemo(() => creationForm || {}, [creationForm]);
  const dispatch = useDispatch();
  const [campaignType, setCampaignType] = useState(type);
  const onChangeCampaignType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignType(event.target.value);
  };

  const onNextHandler = useCallback(() => {
    dispatch(clearCreationForm());
    dispatch(clearForm());
    dispatch(setPromotionType(campaignType as EPromotionTypes));
    dispatch(setLocation("/promotion-create"));
    onFinish();
  }, [campaignType, dispatch, onFinish]);

  // get options based on country if storeid exist
  const options = useMemo(() => {
    const storePermission = permissions[storeId || ""];
    let selectedCampagins = selectOptions.promotionTypes;
    if (storePermission) {
      const allowedCampaigns = (storePermission.find((permission) => permission.type === "campaign") || {}).value?.split(",");
      if(allowedCampaigns){
        if(allowedCampaigns.find(permission => permission === EPromotionTypes.DISCOUNT_COUPON)){
          allowedCampaigns.push(EPromotionTypes.BULK_COUPON);
        }
      }
      if (allowedCampaigns && allowedCampaigns.length > 0) {
        selectedCampagins = selectedCampagins.filter((campaign) => {
          const campaignName =
            campaign.value === EPromotionTypes.COUPON ? EPromotionTypes.DISCOUNT_COUPON : campaign.value;
          return !!allowedCampaigns.find(
            (campaign) => campaign.trim() === campaignName
          );
        });
      }
    }
    return selectedCampagins || [];
  }, [selectOptions, storeId, permissions]);
  return (
    <Card padding="30px" minWidth={["100%", "450px", "450px", "450px"]}>
      <Text
        fontSize="14px"
        fontWeight={500}
        marginBottom="20px"
        textAlign="center"
      >
        SELECT CAMPAIGN TYPE
      </Text>
      <Box display="flex" width="100%">
        <Select
          options={options}
          label="Please Select Campaign Type"
          variant="native"
          onNativeChange={onChangeCampaignType}
          nativeValue={campaignType}
          width="100%"
          size="medium"
          disableUnderline={false}
          fontSize="14px"
        />
      </Box>
      <Button
        data-testid="country-selection-button"
        color="primary"
        round
        variant="contained"
        margin="25px 0"
        width="100%"
        type="button"
        onClick={onNextHandler}
        disabled={!campaignType}
      >
        Continue
      </Button>
    </Card>
  );
};

export default SelectCampaign;

import React from "react";
import {
  compose,
  spacing,
  typography,
  sizing,
  display,
  SpacingProps,
  DisplayProps,
  palette,
  PaletteProps,
  TypographyProps,
  SizingProps,
} from "@material-ui/system";
import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const DumpText = styled(Typography)(
  compose(spacing, typography, sizing, palette, display)
);

export interface ITextProps
  extends SpacingProps,
    TypographyProps,
    SizingProps,
    DisplayProps,
    PaletteProps {
  noWrap?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
export const Text: React.FC<ITextProps> = ({ children, noWrap, ...rest }) => {
  return (
    <DumpText gutterBottom={false} noWrap={noWrap} {...rest}>
      {children}
    </DumpText>
  );
};

Text.defaultProps = {
  color: "text.primary",
  fontSize: "13px",
  fontWeight: 500,
  noWrap: false,
};

export default Text;

import { Theme, makeStyles } from "@material-ui/core/styles";
import { EInputVariant, ETheme } from "../common.interface";
import { variant } from "./Select.interface";

interface IStyle {
  width?: string;
  height?: string;
  inputVariant?: EInputVariant;
  color?: ETheme;
  variant?: variant;
  listWidth?: string;
  searchIconSpacing?: string;
}

export const useListStyles = makeStyles({
  popper: {
    width: ({ listWidth }: IStyle) => `${listWidth} !important`,
  },
});
export const useVirtualizedStyles = makeStyles((theme: Theme) => ({
  root: {
    width: ({ width = "auto" }: IStyle) => width,
    height: ({ height = "auto" }: IStyle) => height,
  },
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  popupIndicatorOpen: {
    transform: ({ variant }: IStyle) =>
      variant === "default" ? "rotate(180deg)" : "none",
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: ({ color }) =>
        color === "secondary" ? "#EAF8F5" : "#e6f2ff",
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginLeft: ({ searchIconSpacing = "0px" }) => searchIconSpacing,
  },
  inputAdornment: {
    marginTop: "0px !important",
  },
}));

export const useNativeSelectStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    width: ({ width = "auto" }: IStyle) => width,
    backgroundColor: palette.background.default,
    "&:focus": {
      backgroundColor: palette.background.default,
    },
  },
}));

export const useNativeSelectRootStyles = makeStyles((theme: Theme) => ({
  paper: {
    boxShadow: "0px 0px 16px 0px rgb(0 0 0 / 14%)",
    "& ul": {
      "& li": {
        "&:hover": {
          backgroundColor: ({ color }: IStyle) =>
            color === "secondary" ? "#EAF8F5" : "#e6f2ff",
        },
      },
    },
  },
}));

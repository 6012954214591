import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "25px 0",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  contentContainerSummary: {
    display: "grid",
    gridGap: "10px",
    margin: "25px 0",
    minHeight: "400px",
    width: "100%",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  buttonWrapper: {
    position: "relative",
    margin: "15px 0",
  },
  container: {
    display: "flex",
    padding: "50px 100px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
    marginTop: "15px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 20px",
    },
  },
}));

import React, { FC, useMemo } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { useStyles, useMenuItemStyles, StyledMenu } from "./Menu.style";
import { IProps } from "./Menu.interface";
import { useDispatch } from "react-redux";
import { setLocation } from "store/actions/auth.actions";
import { useLocation } from "wouter";

const Menu: FC<IProps> = ({
  options,
  theme,
  menuLabel,
  fontSize,
  fontWeight,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const [location] = useLocation();

  // get list of paths from options to check the active links
  const paths = useMemo(() => {
    if (!options || options.length === 0) {
      return [];
    }
    return options.map((option) => option.to);
  }, [options]);

  const stylesProps = useMemo(
    () => ({
      theme,
      fontSize,
      fontWeight,
    }),
    [theme, fontSize, fontWeight]
  ) as IProps;

  const { root, listWrapper } = useStyles({
    ...stylesProps,
    active: paths.indexOf(location) > -1,
  } as IProps);
  const classes = useMenuItemStyles({ theme } as IProps);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick =
    (to: string) => (event: React.MouseEvent<EventTarget>) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      setOpen(false);
      dispatch(setLocation(to));
    };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        classes={{ root }}
      >
        {menuLabel}
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={listWrapper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenu
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {options &&
                    options.length > 0 &&
                    options.map(({ label, to, onClick }, index) => (
                      <MenuItem
                        selected={location === to}
                        key={`${label}-${index}`}
                        classes={classes}
                        onClick={onClick || handleMenuItemClick(to)}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  {children}
                </StyledMenu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Menu;

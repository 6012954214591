import {
  DELETE_USER_BY_ID,
  GET_USER_BY_ID,
  SET_USERS,
  UPDATE_USER_BY_ID,
} from "store/store.types";
import { EPromotionTypes, IAction } from "../store.interface";
import { ERoles, EStores } from "./auth.reducers";

export const USERS_INITIAL_STATE = {
  users: [],
  page: 0,
  params: "",
};

export interface IUsers {
  users: IUserData[];
  page: number;
  params: string;
  userToEdit?:IUserData
}
export interface IUserData {
  id: string;
  email: string;
  name: string;
  roles: ERoles[];
  storeIds: EStores[];
  permissions: {
    [storeId: string]: EPromotionTypes[];
  };
}
const userReducers = (state: IUsers = USERS_INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload.data,
        page: action.payload.page,
        params: action.payload.params,
      };
    case DELETE_USER_BY_ID:
      return {
        ...state,
        users: state.users.filter((item) => item.id !== action.payload),
      };
    case UPDATE_USER_BY_ID:
      const index = state.users.findIndex((x) => x.id === action.payload.id);
      const newUsers = state.users;
      newUsers[index] = action.payload.data;
      return {
        ...state,
        users: newUsers,
      };
      case GET_USER_BY_ID:
        return {
          ...state,
          userToEdit:state.users.find(user=> user.id === action.payload)
        }
    default:
      return state;
  }
};

export default userReducers;

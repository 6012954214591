import {
  API_CONFIG,
  API_HEADERS_COMMON_CONFIG,
  API_HEADERS_COMMON_CONFIG_STATIC,
} from "./api.config";
import { EMethods, IPayload, IApiMiddlewareAction } from "../store.interface";
import { API } from "../store.types";
import axios from "axios";
import { isEmpty } from "utils/app.utils";

export type TNetwork = {
  forFileSend?: boolean;
  url: string;
  method: EMethods;
  data: object | FormData;
  headers?: object;
  isAbsoluteUrl?: boolean;
  token?: string;
  storeId?: string;
  requestConfig?: any;
  isStatic?: boolean;
};

export type TMultiNetwork = {
  requests: TNetwork[];
};

export const PostFileRequest = async <T = any>({
  url,
  method,
  data: bodyOrParams,
  headers = {},
  isAbsoluteUrl,
  token,
  storeId,
  isStatic,
  requestConfig = {},
}: TNetwork): Promise<{data: T}> => {
  const { baseURL } = API_CONFIG;
  const COMMON_HEADERS = isStatic
    ? API_HEADERS_COMMON_CONFIG_STATIC
    : API_HEADERS_COMMON_CONFIG;
  // Sets request "Headers" and baseURL common attributes
  let combinedHeaders = { ...COMMON_HEADERS, ...headers } as any;
  if (token) {
    combinedHeaders = { ...combinedHeaders, Authorization: token };
  }
  if (storeId) {
    combinedHeaders = { ...combinedHeaders, storeId };
  }
  axios.defaults.headers.common = combinedHeaders;
  axios.defaults.baseURL = isAbsoluteUrl ? "" : baseURL;
  try {
    const {data} = await axios.post(url, bodyOrParams, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return Promise.resolve({ data });
  } catch (exception) {
    return Promise.reject(exception);
  }
}
export const NetworkRequest = async <T = any>({
  url,
  method,
  data: bodyOrParams,
  headers = {},
  isAbsoluteUrl,
  token,
  storeId,
  isStatic,
  requestConfig = {},
}: TNetwork): Promise<{ data: T }> => {
  const { baseURL } = API_CONFIG;
  const COMMON_HEADERS = isStatic
    ? API_HEADERS_COMMON_CONFIG_STATIC
    : API_HEADERS_COMMON_CONFIG;
  // Sets request "Headers" and baseURL common attributes
  let combinedHeaders = { ...COMMON_HEADERS, ...headers } as any;
  if (token) {
    combinedHeaders = { ...combinedHeaders, Authorization: token };
  }
  if (storeId) {
    combinedHeaders = { ...combinedHeaders, storeId };
  }
  axios.defaults.headers.common = combinedHeaders;
  axios.defaults.baseURL = isAbsoluteUrl ? "" : baseURL;

  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  try {
    const { data } = await axios.request({
      url,
      method,
      [dataOrParams]: { ...bodyOrParams },
      ...requestConfig,
    });
    return Promise.resolve({ data });
  } catch (exception) {
    return Promise.reject(exception);
  }
};

/**
 * data required for returning correct action object single request
 * @param {object} headers
 * @param {string} label
 * @param {EMethods} method
 * @param {boolean} isAbsoluteUrl
 * @param {function} onSuccess
 * @param {function} onFailure
 * @param {string} url
 * @param {object} data
 */
export const apiAction = ({
  forFileSend = false,
  headers,
  label,
  method,
  isAbsoluteUrl,
  onSuccess,
  onFailure,
  onFinally,
  url,
  data,
  withStoreId,
  withToken,
  requestConfig,
  isStatic,
}: IPayload): IApiMiddlewareAction => {
  return {
    type: API,
    payload: {
      forFileSend,
      headers,
      label,
      method,
      onSuccess,
      onFailure,
      onFinally,
      isAbsoluteUrl,
      url,
      data,
      withStoreId,
      withToken,
      requestConfig,
      isStatic,
    },
  };
};

export const replaceUrlVariables = (
  url: string,
  params: { [key: string]: any }
): string => {
  const regex = /[^{{}]+(?=}})/g;
  const matches = url.match(regex);
  let modifiedURL = url;
  if (matches) {
    matches.forEach((item: string) => {
      const value = params[item];
      modifiedURL = modifiedURL.replace(
        new RegExp("{{" + item + "}}"),
        !isEmpty(value) || value === "" ? value : `{{${item}}}`
      );
    });
  }

  return modifiedURL;
};

import React, { FC, useEffect } from "react";
import Loader from "Loader/Main";
import { useDispatch } from "react-redux";
import { setLocation, signout } from "store/actions/auth.actions";

const Logout: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signout());
    dispatch(setLocation("/login"));
  }, [dispatch]);
  return <Loader />;
};

export default Logout;

import React from "react";
import { ITransaction } from "store/reducers/promotion.reducers";
import * as S from "./TransactionHistory.style";
import { Box } from "common";

const TransactionHistory: React.FC<{
  transactionHistory?: ITransaction[];
}> = ({ transactionHistory = [] }) => {
  if (transactionHistory.length === 0) {
    return null;
  }

  return (
    <S.TransactionHistory>
      <S.TransactionHistoryHeading>
        Transaction history
      </S.TransactionHistoryHeading>

      <S.Transactions>
        <S.ColumnHeading>Transaction Id</S.ColumnHeading>
        <S.ColumnHeading>Actor Email</S.ColumnHeading>
        <S.ColumnHeading>Actor Name</S.ColumnHeading>
        <S.ColumnHeading>Previous Status</S.ColumnHeading>
        <S.ColumnHeading>Current Status</S.ColumnHeading>
        <S.ColumnHeading>Date Time</S.ColumnHeading>

        {transactionHistory.map((transaction) => {
          return (
            <>
              <Box>{transaction.id}</Box>
              <Box>{transaction.actorEmail}</Box>
              <Box>{transaction.actorName}</Box>
              <Box>{transaction.previousStatus}</Box>
              <Box>{transaction.currentStatus}</Box>
              <Box>{transaction.dateTime}</Box>
            </>
          );
        })}
      </S.Transactions>
    </S.TransactionHistory>
  );
};

export default TransactionHistory;

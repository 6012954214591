import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  dropDownWrapper: {
    "& svg": {
      width: "30px",
      height: "30px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  boxWrapper: {
    width: "100%",
    padding: "50px 30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      padding: "50px 80px",
    },
  },
  wrapper: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    maxWidth: "550px",
    margin: "auto",
    borderRadius: "4px",
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  footerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    padding: "15px 10px",
    backgroundColor: "#31261D",
  },
  carrefourWrapperLogo: {
    marginBottom: "15px",
    "& svg": {
      width: "128px",
    },
    "& path:nth-of-type(2)": {
      fill: '#ee2527'
    }
  },
}));

import React, { useState } from "react";
import { Box, Button, Card } from "common";
import { useStyles } from "./MyProfile.style";
import ChangePassword from "./ChangePassword/ChangePassword";
import COLORS from "common/Theme/colors";
import { AccountDetails } from "./AccountDetails";

const ProfileDetails = ({
  name,
  email,
  role,
}: {
  name: string;
  email: string;
  role: string;
}) => {
  const { profileDetailContainer, styledButton } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <Card
      padding={4}
      borderRadius="8px"
      border={`1px solid ${COLORS.LIGHT_GRAY}`}
      marginY="20px"
    >
      <Box className={profileDetailContainer}>
        <AccountDetails heading="Name" subheading={name} />
        <AccountDetails heading="Email Address" subheading={email} />
        <AccountDetails heading="Access type" subheading={role} />
        <Box>
          <Button
            className={styledButton}
            variant="outlined"
            onClick={handleOpenCloseModal}
          >
            Change Password
          </Button>
        </Box>
      </Box>
      <ChangePassword isOpen={isModalOpen} handleClose={handleOpenCloseModal} />
    </Card>
  );
};

export default ProfileDetails;

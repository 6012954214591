export const isProd =
  window.location.hostname === "promolab-dexter.mafrservices.com";

export const API_CONFIG = {
  baseURL: isProd 

    // https://promotion-engine-admin-backend.azurewebsites.net
    ? "https://agent-zero-backend.mafrservices.com"

    : "https://agent-zero-backend-staging.mafrservicesstg.com/",  /*  live * */ 
    // : "https://agent-zero-backend-stg.azurewebsites.net",  /*  Local * */
};

/**
 * default headers if no headers has been passed
 */
export const API_HEADERS_COMMON_CONFIG = {
  "x-api-key": isProd ? process.env.REACT_APP_SECRET_API_KEY : "testing",
};

/**
 * default headers if no headers has been passed for static api
 */
export const API_HEADERS_COMMON_CONFIG_STATIC = {
  "x-api-key": isProd ? process.env.REACT_APP_SECRET_STATIC_API_KEY : "testing",
};

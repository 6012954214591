import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    width: "50px",
    height: "100%",
    textAlign: "center",
    paddingTop: "8px",
    cursor: "pointer",
    transform: "translateX(10px)",
  },
  searchInput: {
    maxWidth: "300px",
  },
}));

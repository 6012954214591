import React, { ComponentType, FC, ReactNode } from "react";
import { Route, RouteComponentProps, Redirect } from "wouter";
import { useSelector } from "react-redux";
import { IState } from "store/store.interface";
import { isEmpty } from "utils/app.utils";
import { useApiInfo } from "hooks";
import {
  AUTH_GET_USER,
  AUTH_VERIFY_SESSION,
  CONFIG_GET,
} from "store/store.types";
import Loader from "Loader/Main";

interface IProps {
  children?: ReactNode;
  component?: ComponentType<RouteComponentProps>;
  path: string;
}
const ProtectedRoute: FC<IProps> = ({ component, ...rest }) => {
  const { isAuthenticated, storeId, userPermissions, statusPermissions } =
    useSelector((state: IState) => ({ ...state.auth, ...state.config }));
  const { isLoading: isVerifyLoading = true } = useApiInfo(AUTH_VERIFY_SESSION);
  const { isLoading: isGetUserLoading } = useApiInfo(AUTH_GET_USER);
  const { isLoading: isGetConfigLoading } = useApiInfo(CONFIG_GET);

  if (isVerifyLoading || isGetConfigLoading || isGetUserLoading) {
    return <Loader />;
  }
  if (
    isAuthenticated &&
    storeId &&
    !isEmpty(userPermissions) &&
    !isEmpty(statusPermissions)
  ) {
    return <Route component={component} {...rest} />;
  }
  return <Redirect to="/logout" />;
};

export default ProtectedRoute;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaskedInput from "react-text-mask";
import { isTime12, isTime24 } from "utils/app.utils";
import { Box, Input, Select } from "common";
import { EInputVariant } from "common/common.interface";

export interface ITimingProps {
  disable?: boolean;
  timingVariant: "start" | "end";
  onChange: (value: string) => void;
  errorMsg?: string;
  defaultValue?: string;
  variant?: EInputVariant;
  testId?: string;
  id?: string;
}

const options = [
  { id: "1", label: "AM", value: "AM" },
  { id: "2", label: "PM", value: "PM" },
];

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom: React.FC<TextMaskCustomProps> = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, /[\d]/, ":", /[0-5]/, /[\d]/, ":", /[0-5]/, /[\d]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

const Timing: React.FC<ITimingProps> = ({
  disable,
  timingVariant,
  onChange,
  errorMsg,
  defaultValue = "",
  variant = "filled",
  testId,
  id,
}) => {
  const [timing, setTiming] = useState<string>("");
  const [timeRange, setTimeRange] = useState<string>("AM");
  const [error, setError] = useState(false);

  const { label } = useMemo(() => {
    if (timingVariant === "start") {
      return { label: "Start Time (Hours:Minutes:Seconds)" };
    }
    return { label: "End Time (Hours:Minutes:Seconds)" };
  }, [timingVariant]);

  const onChangeHandler = useCallback(
    (time?: string, range?: string, isSet?: boolean) => {
      if (time && isSet) {
        onChange(time);
      } else if (time && isTime24(time) && range) {
        if (range === "PM") {
          if (isTime12(time)) {
            const [hours, minutes, seconds] = time.split(":");
            const formatedHours = +hours < 12 ? +hours + 12 : +hours;
            onChange(`${formatedHours}:${minutes}:${seconds}`);
            setError(false);
          } else {
            setError(true);
          }
        } else {
          if (isTime12(time)) {
            const [hours, minutes, seconds] = time.split(":");
            const formatedHours = +hours >= 12 ? +hours - 12 : +hours;
            onChange(`${formatedHours}:${minutes}:${seconds}`);
            setError(false);
          } else {
            setError(true);
          }
        }
        setError(false);
      } else {
        onChange("");
        setError(true);
      }
    },
    [onChange]
  );

  const onChangeTiming = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTiming(event.target.value);
      onChangeHandler(event.target.value, timeRange, false);
    },
    [onChangeHandler, timeRange]
  );

  const onChangeTimeRange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTimeRange(event.target.value);
      onChangeHandler(timing, event.target.value, false);
    },
    [timing, onChangeHandler]
  );

  useEffect(() => {
    if (defaultValue) {
      if (isTime24(defaultValue)) {
        const [hours, minutes, seconds] = defaultValue.split(":");
        if (+hours > 12) {
          const formatedHours = +hours - 12;
          setTiming(
            `${
              formatedHours > 9 ? formatedHours : `0${formatedHours}`
            }:${minutes}:${seconds}`
          );
        } else {
          if (+hours === 0) {
            const formatedHours = +hours + 12;
            setTiming(
              `${
                formatedHours > 9 ? formatedHours : `0${formatedHours}`
              }:${minutes}:${seconds}`
            );
          } else {
            setTiming(defaultValue);
          }
        }
        // section to set time range
        if (+hours >= 12) {
          setTimeRange("PM");
        } else {
          setTimeRange("AM");
        }
        onChangeHandler(defaultValue, "", true);
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Box
        gridTemplateColumns={["1fr", "9fr 3fr", "9fr 3fr", "9fr 3fr"]}
        gridGap="10px"
        data-testid={testId}
      >
        <Input
          disabled={disable}
          name={`${timingVariant === "start" ? "start-time" : "end-time"}`}
          label={label}
          fontSize="16px"
          variant={variant}
          data-testid={`text-field-${
            timingVariant === "start" ? "start-time" : "end-time"
          }`}
          onChange={onChangeTiming}
          value={timing}
          inputComponent={TextMaskCustom as any}
          error={error || !!errorMsg}
          errorMessage={errorMsg || "Please Enter correct timing"}
          size="medium"
          id={`${id}-input`}
        />
        <Select
          disable={disable}
          options={options}
          nativeValue={timeRange}
          onNativeChange={onChangeTimeRange}
          variant="native"
          disableUnderline={false}
          size="medium"
          width="100%"
          testId={`select-${testId}-${timingVariant}`}
          id={`${id}-select`}
        />
      </Box>
    </>
  );
};

export default Timing;

import React, { FC, useMemo, useState, memo, useCallback } from "react";
import { useStyles } from "./SearchableList.style";
import {
  areEqual,
  ListChildComponentProps,
  FixedSizeList as List,
} from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import LinearProgress from "@material-ui/core/LinearProgress";
import memoize from "memoize-one";
import { MagnifyGlass } from "@mafc/icon";
import { Box, Input } from "common";

export interface IEntry {
  label: string;
  value: string;
}
interface IProps {
  disable?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectItem?: (entry: IEntry) => void;
  selectedItem?: string;
  entries: IEntry[];
  label?: string;
  testId?: string;
  isLoading?: boolean;
  error?: string;
}

export const Row = memo(({ data, index, style }: ListChildComponentProps) => {
  // Data passed to List as "itemData" is available as props.data
  const { items, onSelect } = data;
  const item = items[index];

  const onSelectHandler = () => {
    onSelect(item);
  };

  return (
    <li onClick={onSelectHandler} data-testid="list-selector" style={style}>
      {item.label}
    </li>
  );
}, areEqual);

const createItemData = memoize((items, onSelect) => ({
  items,
  onSelect,
}));

const SearchableInput: FC<IProps> = ({
  disable,
  label,
  entries,
  onSelectItem,
  selectedItem,
  testId,
  onChange,
  isLoading,
  error,
}) => {
  const classes = useStyles();
  const [selected, setSeleted] = useState(selectedItem);
  const [touched, setTouched] = useState(false);
  const [input, setInput] = useState("");
  const [focused, setFocused] = useState(false);

  const onFocusHandler = () => {
    setFocused(true);
  };

  const onBlurHandler = () => {
    setFocused(false);
  };

  const onSelect = (item: IEntry) => {
    setSeleted(item.label);
    if (onSelectItem) {
      onSelectItem(item);
    }
  };

  const list = useMemo(() => {
    if (!input) return [];
    return entries.filter((item) => item && item.label);
  }, [input, entries]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true);
    }
    setSeleted("");
    if (onSelectItem) {
      onSelectItem({ value: "", label: "" });
    }
    setInput(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const itemData = createItemData(list, onSelect);

  const renderComponent = useCallback(() => {
    if (touched && !selected) {
      if (isLoading) {
        return (
          <Box className={classes.noListWrapper}>
            <li>loading ...</li>
            <Box className={classes.loaderWrapper}>
              <LinearProgress
                color="primary"
                data-testid="searchable-list-loading"
              />
            </Box>
          </Box>
        );
      } else if (list.length > 0) {
        return (
          <AutoSizer>
            {({ height, width }: Size) => {
              return (
                <List
                  height={height + 200}
                  itemCount={list.length}
                  itemData={itemData}
                  itemSize={42}
                  width={width}
                  data-testid="searchable-list-view"
                  className={classes.list}
                >
                  {Row}
                </List>
              );
            }}
          </AutoSizer>
        );
      } else if (focused) {
        return (
          <Box className={classes.noListWrapper}>
            <li>No items found ...</li>
          </Box>
        );
      }
    }
  }, [isLoading, selected, list, focused, itemData, touched, classes]);

  return (
    <div className={classes.wrapper} data-testid={testId}>
      <Input
        disabled={disable}
        data-testid="searchable-input"
        label={label}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        value={selected ? selected : input}
        size="medium"
        fullWidth
        width="100%"
        endAdornment={<MagnifyGlass size="M" />}
        error={!!error}
        errorMessage={error}
      />
      {renderComponent()}
    </div>
  );
};

export default SearchableInput;

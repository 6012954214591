import React from "react";
import { Box, Card } from "common";
import Skeleton from "@material-ui/lab/Skeleton";

const StepperLoader: React.FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      mb="20px"
    >
      <Box mr="10px">
        <Skeleton
          variant="circle"
          width="20px"
          height="20px"
          animation="pulse"
        />
      </Box>
      <Skeleton variant="text" width="150px" animation="pulse" />
      <Box mx="10px">
        <Skeleton
          variant="circle"
          width="20px"
          height="20px"
          animation="pulse"
        />
      </Box>
      <Skeleton variant="text" width="150px" animation="pulse" />
      <Box ml="10px">
        <Skeleton
          variant="circle"
          width="20px"
          height="20px"
          animation="pulse"
        />
      </Box>
    </Box>
  );
};

const FormLoader: React.FC<{ withStepper?: boolean }> = ({ withStepper }) => {
  return (
    <Card
      width="100%"
      padding="25px"
      data-testid="form-loader"
      justifyContent="center"
      alignItems="center"
      margin="25px 0px"
    >
      {withStepper && <StepperLoader />}
      <Box
        gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
        gridGap="20px"
      >
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
        <Skeleton height={50} width="100%" animation="pulse" variant="rect" />
      </Box>
    </Card>
  );
};

export default FormLoader;

import { Dispatch } from "redux";
import {
  CONFIG_GET,
  CONFIG_GET_BRANDS,
  CONFIG_GET_CATEGORIES,
  CONFIG_SET,
  CONFIG_SET_BRANDS,
  CONFIG_SET_CATEGORIES,
  CONFIG_SET_SINGLE_BRAND,
  CONFIG_RESET_BRAND_AND_CATEGORY,
  CONFIG_SET_SINGLE_CATEGORY,
} from "../store.types";
import { apiAction } from "store/middlewares/api.middleware.helper";
import {
  absoluteUrls,
  API_KEY_AZURE_SEARCH,
  staticApiEndpoints,
} from "constant";
import { EMethods } from "store/store.interface";
import { isEmpty } from "utils/app.utils";
import { TBrands, TCategories } from "store/reducers/config.reducers";
import { IEntry } from "components/SearchableInput";

export const getAppConfig = () => (dispatch: Dispatch) => {
  dispatch(
    apiAction({
      url: staticApiEndpoints.CONFIG,
      label: CONFIG_GET,
      method: EMethods.GET,
      isStatic: true,
      data: {},
      onSuccess: ({ data }) => (dispatch: Dispatch) => {
        let dataToInject = {};
        if (!isEmpty(data) && Array.isArray(data)) {
          dataToInject = data[0].config;
        }
        dispatch({
          type: CONFIG_SET,
          payload: dataToInject,
        });
      },
    })
  );
};

export const getCategoriesByKeyword = (search: string) =>
  apiAction({
    url: absoluteUrls.categories,
    method: EMethods.GET,
    isAbsoluteUrl: true,
    label: CONFIG_GET_CATEGORIES,
    headers: {
      "api-key": API_KEY_AZURE_SEARCH,
    },
    data: {
      "api-version": "2020-06-30-Preview",
      $top: 40,
      queryType: "full",
      search,
    },
    onSuccess: ({ value }) => setCategoriesEntries(value),
  });

export const getBrandsByKeyword = (search: string) =>
  apiAction({
    url: absoluteUrls.brands,
    method: EMethods.GET,
    isAbsoluteUrl: true,
    label: CONFIG_GET_BRANDS,
    headers: {
      "api-key": API_KEY_AZURE_SEARCH,
    },
    data: {
      "api-version": "2020-06-30-Preview",
      search,
    },
    onSuccess: ({ value }) => setBrandsEntries(value),
  });

export const setCategoriesEntries = (categories: TCategories[]) => (
  dispatch: Dispatch
) => {
  const categoriesEntries = categories
    .map((category) => {
      const {
        id,
        levelFourName,
        levelOneName,
        levelThreeName,
        levelTwoName,
        levelFourcode,
        levelOneCode,
        levelThreeCode,
        levelTwoCode,
      } = category;
      const label =
        levelFourName || levelThreeName || levelTwoName || levelOneName;
      const valueKey = Object.keys(category).filter(
        (key) => category[key as keyof TCategories] === label
      )[0] as keyof TCategories;
      let value;
      switch (valueKey) {
        case "levelFourName":
          value = levelFourcode;
          break;
        case "levelThreeName":
          value = levelThreeCode;
          break;
        case "levelTwoName":
          value = levelTwoCode;
          break;
        case "levelOneName":
          value = levelOneCode;
          break;
        default:
          break;
      }
      if (label && value) {
        return {
          id,
          label,
          value,
        };
      }
      return null;
    })
    .filter((category) => category && category.id);
  dispatch({ type: CONFIG_SET_CATEGORIES, payload: categoriesEntries });
};

export const setBrandsEntries = (brands: TBrands[]) => (dispatch: Dispatch) => {
  const brandsEntries = brands
    .map(({ id, name, code }) => {
      if (name && code) {
        return {
          id,
          label: name,
          value: code,
        };
      }
      return null;
    })
    .filter((brand) => brand && brand.id);
  dispatch({
    type: CONFIG_SET_BRANDS,
    payload: brandsEntries,
  });
};

export const setBrandHelper = (brand: IEntry) => ({
  type: CONFIG_SET_SINGLE_BRAND,
  payload: brand,
});

export const setCategoryHelper = (category: IEntry) => ({
  type: CONFIG_SET_SINGLE_CATEGORY,
  payload: category,
});

export const clearBrandAndCategoriesHelpers = () => ({
  type: CONFIG_RESET_BRAND_AND_CATEGORY,
});

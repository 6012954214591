import React, { FC, useMemo } from "react";
import { useStyles } from "./Step.style";
import DoneIcon from "@material-ui/icons/Done";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export enum Evariant {
  active = "active",
  inActive = "inActive",
  done = "done",
}
interface IProps {
  stepNumber: number;
  isActive: boolean;
  isDone: boolean;
  title: string;
}
const Step: FC<IProps> = ({ stepNumber, isActive, isDone, title }) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { wrapperClass, numberClass } = useMemo(() => {
    const {
      stepNumberActive,
      stepNumberDone,
      stepNumberInActive,
      stepWrapperActive,
      stepWrapperDone,
      stepWrapperInActive,
    } = classes;
    if (isActive) {
      return { wrapperClass: stepWrapperActive, numberClass: stepNumberActive };
    } else if (isDone) {
      return { wrapperClass: stepWrapperDone, numberClass: stepNumberDone };
    }
    return {
      wrapperClass: stepWrapperInActive,
      numberClass: stepNumberInActive,
    };
  }, [isActive, isDone, classes]);
  return (
    <div
      className={`${classes.stepWrapper} ${wrapperClass}`}
      data-testid={`step-wrapper-${stepNumber}`}
    >
      <div className={`${classes.stepNumber} ${numberClass}`}>
        {isDone ? (
          <DoneIcon
            className={classes.icon}
            data-testid={`completed-icon-${stepNumber}`}
          />
        ) : (
          stepNumber
        )}
      </div>
      {!isMobile && (
        <span data-testid={`step-text-${stepNumber}`}>{title}</span>
      )}
    </div>
  );
};

export default Step;

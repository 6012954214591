import { Theme, withStyles } from "@material-ui/core/styles";
import { Box, Text } from "common";

export const ValueWrapper = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#f7f7f9",
    borderRadius: "4px",
    padding: "5px 10px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}))(Box);

export const TextWrapper = withStyles((theme: Theme) => ({
  root: {
    color: "#666666",
    fontWeight: 400,
    marginLeft: "20px",
    fontSize: "14px",
    maxHeight: "150px",
    overflow: "scroll",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      marginLeft: "0px",
      marginTop: "3px",
      textAlign: "left",
    },
  },
}))(Text);

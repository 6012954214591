import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Text } from "common";

interface IProps {
  title: string;
  onChange: (
    value: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value: string;
  testId?: string;
}
const TitledCheckbox: React.FC<IProps> = ({
  title,
  onChange,
  checked,
  value,
  testId,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="15px"
      width="auto"
      data-testid={testId || "titled-checkbox-custom"}
    >
      <Checkbox
        data-testid={`${
          testId ? `${testId}-input` : '"titled-checkbox-custom-input"'
        }`}
        onChange={onChange(value)}
        checked={checked}
        color="primary"
      />
      <Text
        fontSize="16px"
        fontWeight={400}
        textAlign="left"
        marginLeft="5px"
        data-testid="checkbox-title"
        color="text.primary"
      >
        {title}
      </Text>
    </Box>
  );
};

export default TitledCheckbox;

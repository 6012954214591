import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { IOption } from "store/reducers/config.reducers";
import { IState } from "store/store.interface";
import { useStyles } from "./SelectCountry.style";
import { Select } from "common";
import { getIcon } from "components/MyProfile/helpers/MyProfile.helper";

interface IProps {
  onChangeCountry: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  width?: string;
  minWidth?: string;
  isTransparent?: boolean;
  label?: string;
  disableUnderLine?: boolean;
  size?: "small" | "medium";
  inputStartPadding?: string;
  fullWidth?: boolean;
}
const SelectCountry: FC<IProps> = ({
  onChangeCountry,
  value,
  minWidth,
  width,
  isTransparent = true,
  label,
  disableUnderLine,
  size,
  inputStartPadding = "0px",
  fullWidth,
}) => {
  const { root } = useStyles();
  const { selectOptions = {}, storeIds = [] } = useSelector(
    (state: IState) => ({
      ...state.config,
      ...state.auth,
    })
  );

  const options = useMemo(() => {
    if (selectOptions.countries && selectOptions.countries.length > 0) {
      const { countries } = selectOptions;
      return storeIds.map((singleCountry: string) => {
        const { value, iconName, label, timeZone } =
          countries.filter(
            (country: IOption) => country.value === singleCountry
          )[0] || {};
        return {
          value,
          label: (
            <span className={root} data-testid={`${iconName}-icon`}>
              {getIcon(iconName,'40px','40px')}
              {label}
            </span>
          ),
          timeZone,
        };
      });
    }
    return [];
  }, [storeIds, selectOptions, root]);

  return (
    <Select
      id="select-country"
      options={options}
      onNativeChange={onChangeCountry}
      nativeValue={value}
      variant="native"
      isTransparent={isTransparent}
      minWidth={minWidth}
      width={width}
      inputStartPadding={inputStartPadding}
      label={label}
      disableUnderline={disableUnderLine}
      size={size}
      fullWidth={fullWidth}
    />
  );
};

export default SelectCountry;

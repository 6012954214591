import React, { FC, useEffect, useMemo, useState } from "react";
import SummaryComponent from "components/PromotionForm/Summary";
import { useDispatch, useSelector } from "react-redux";
import { EPromotionTypes, IState } from "store/store.interface";
import FormLoader from "Loader/Form";
import {
  ECampaignActions,
  ECampaignStatus,
} from "store/reducers/promotion.reducers";
import { setLocation } from "store/actions/auth.actions";
import Layout from "Layout";
import { useApiInfo, usePermissions } from "hooks";
import {
  PROMOTION_DOWNLOAD_BULK_COUPON,
  PROMOTION_GET_CAMPAIGN_BY_ID,
  PROMOTION_GET_REPORT,
  PROMOTION_SET_VALUE,
  PROMOTION_UPDATE_STATUS,
} from "store/store.types";
import {
  clearCreationForm,
  clearForm,
  getCampaignReport,
  getDownloadCoupons,
  setPromotionType,
  updateCampaignStatus,
} from "store/actions/promotion.actions";
import Section from "Layout/Section";
import { Box, Card, Link, Button } from "common";
import { ERoles } from "store/reducers/auth.reducers";
import TransactionHistory from "components/PromotionForm/TransactionHistory";

const Summary: FC = () => {
  const { roles, campaign } = useSelector((state: IState) => ({...state.promotion, ...state.auth, ...state.config}));
  const [loadingState, setLoadingState] = useState("");
  const { isLoading, error, done } = useApiInfo(PROMOTION_UPDATE_STATUS);
  const {
    isLoading: isGettingReport,
    error: gettingReportError,
    done: gettingReportDone,
  } = useApiInfo(PROMOTION_GET_REPORT);
  const {
    isLoading: isDownloadCSV,
    error: gettingDownloadCSVError,
    done: gettingDownloadCSVDone
  } = useApiInfo(PROMOTION_DOWNLOAD_BULK_COUPON);
  const { isLoading: isGettingCampaignLoading, done: isGettingCampaignDone } =
    useApiInfo(PROMOTION_GET_CAMPAIGN_BY_ID);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && (done || error)) {
      setLoadingState("");
    }
  }, [isLoading, error, done, dispatch]);

  useEffect(() => {
    if(!isDownloadCSV && (gettingDownloadCSVDone || gettingDownloadCSVError)) {
      setLoadingState("");
    }
  }, [gettingDownloadCSVDone, gettingDownloadCSVError, isDownloadCSV])

  useEffect(() => {
    if (!isGettingReport && (gettingReportDone || gettingReportError)) {
      setLoadingState("");
    }
  }, [gettingReportDone, gettingReportError, isGettingReport]);

  const availableUserActions = usePermissions(
    campaign ? campaign.status : undefined
  );

  const onApprove = () => {
    const { id, object } = campaign || {};
    if (id && object && !isLoading) {
      setLoadingState(ECampaignActions.approve);
      dispatch(updateCampaignStatus(id, object, ECampaignStatus.approved));
    }
  };

  const onComplete = () => {
    const { id, object } = campaign || {};
    if (id && object && !isLoading) {
      setLoadingState(ECampaignActions.complete);
      dispatch(updateCampaignStatus(id, object, ECampaignStatus.completed));
    }
  };

  const onPause = () => {
    const { id, object } = campaign || {};
    if (id && object && !isLoading) {
      setLoadingState(ECampaignActions.pause);
      dispatch(updateCampaignStatus(id, object, ECampaignStatus.paused));
    }
  };

  const onTakeLive = () =>{
    const { id, object } = campaign || {};
    if (id && object && !isLoading) {
      setLoadingState(ECampaignActions.takeLive);
      dispatch(updateCampaignStatus(id, object, ECampaignStatus.active));
    }
  }
  const onReject = () => {
    const { id, object } = campaign || {};
    if (id && object && !isLoading) {
      setLoadingState(ECampaignActions.reject);
      dispatch(updateCampaignStatus(id, object, ECampaignStatus.rejected));
    }
  };

  const onGetReport = () => {
    const { id, object, name } = campaign || {};
    if (id && object && !isGettingReport) {
      setLoadingState(ECampaignActions.getReport);
      dispatch(getCampaignReport(id, object, name));
    }
  };

  const onDownloadCoupon = () => {
    const {id} = campaign || {};
    if(id && !isDownloadCSV) {
      setLoadingState(ECampaignActions.downloadCoupons);
      dispatch(getDownloadCoupons(id))
    }
  };

  useEffect(() => {
    if (!isGettingCampaignLoading && !campaign) {
      dispatch(setLocation("/"));
    }
  }, [isGettingCampaignLoading, isGettingCampaignDone, campaign, dispatch]);

  const onBackClick = () => {
    dispatch(setLocation("/"));
  };

  const goToBasicInfoSection = () => {
    dispatch(clearCreationForm())
    dispatch(clearForm());
    delete campaign?.createdBy;
    delete campaign?.managedBy;
    if(campaign?.bankName && campaign.restrictions.bin && EPromotionTypes.BIN_CAMPAIGN !== campaign.type) {
      campaign.couponType = 'BIN_LOCKED_COUPON';
      dispatch({
        type: PROMOTION_SET_VALUE,
        payload: {...campaign, edit: true}
      })
    } else if(EPromotionTypes.BIN_CAMPAIGN === campaign?.type) {
      dispatch({
        type: PROMOTION_SET_VALUE,
        payload: {...campaign, edit: true}
      })
    } else {
      dispatch({
        type: PROMOTION_SET_VALUE,
        payload: {...campaign, couponType: EPromotionTypes.DISCOUNT_COUPON, edit: true}
      })
    }
    dispatch(setPromotionType(campaign?.type as any));
    dispatch(setLocation("/promotion-create"))
  }

  const [
    isDownloadCouponAvailable,
    isApproveAvailable,
    isRejectAvailable,
    isViewReportAvailable,
    isCompleteAvailable,
    isPauseAvailable,
    downloadCouponTitle,
    approveTitle,
    rejectTitle,
    viewReportTitle,
    completeTitle,
    pauseTitle,
    takeLiveTitle,
  ] = useMemo(() => {
    if (availableUserActions.length > 0) {
      const downloadCouponObj = 
        availableUserActions.filter(
          (permission) => (permission.action === ECampaignActions.downloadCoupons && EPromotionTypes.BULK_COUPON === campaign?.type)
        )[0] || {};
      const approveObj =
        availableUserActions.filter(
          (permission) => permission.action === ECampaignActions.approve
        )[0] || {};
      const rejectObj =
        availableUserActions.filter(
          (permission) => permission.action === ECampaignActions.reject
        )[0] || {};
      const viewReportobj =
        availableUserActions.filter(
          (permission) => permission.action === ECampaignActions.getReport
        )[0] || {};
      const completeObj =
        availableUserActions.filter(
          (permission) => permission.action === ECampaignActions.complete
        )[0] || {};
      const pauseObj =
        availableUserActions.filter(
          (permission) => (permission.action === ECampaignActions.pause && (EPromotionTypes.BIN_CAMPAIGN === campaign?.type || EPromotionTypes.BULK_COUPON === campaign?.type || EPromotionTypes.COUPON === campaign?.type || EPromotionTypes.DISCOUNT_COUPON === campaign?.type))
        )[0] || {};
      
      const takeLiveObj = 
          availableUserActions.filter(
            (permission) => permission.action === ECampaignActions.takeLive
          )[0]||{};
      return [
        downloadCouponObj && downloadCouponObj.action,
        approveObj && approveObj.action,
        rejectObj && rejectObj.action,
        viewReportobj && viewReportobj.action,
        completeObj && completeObj.action,
        pauseObj && pauseObj.action,
        downloadCouponObj.title,
        approveObj.title,
        rejectObj.title,
        viewReportobj.title,
        completeObj.title,
        pauseObj.title,
        takeLiveObj.title,
      ];
    }
    return [];
  }, [availableUserActions, campaign]);

  if (!campaign || isGettingCampaignLoading) {
    return (
      <Layout>
        <Section>
          <FormLoader />
        </Section>
      </Layout>
    );
  }
  return (
    <Layout>
      <Section>
        <Card padding={["40px 20px", "40px 20px", "50px 100px", "50px 100px"]}>
          {campaign.status === 'rejected' || campaign.type === EPromotionTypes.BADGE_CAMPAIGN || roles?.includes(ERoles.MANAGER) || (roles?.includes(ERoles.READONLY)) || campaign.status === ECampaignStatus.active  ? 
            <SummaryComponent isView={true} formValues={campaign} error={error} />
          :
            <SummaryComponent isView={true} formValues={campaign} error={error} onEditBasicInfo={goToBasicInfoSection} />
          }

          <TransactionHistory transactionHistory={campaign.trnHistory}  />
          
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexDirection={["column-reverse", "column-reverse", "row", "row"]}
          >
            <Link
              data-testid='back-btn'
              variant="default"
              color="primary"
              onClick={onBackClick}
              marginRight="15px"
            >
              Back
            </Link>
            {isDownloadCouponAvailable && (
              <Button
                data-testid='download-coupon-btn'
                variant="outlined"
                color="primary"
                round
                width="auto"
                minWidth="200px"
                margin="0 15px 0 0"
                onClick={onDownloadCoupon}
                disabled={loadingState === ECampaignActions.downloadCoupons}
              >
                {downloadCouponTitle}
              </Button>
            )}
            {isViewReportAvailable && (
              <Button
                data-testid='report-button'
                variant="outlined"
                color="primary"
                round
                width="auto"
                minWidth="200px"
                margin="0 15px 0 0"
                onClick={onGetReport}
                disabled={loadingState === ECampaignActions.getReport}
              >
                {viewReportTitle}
              </Button>
            )}
            {isRejectAvailable && (
              <Button
                data-testid='reject-button'
                variant="outlined"
                color="primary"
                round
                width="auto"
                minWidth="200px"
                margin="0 15px 0 0"
                onClick={onReject}
                disabled={loadingState === ECampaignActions.reject}
              >
                {rejectTitle}
              </Button>
            )}
            {isCompleteAvailable && (
              <Button
                data-testid='complete-button'
                variant="outlined"
                color="primary"
                round
                width="auto"
                minWidth="200px"
                margin="0 15px 0 0"
                onClick={onComplete}
                disabled={loadingState === ECampaignActions.complete}
              >
                {completeTitle}
              </Button>
            )}
            {isPauseAvailable && (
              <Button
                data-testid='pause-button'
                variant="outlined"
                color="primary"
                round
                width="auto"
                minWidth="200px"
                margin="0 15px 0 0"
                onClick={onPause}
                disabled={loadingState === ECampaignActions.pause}
              >
                {pauseTitle}
              </Button>
            )}
            {takeLiveTitle &&(
              <Button
              data-testid='take-live-button'
              variant="outlined"
              color="primary"
              round
              width="auto"
              minWidth="200px"
              margin="0 15px 0 0"
              onClick={onTakeLive}
              disabled={loadingState === ECampaignActions.takeLive}
            >
              {takeLiveTitle}
            </Button>
            )

            }
            {isApproveAvailable && (
              <Button
                data-testid='approve-button'
                variant="contained"
                color="secondary"
                round
                width="auto"
                minWidth="200px"
                onClick={onApprove}
                disabled={loadingState === ECampaignActions.approve}
              >
                {approveTitle}
              </Button>
            )}
          </Box>
        </Card>
      </Section>
    </Layout>
  );
};

export default Summary;

/**
 * API Types
 */
export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const API_ERROR = "API_ERROR";
export const API_CLEAR = "API_CLEAR";

/**
 * AUTH
 */
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_GET_USER = "AUTH_GET_USER";
export const AUTH_VERIFY_SESSION = "AUTH_VERIFY_SESSION";
export const AUTH_SET_COUNTRY = "AUTH_SET_COUNTRY";
export const AUTH_UPDATE_LOCATION = "AUTH_UPDATE_LOCATION";

/**
 * USERS
 */
export const CHANGE_PASSWORD ='CHANGE_PASSWORD'
export const GET_USERS ='GET_USERS'
export const SET_USERS ='SET_USERS'
export const ADD_NEW_USER = 'ADD_NEW_USER'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID'
export const DELETE_USER_BY_ID = 'DELETE_USER_BY_ID'

/**
 * CONFIG
 */
export const CONFIG_GET = "CONFIG_GET";
export const CONFIG_SET = "CONFIG_SET";
export const CONFIG_GET_CATEGORIES = "CONFIG_GET_CATEGORIES";
export const CONFIG_SET_CATEGORIES = "CONFIG_SET_CATEGORIES";
export const CONFIG_GET_BRANDS = "CONFIG_GET_BRANDS";
export const CONFIG_SET_BRANDS = "CONFIG_SET_BRANDS";
export const CONFIG_SET_SINGLE_BRAND = "CONFIG_SET_SINGLE_BRAND";
export const CONFIG_SET_SINGLE_CATEGORY = "CONFIG_SET_SINGLE_CATEGORY";
export const CONFIG_RESET_BRAND_AND_CATEGORY =
  "CONFIG_RESET_BRAND_AND_CATEGORY";

/**
 * PROMOTIONS
 */
export const PROMOTION_SET_TYPE = "PROMOTION_SET_TYPE";
export const PROMOTION_CREATE = "PROMOTION_CREATE";
export const PROMOTION_FETCH_CAMPAIGNS = "PROMOTION_FETCH_CAMPAIGNS";
export const PROMOTION_ADD_NEW_CAMPAGIN_TO_LIST =
  "PROMOTION_ADD_NEW_CAMPAGIN_TO_LIST";
export const PROMOTION_UPDATE_CAMPAIGN = "PROMOTION_UPDATE_CAMPAIGN";
export const PROMOTION_SET_CAMPAIGNS = "PROMOTION_SET_CAMPAIGNS";
export const PROMOTION_SET_SEARCH_TEXT = "PROMOTION_SET_SEARCH_TEXT";
export const PROMOTION_CLEAR_CREATION_FORM = "PROMOTION_CLEAR_CREATION_FORM";
export const PROMOTION_CLEAR_CAMPAIGNS_LIST = "PROMOTION_CLEAR_CAMPAIGNS_LIST";
export const PROMOTION_SET_FIELDS = "PROMOTION_SET_FIELDS";
export const PROMOTION_GET_FIELDS = "PROMOTION_GET_FIELDS";
export const PROMOTION_SET_VALUE = "PROMOTION_SET_VALUE";
export const PROMOTION_UPDATE_STATUS = "PROMOTION_UPDATE_STATUS";
export const PROMOTION_GET_REPORT = "PROMOTION_GET_REPORT";
export const PROMOTION_DOWNLOAD_BULK_COUPON = "PROMOTION_DOWNLOAD_BULK_COUPON";
export const PROMOTION_UPDATE_PAGE_NUMBER = "PROMOTION_UPDATE_PAGE_NUMBER";
export const PROMOTION_GET_CAMPAIGN_BY_ID = "PROMOTION_GET_CAMPAIGN_BY_ID";
export const PROMOTION_SET_CAMPAIGN_BY_ID = "PROMOTION_SET_CAMPAIGN_BY_ID";
export const PROMOTION_UPDATE_FILTER = "PROMOTION_UPDATE_FILTER";
export const PROMOTION_RESET_FILTER = "PROMOTION_RESET_FILTER";
export const PROMOTION_CLEAR_FIELDS = "PROMOTION_CLEAR_FIELDS";
export const PROMOTION_EXTEND = "PROMOTION_EXTEND";
export const PROMOTION_EXTEND_UPDATE_DATA = "PROMOTION_EXTEND_UPDATE_DATA";
export const PROMOTION_INCREMENTAL_DATA_ERROR = "PROMOTION_INCREMENTAL_DATA_ERROR";
export const PROMOTION_INCREMENTAL_DATA = "PROMOTION_INCREMENTAL_DATA";
export const PROMOTIION_INCREMENTAL_TYPE = "PROMOTIION_INCREMENTAL_TYPE";

/**
 * RESOURCE
 */

export const RESOURCE_GET_BY_ID = "RESOURCE_GET_BY_ID"; 
export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const RESOURCE_DATA = 'RESOURCE_DATA';
export const GET_RESOURCE_DATA = 'GET_RESOURCE_DATA';
export const FILE_UPLOAD_ERROR =  'FILE_UPLOAD_ERROR';
export const FILE_UPLOADING = "FILE_UPLOADING";
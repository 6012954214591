import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import { IProps } from "./Input.interface";
import { EInputVariant } from "../common.interface";

interface IInputAdornmentStyle {
  fontSize?: string;
  fontWeight?: number;
  fontColor?: string;
  adornmentMargin?: string;
  variant?: EInputVariant;
}
export const useFormControlStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    margin: ({ formControlMargin = "0px" }: IProps) => formControlMargin,
  },
  inputStyle: {
    backgroundColor: palette.background.default,
    "&:focus": {
      backgroundColor: palette.background.default,
    },
  },
}));

export const useStyledInputAdorment = makeStyles(({ palette }: Theme) => ({
  root: {
    fontSize: ({ fontSize = "12px" }: IInputAdornmentStyle) => fontSize,
    fontWeight: ({ fontWeight = 500 }) => fontWeight,
    color: ({ fontColor = palette.text.primary }) => fontColor,
    backgroundColor: "inherit",
    "&:focused": {
      backgroundColor: "inherit",
    },
    "& p": {
      marginBottom: "0px",
    },
  },
  positionStart: {
    margin: ({ adornmentMargin, variant }) => {
      if (adornmentMargin) {
        return adornmentMargin;
      } else {
        return "1px 0 0 0 !important";
      }
    },
  },
}));

export const StyledFormHelperText = withStyles({
  error: {
    fontWeight: 500,
  },
})(FormHelperText);

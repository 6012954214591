import { Theme, makeStyles } from "@material-ui/core/styles";
import { Shape } from "@material-ui/core/styles/shape";
import { IProps } from "./Button.interface";


export const roundHandler = (round: boolean|undefined, shape: Shape) =>{
  return round ? shape.borderRadius : 0
};

export const useButtonStyles = makeStyles(({ shape, palette }: Theme) => ({
  root: {
    boxShadow: "0px",
    textTransform: "none",
    borderRadius: ({ round }: IProps) => (round ? shape.borderRadius : 0),
    borderTopRightRadius: ({ isLoading, round }) =>
      isLoading ? "0px" : roundHandler(round, shape), // round ? shape.borderRadius : 0,
    borderTopLeftRadius: ({ isLoading, round }) =>
      isLoading ? "0px" : roundHandler(round, shape),
    fontSize: ({ fontSize = "13px" }) => fontSize,
    fontWeight: ({ fontWeight = 500 }) => fontWeight,
    width: ({ width = "auto", fullWidth }) =>
      fullWidth ? "100% !important" : width,
    height: ({ height = "auto" }) => height,
    maxWidth: ({ maxWidth = "auto" }) => maxWidth,
    minWidth: ({ minWidth = "auto" }) => minWidth,
    padding: ({ padding = "8px 25px" }) => padding,
    margin: ({ margin = "0px" }) => margin,
    "&$disabled": {
      color: palette.common.white,
      backgroundColor: "#B8B8B8",
      border: "1px solid #B8B8B8",
      "&:hover": {
        color: palette.common.white,
        backgroundColor: "#B8B8B8",
        border: "1px solid #B8B8B8",
      },
    },
  },
  disabled: {},
}));

export const useCustomButtonStyles = makeStyles(
  ({ palette, shape }: Theme) => ({
    root: {
      boxShadow: "0px",
      textTransform: "none",
      borderRadius: ({ round }: IProps) => (round ? shape.borderRadius : 0),
      borderTopRightRadius: ({ isLoading, round }) =>
        isLoading ? "0px" : roundHandler(round, shape),
      borderTopLeftRadius: ({ isLoading, round }) =>
        isLoading ? "0px" : roundHandler(round, shape),
      fontSize: ({ fontSize = "13px" }) => fontSize,
      fontWeight: ({ fontWeight = 500 }) => fontWeight,
      width: ({ width = "auto", fullWidth }) => (fullWidth ? "100%" : width),
      height: ({ height = "auto" }) => height,
      maxWidth: ({ maxWidth = "auto" }) => maxWidth,
      minWidth: ({ minWidth = "auto" }) => minWidth,
      padding: ({ padding = "8px 25px" }) => padding,
      margin: ({ margin = "0px" }) => margin,
      "&$disabled": {
        color: palette.common.white,
        backgroundColor: "#B8B8B8",
        border: "1px solid #B8B8B8",
        "&:hover": {
          color: palette.common.white,
          backgroundColor: "#B8B8B8",
          border: "1px solid #B8B8B8",
        },
      },
      backgroundColor: ({ customVariant }: IProps) => {
        switch (customVariant) {
          case "white":
            return palette.common.white;
          case "danger":
            return palette.error.light;
          default:
            return "inherit";
        }
      },
      color: ({ color, customVariant }) => {
        switch (customVariant) {
          case "white":
            if (color === "primary") {
              return palette.primary.main;
            }
            return palette.secondary.main;
          case "danger":
            return palette.common.white;
          default:
            return "inherit";
        }
      },
      "&:hover": {
        backgroundColor: ({ customVariant }: IProps) => {
          switch (customVariant) {
            case "white":
              return palette.common.white;
            case "danger":
              return palette.error.main;
            default:
              return "inherit";
          }
        },
        color: ({ color, customVariant }) => {
          switch (customVariant) {
            case "white":
              if (color === "primary") {
                return palette.primary.dark;
              }
              return palette.secondary.dark;
            case "danger":
              return palette.common.white;
            default:
              return "inherit";
          }
        },
      },
    },
  })
);

export const useWrapperStyle = makeStyles({
  root: {
    position: "relative",
    boxShadow: "0px",
    width: ({ width = "auto", fullWidth }: IProps) =>
      fullWidth ? "100% !important" : width,
    height: ({ height = "auto" }) => height,
    maxWidth: ({ maxWidth = "auto" }) => maxWidth,
    minWidth: ({ minWidth = "auto" }) => minWidth,
  },
});

import { isEmpty } from "lodash";
import {
  EDiscountTypes,
  EPromotionTypes,
  IAction,
  IFilter,
} from "store/store.interface";
import {
  PROMOTION_CLEAR_CAMPAIGNS_LIST,
  PROMOTION_CLEAR_CREATION_FORM,
  PROMOTION_RESET_FILTER,
  PROMOTION_SET_CAMPAIGNS,
  PROMOTION_SET_CAMPAIGN_BY_ID,
  PROMOTION_SET_FIELDS,
  PROMOTION_SET_TYPE,
  PROMOTION_SET_VALUE,
  PROMOTION_SET_SEARCH_TEXT,
  PROMOTION_ADD_NEW_CAMPAGIN_TO_LIST,
  PROMOTION_UPDATE_CAMPAIGN,
  PROMOTION_UPDATE_FILTER,
  PROMOTION_UPDATE_PAGE_NUMBER,
  PROMOTION_UPDATE_STATUS,
  PROMOTION_CLEAR_FIELDS,
  PROMOTION_EXTEND_UPDATE_DATA,
  RESOURCE_DATA,
  FILE_UPLOAD_ERROR,
  FILE_UPLOADING,
  PROMOTION_INCREMENTAL_DATA_ERROR,
  PROMOTION_INCREMENTAL_DATA,
  PROMOTIION_INCREMENTAL_TYPE,
} from "../store.types";

export interface IRestrictions {
  bin?: string[];
  sellerType?: string;
  isCustomerFirstOrder?: boolean|string;
  numberOfUsage?: number;
  numberOfUsagePerCustomer?: number;
  minOrderValue?: number;
  customerEmailGroup?: string[];
  [key:string]: IRestrictionExclusionType | any;
}

export interface IRestrictionExclusionType {
  op:string;
  value:string[]
}
export type TBadgeText = {
  normalText?: string;
  boldText?: string;
};
export interface IBadge {
  code?: string;
  type?: string;
  text?: {
    ar?: TBadgeText;
    en?: TBadgeText;
  };
  placement?: string;
}
export interface IPromotionForm {
  type?: EPromotionTypes;
  campaignName?: string;
  startDate?: Date;
  endDate?: Date;
  description?: string;
  visibleToRetailCustomer?: string; // "yes" or "no"
  code?: string;
  numberOfCoupons?: string;
  edit?: boolean;
  id?: string;
  bankName?: any;
  object?: string;
  schedule?: any;
  couponType?: any;//EPromotionTypes;
  status?: ECampaignStatus;
  badge?: IBadge;
  codeConfig?: {
    pattern?: string,
    charset?: string
  }
  discount?: {
    type?: EDiscountTypes;
    value?: number;
    maxAmount?: number;
  };
  restrictions: IRestrictions;
}

export interface ITransaction {
  actorName: string;
  actorEmail: string;
  previousStatus: string;
  currentStatus: string;
  dateTime: string;
  id: string;
}

interface ITransactionHistory {
  trnHistory?: ITransaction[]
}

export enum EOrderRestrictionType {
  Inclusion = "inclusion",
  Exclusion = "exclusion",
}
export enum ExclusionLabel {
  in = "in",
  notIn = "notIn",
  op = "op"
}
export enum EFieldType {
  input = "input",
  select = "select",
  dateRange = "dateRange",
  radioGroup = "radioGroup",
  selectColor = "selectColor",
  checkbox = "checkbox",
  badgePreview = "badgePreview",
  incrementalDiscount = "incrementalDiscount",
  incButton = "incButton",
  decButton = "decButton"
}

export interface ICondition {
  keys: string[];
  as: { [key: string]: string };
  defaultValue?: string;
}

export type TWhenToRenderField = {
  keys: string[];
  values: any[];
};

export type TAtLeastToRenderField = {
  keys: string[];
}

export type TDefault = {
  field?: any;
  startTime?: string;
  endTime?: string;
};

export type TFieldType = {
  isGeneral?: boolean;
  isSingle?: boolean;
  isIncrementalDiscount?: boolean;
  id: string;
  name: string;
  keys: string[];
  label?: string | ICondition;
  hint?: string;
  type: EFieldType;
  value?: any;
  optionsType?: string;
  validator?: string | ICondition;
  maxChar?: number;
  minChar?: number;
  maxNumber?: number;
  minNumber?: number;
  maxYears?: number;
  isVisible?: boolean;
  isEditable?: boolean;
  isRequired?: boolean;
  startDateKeys?: string[];
  endDateKeys?: string[];
  when?: TWhenToRenderField[];
  notShow?: TWhenToRenderField[];
  atLeast?: TAtLeastToRenderField[];
  withTiming?: boolean;
  isTimingOneDay?: boolean;
  defaultValue?: string | TDefault;
  multiline?: boolean;
  rowsMax?: number;
  description?:string;
  errorMessage?: string;
  ignoreClear?: boolean;
};

export type TRestrictionFieldLimitation = {
  notExist: string[];
  message: string;
};

export type ERestrictionType =
  | "input"
  | "number"
  | "text"
  | "file"
  | "select"
  | "brand"
  | "sellerType"
  | "shipmentType"
  | "category"
  | "boolean"
  | "float";
export type TRestrictionType = {
  id: string;
  multi?: boolean;
  name: string;
  label: string;
  type: ERestrictionType;
  fieldTitle: string;
  optionsType?: string;
  description?: string;
  restrictions?: TRestrictionFieldLimitation;
  isRequired?: boolean;
  fileValidation?: "number" | "emails";
  maxChar?: number;
  minChar?: number;
  maxNumber?: number;
  minNumber?: number;
  when?: any,
  restrictionType?:string
};

export interface IRestrictionsForm {
  header: string;
  description?: string;
  featured?: boolean;
  bin_lock_coupon?: boolean;
  fields: TRestrictionType[];
  when?: any;
}

export type TApprovalOption = {
  id: string;
  label: string;
  value: string;
  required?: boolean;
};

export type TPoints={
  label:string
}
export type TLayout = "layout_1" | "layout_2";

export type TApproval = {
  header: string;
  waringHeader?:string;
  sections: {
    header: string;
    layout: TLayout;
    options: TApprovalOption[];
    points?: TPoints[];
  }[];
};

export interface IFormDetails {
  header: string;
  title: string;
  restrictionsTitle?: string;
  layout: TLayout;
  fields: TFieldType[];
  restrictions: IRestrictionsForm[];
  approval: TApproval;
}

export enum ECampaignObjects {
  coupon = "COUPON",
  BIN = "BIN_CAMPAIGN",
}

export enum ECampaignStatus {
  approved = "approved",
  active = "active",
  paused = "paused",
  completed = "completed",
  expired = "expired",
  pending = "pending",
  created = "created",
  rejected = "rejected",
  creating = "creating"
}

export interface IDashboard {
  id: string;
  object: ECampaignObjects;
  code: string;
  type: string;
  startDate: Date;
  endDate: Date;
  name: string;
  status: ECampaignStatus;
  createdByName: string;
  description?: string;
  redeemedCount: number;
  redeemedAmount: number;
  totalOrdersValue: number;
  createdBy?: {
    email?: string,
    id?: string,
    name?: string
  },
  managedBy?: {
    email?: string,
    id?: string,
    name?: string
  }
}

export enum ECampaignActions {
  view = "view",
  approve = "approve",
  reject = "reject",
  getReport = "getReport",
  downloadCoupons = "downloadCoupons",
  complete = "complete",
  pause = "pause",
  takeLive = "takeLive"
}

export interface IPromotion {
  creationForm: IPromotionForm;
  dashboard?: IDashboard[];
  newCampaign?: IDashboard;
  formDetails?: IFormDetails;
  formCode?: string;
  campaign?: IDashboard & IPromotionForm & ITransactionHistory;
  filters?: IFilter[];
  page: number;
  total: number;
  searchTerm?: string;
  resourceData?: any;
  FileUploadMessage?:string;
  fileUploading?:null|boolean;
  formValid:boolean;
  incrementalData:any[]|null;
  incrementalType:string
}

export interface IQuery {
  limit?: number;
  page?: number;
  filters?: IFilter[];
}

export interface ISearchQuery {
  searchTerm: string;
  limit?: number;
  page?: number;
}

export const INITIAL_STATE = {
  creationForm: {
    restrictions: {},
  },
  resourceData: null,
  fileUploading:null,
  formValid:false,
  incrementalData:null,
  incrementalType:"",
  page: 0,
  total: 0,
};

export default (state: IPromotion = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case PROMOTION_SET_TYPE:
      return {
        ...state,
        creationForm: {
          ...state.creationForm,
          type: action.payload
        },
      };
    case PROMOTION_SET_FIELDS:
      let dataToInject;
      let formCode;
      const data = action.payload;
      if (!isEmpty(data) && Array.isArray(data)) {
        dataToInject = data[0].data;
        formCode = data[0].code;
      }
      return {
        ...state,
        formCode: formCode,
        formDetails: dataToInject,
      };
    case PROMOTION_SET_VALUE:
      return {
        ...state,
        creationForm: {...action.payload, type: action.payload.type},
      };
    case PROMOTION_CLEAR_FIELDS:
      return {
        ...state,
        formDetails: [],
      };
    case PROMOTION_CLEAR_CREATION_FORM:
      return {
        ...state,
        creationForm: {
          restrictions: {},
        },
      };
    case PROMOTION_CLEAR_CAMPAIGNS_LIST:
      return {
        ...state,
        dashboard: [],
        page: 0,
        total: 0,
      };
    case PROMOTION_SET_CAMPAIGN_BY_ID:
      return {
        ...state,
        campaign: action.payload,
      };
    case PROMOTION_EXTEND_UPDATE_DATA:
      return {
        ...state,
        campaign: action.payload,
        dashboard: state.dashboard?.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };
    case PROMOTION_UPDATE_CAMPAIGN: 
      return {
        ...state,
        dashboard: state.dashboard?.map((item) => {
          if(item.id === action.payload.id) {
            return action.payload
          }
          return item;
        })
      }
    case PROMOTION_SET_CAMPAIGNS:
      const { results } = action.payload;
      const newCampaign = state.newCampaign;
      let dashboardState = [...(state.dashboard || []), ...results];
      let total = state.total;
      if (newCampaign) {
        dashboardState = [newCampaign, ...dashboardState];
      }
      if (results && Array.isArray(results)) {
        total = state.total + results.length;
      }
      return {
        ...state,
        dashboard: dashboardState,
        newCampaign: null,
        total,
      };
    case PROMOTION_UPDATE_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    case PROMOTION_SET_SEARCH_TEXT:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case PROMOTION_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case PROMOTION_RESET_FILTER:
      return {
        ...state,
        filters: [],
      };
    case RESOURCE_DATA: 
      return {
        ...state,
        resourceData: action.payload
      };
    case FILE_UPLOADING :
      return{
        ...state,
        fileUploading: action.payload
    };
    case FILE_UPLOAD_ERROR:
      return{
        ...state,
        FileUploadMessage:action.payload
    };  
    case PROMOTION_INCREMENTAL_DATA_ERROR:
      return{
        ...state,
        formValid:action.payload
      }
    case PROMOTION_INCREMENTAL_DATA:
      return{
        ...state,
        incrementalData:action.payload
      }  
    case PROMOTIION_INCREMENTAL_TYPE:
      return{
        ...state,
        incrementalType:action.payload
      }  
    case PROMOTION_UPDATE_STATUS:
      const { id: idToUpdate, status: newStatus } = action.payload;
      const { dashboard = [] } = state;
      const newDashboard = dashboard.map((promotion) =>
        promotion.id === idToUpdate
          ? { ...promotion, status: newStatus }
          : promotion
      );
      return {
        ...state,
        dashboard: newDashboard,
        campaign: {
          ...state.campaign,
          status: newStatus,
        },
      };
    case PROMOTION_ADD_NEW_CAMPAGIN_TO_LIST:
      return {
        ...state,
        newCampaign: action.payload,
      };
    default:
      return state;
  }
};

import React, { FC } from "react";
import { Chip } from "common";
import { useBadgeInfo } from "hooks";

interface IProps {
  as: string;
  margin?: string;
  height?: string;
  borderRadius?: string;
  width?: string;
  variant?: "contained" | "outlined";
}
const Badge: FC<IProps> = ({
  as,
  margin,
  height = "auto",
  borderRadius = "0px",
  width = "auto",
  variant,
}) => {
  const { text, fontColor, bgColor } = useBadgeInfo(as);

  if (variant === "outlined") {
    return (
      <Chip
        data-testid="role-sticker"
        borderRadius={borderRadius}
        backgroundColor="#fff"
        customColor={bgColor}
        border={`1px solid ${bgColor}`}
        fontSize="12px"
        width={width}
        height={height}
        margin={margin}
        label={text}
      />
    );
  }
  return (
    <Chip
      data-testid="role-sticker"
      borderRadius={borderRadius}
      backgroundColor={bgColor}
      customColor={fontColor}
      fontSize="12px"
      width={width}
      height={height}
      margin={margin}
      label={text}
    />
  );
};

Badge.defaultProps = {
  variant: "contained",
};

export default Badge;

import React, { FC } from "react";
import { StyledIconButton } from "./Drawer.style";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import { IProps } from "./Drawer.interface";

const StyledDrawer: FC<IProps> = ({
  anchor = "left",
  children,
  theme = "primary",
  open = false,
  toggleDrawer,
}) => {
  return (
    <>
      <StyledIconButton
        color={theme}
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        edge="end"
        size="medium"
        data-testid='drawer-icon'
      >
        <MenuIcon fontSize="large" color={theme} />
      </StyledIconButton>
      <SwipeableDrawer
        anchor={anchor}
        onOpen={toggleDrawer(true)}
        open={open}
        onClose={toggleDrawer(false)}
      >
        {children}
      </SwipeableDrawer>
    </>
  );
};

export default StyledDrawer;

import React, { FC, useEffect, useMemo } from "react";
import { Header } from "custom";
import Main from "./Main";
import HeaderElements from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/store.interface";
import { Box, Text } from "common";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { setLocation } from "store/actions/auth.actions";
import { ERoles } from "store/reducers/auth.reducers";

const dropMenuRoutes = [
  { label: "My Profile", to: "/my-profile" },
  { label: "Logout", to: "/logout" },
];
const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { name, email, roles, scopes } = useSelector((state: IState) => ({
    ...state.auth,
    ...state.config,
  }));

  useEffect(() => {
    if (roles && roles[0] === ERoles.ADMIN) {
      if (dropMenuRoutes.length === 2)
        dropMenuRoutes.unshift({ label: "Users", to: "/users" });
    }
  }, [roles]);

  const roleName = useMemo(() => {
    if (roles && roles.length > 0 && scopes && scopes[roles[0]]) {
      const scope = scopes[roles[0]];
      return scope.text;
    }
  }, [roles, scopes]);

  const dispatch = useDispatch();

  const onLogoClick = () => {
    dispatch(setLocation("/"));
  };

  const menuLabel = useMemo(() => {
    return (
      <Box display="flex" justifyContent="center">
        <AccountCircle color="primary" />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          marginLeft="6px"
          maxWidth="125px"
          overflow="hidden"
        >
          <Text
            fontSize="10px"
            fontWeight={500}
            marginBottom="3px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            Hi, {name || email}
          </Text>
          {roleName && (
            <Text fontSize="10px" color="primary.main">
              {roleName}
            </Text>
          )}
        </Box>
      </Box>
    );
  }, [roleName, name, email]);
  return (
    <>
      <Header
        menuLabel={menuLabel}
        dropMenuRoutes={dropMenuRoutes}
        onLogoClick={onLogoClick}
      >
        <HeaderElements />
      </Header>
      <Main>{children}</Main>
    </>
  );
};

export default Layout;

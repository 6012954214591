import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Box } from "common";

const MainLoader: React.FC = () => {
  return (
    <Box padding="15px">
      <Box>
        <Skeleton variant="rect" animation="pulse" height={60} width="100%" />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY="10px"
        flexDirection={["column", "row", "row", "row"]}
      >
        <Skeleton variant="text" animation="pulse" width="100px" />
        <Skeleton variant="rect" animation="pulse" height={20} width="300px" />
      </Box>
      <Box marginY="10px" width={["100%", "500px", "500px", "500px"]}>
        <Skeleton variant="rect" animation="pulse" height={20} width="100%" />
      </Box>
      <Box
        gridTemplateColumns={["1fr", "1fr", "10fr 2fr", "10fr 2fr"]}
        gridGap="20px"
      >
        <Box gridTemplateColumns="1fr" gridGap="20px">
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={50} width="100%" />
        </Box>
        <Box gridTemplateColumns="1fr" gridGap="20px">
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
          <Skeleton variant="rect" animation="pulse" height={30} width="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLoader;

import { makeStyles, Theme } from "@material-ui/core/styles";
import COLORS from "common/Theme/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  profileDetailContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  profileSubContainer: {
    display: "flex",
    width: "28%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: 8,
    },
  },
  headingText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: COLORS.MINE_SHAFT,
  },
  headingSubText: {
    fontSize: "16px",
    lineHeight: "20px",
    color: COLORS.BLACK,
    fontWeight: 700,
  },
  styledButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    borderRadius: 4,
  },
  changePasswordModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 16,
    width: "496px",
    background: theme.palette.common.white,
    border: `1px solid ${COLORS.AQUA}`,
    boxShadow: "0px 0px 10px 2px rgba(14, 90, 167, 0.1)",
    borderRadius: "8px",
    gap: 16,
  },
  normalText: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  dangerText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.secondary.main,
  },
  submitButton: {
    margin: "15px 0",
    width: "100%",
    borderRadius: 4,
    background: theme.palette.secondary.main,
    padding: "16px 48px",
    color: theme.palette.common.white,
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 700,
    cursor: "pointer",
    "&:disabled": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "38%",
      pointerEvents: "none",
    },
  },
  notification:{
    position:'absolute',
    left:'40%',
    top:'8%',
    zIndex:1
  }
}));

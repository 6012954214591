import { makeStyles, Theme } from "@material-ui/core";

interface IBadgeStyle {
  width?: string;
  height?: string;
  withMargin?: boolean;
  bgColor?: string;
  color?: string;
  fontSize?: string;
}
export const useStyles = makeStyles((theme: Theme) => ({
  badgePreviewContainer: {
    width: ({ width = "148px" }: IBadgeStyle) => width,
    height: ({ height = "236px" }) => height,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E6E6E6",
    borderRadius: "4px",
    padding: "5px",
    margin: ({ withMargin }) => (withMargin ? "0 20px" : "0px"),
  },
  badgePrviewRect: {
    height: "12px",
    width: "100%",
    float: "left",
    backgroundColor: "rgba(161, 177, 187, 0.5)",
  },
  badgePreviewSquare: {
    display: "flex",
    justifyContent: "flex-end",
    "& div": {
      borderRadius: "4px",
      height: "30px",
      width: "30px",
      backgroundColor: "rgba(161, 177, 187, 0.5)",
    },
  },
  badgeText: {
    backgroundColor: ({ bgColor = "#fff" }) => bgColor,
    color: ({ color = "#000" }) => color,
    padding: "4px",
    borderRadius: "5px",
    fontSize: ({ fontSize = "11px" }) => fontSize,
    width: "fit-content",
    "& span": {
      margin: "0",
      fontWeight: 700,
    },
  },
  badgeEmptyPicture: {
    display: "flex",
    marginTop: "25px",
  },
}));

import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {ExclusionLabel, IRestrictions } from "store/reducers/promotion.reducers";
import { IEntry } from "../../SearchableInput";
import CategoriesSearchableInput from "./Categories";
import BrandsSearchableInput from "./Brands";
import SellerType from './SellerType';
import FileUpload from "../CustomFields/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { setPromotionObject } from "store/actions/promotion.actions";
import { IRestrictionField } from "./RestrictionsBlock";
import { ParseResult } from "papaparse";
import { IState } from "store/store.interface";
import { isEmail, regEx } from "utils/app.utils";
import { Box, Tooltip ,Input, Select } from "common";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  setBrandHelper,
  setCategoryHelper,
} from "store/actions/config.actions";
import { IconButton } from "@material-ui/core";
import ShipmentType from "./ShipmentType";
import { isEditingPausedCampaign } from "./RestrictionBlock.helper";

interface IProps {
  disable: boolean;
  field: IRestrictionField;
  onChange: (value: IRestrictionField) => void;
  onRemoveElement: (field: IRestrictionField) => () => void;
  restrictionsValues: IRestrictions;
  value: any;
  isFeatured?: boolean;
}

export enum ERestrictions {
  BRAND = "brand",
  CATEGORY = "category",
  SKU = "sku",
  IS_CUSTOMER_FIRST_ORDER = "isCustomerFirstOrder",
  NUMBER_OF_USAGE = "numberOfUsage",
  NUMBER_OF_USAGE_PER_CUSTOMER = "numberOfUsagePerCustomer",
  MINIMUM_ORDER_VALUE = "minOrderValue",
  SELLERTYPE = "sellerType"
}

export enum ERestrictionsSellerType {
  RETAIL = "RETAIL",
  MARKETPLACE = "MARKETPLACE"
}

const booleanOptions = [
  { label: "Yes it is", value: "yes" },
  { label: "No it's not", value: "no" },
];

const Field: FC<IProps> = ({
  disable,
  field,
  onRemoveElement,
  restrictionsValues,
  value,
  onChange,
  isFeatured,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const {
    selectOptions = {},
    selectedBrands = [],
    selectedCategories = [],
    creationForm,
    resourceData
  } = useSelector((state: IState) => ({...state.config, ...state.promotion}));

  useEffect(() => {
    if(resourceData !== null) {
      setPromotionObject(resourceData?.id, ["restrictions", "customerEmailGroup"])
    }
  }, [resourceData])

  const { maxChar, minChar } = useMemo(() => {
    const { maxChar = 1000, minChar = 0 } = field;
    return { maxChar, minChar };
  }, [field]);

  const limitRegex = useMemo(() => {
    return new RegExp(`/^.{${minChar},${maxChar}}$/`);
  }, [maxChar, minChar]);

  const selectedBrand = useMemo(() => {
    return (selectedBrands.filter((brand) => brand.value === value)[0] || {})
      .label;
  }, [selectedBrands, value]);

  const selectedCategory = useMemo(() => {
    return (
      selectedCategories.filter((category) => category.value === value)[0] || {}
    ).label;
  }, [selectedCategories, value]);

  const onChangeInputHandler = useCallback(
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = field;
      onChange({ ...field, value: event.target.value.trim() });
      dispatch(setPromotionObject([event.target.value.trim()], ["restrictions", name]));
    },
    [dispatch, field, onChange]
  );

  const onChangeSelectHandler = useCallback(
    (name: string) =>
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...field, value: target.value });
        if(target.value === ERestrictionsSellerType.RETAIL) {
          dispatch(setPromotionObject('', ["restrictions", "sellerId"]))
        }
        dispatch(setPromotionObject(target.value, ["restrictions", name]));
      },
    [dispatch, field, onChange]
  );

  const onChangeBooleanHandler = useCallback(
    (name: string) =>
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const formatedValue = target.value === "yes" ? true : false;
        onChange({ ...field, value: formatedValue });
        dispatch(setPromotionObject(formatedValue, ["restrictions", name]));
      },
    [dispatch, field, onChange]
  );

  const onChangeBrandHandler = useCallback(
    (item: IEntry) => {
      const { brand = {op:[],value:[]} } = restrictionsValues;
      const { value: existingValue } = item;
      let newBrandList = [
        ...brand.value.filter((brandValue:any) => value !== brandValue),
      ];
      if (existingValue) {
        newBrandList = [...newBrandList, existingValue];
      }
      if (item.value) {
        dispatch(setBrandHelper(item));
      }
      onChange({ ...field, value: existingValue });
      dispatch(
        setPromotionObject(newBrandList, ["restrictions", ERestrictions.BRAND])
      );
    },
    [dispatch, restrictionsValues, field, onChange, value]
  );

  const onChangeCategoryHandler = useCallback(
    (item: IEntry) => {
      const { category = {op:[],value:[]} } = restrictionsValues;
      const { value: existingValue } = item;
      let newCategoryList = [
        ...category.value.filter((categoryValue:any) => value !== categoryValue),
      ];
      if (existingValue) {
        newCategoryList = [...newCategoryList, existingValue];
      }
      if (item.value) {
        dispatch(setCategoryHelper(item));
      }
      onChange({ ...field, value: existingValue });
      dispatch(
        setPromotionObject(newCategoryList, [
          "restrictions",
          ERestrictions.CATEGORY,
        ])
      );
    },
    [restrictionsValues, dispatch, field, onChange, value]
  );

  const onChangeNumberValue = useCallback(
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const {
        maxChar,
        minChar,
        type,
        maxNumber = Number.MAX_VALUE,
        minNumber = 1,
      } = field;
      const regexForTest: RegExp = regEx[type];
      onChange({ ...field, value: value });
      setError("");
      let actualValue = "";
      if (regexForTest && regexForTest.test(value)) {
        actualValue = value;
        if (!isNaN(+value) && (+value > maxNumber || +value < minNumber)) {
          const message =
            maxNumber === Number.MAX_VALUE
              ? `Number should be more than or equal to ${minNumber}`
              : `Number should be between ${minNumber} and ${maxNumber}`;
          setError(message);
        }
      } else if (limitRegex.test(value)) {
        setError(`Text length must be between ${minChar} and ${maxChar}`);
      } else {
        setError(
          `Value must be ${
            type === "number" ? "integer number" : "float number"
          }`
        );
      }
      dispatch(setPromotionObject(actualValue, ["restrictions", name]));
    },
    [dispatch, onChange, field, limitRegex]
  );

  const onCompleteFileUpload = useCallback(
    (name: string) => (result: ParseResult<any>) => {
      const { data } = result;
      const { fileValidation ,label} = field;
      const isExclusionField = label.includes("Exclude");
      const exclusionDispatch =   setPromotionObject(
        isExclusionField ? ExclusionLabel.notIn : ExclusionLabel.in,
        ["restrictions", name, ExclusionLabel.op]
      )
      const dataString = data.toString().split(",");
      let finalSkus = ([] as string[]).concat(data);
      if (fileValidation === "number") {
        finalSkus = data.filter((item) => item && !isNaN(item));
      } else if (fileValidation === "emails") {
        finalSkus = data.filter((item) => item && isEmail(item));
      }
      if (name !== "customerEmailGroup") {
        if (name === ERestrictions.BRAND || name === ERestrictions.CATEGORY) {
          onChange({ ...field, value: dataString });
          dispatch(exclusionDispatch);
          dispatch(
            setPromotionObject(dataString, ["restrictions", name, "value"])
          );
        } else if (name === ERestrictions.SKU) {
          onChange({ ...field, value: finalSkus });
          dispatch(exclusionDispatch);
          dispatch(
            setPromotionObject(finalSkus, ["restrictions", name, "value"])
          );
        } else {
          onChange({ ...field, value: finalSkus });
          dispatch(setPromotionObject(finalSkus, ["restrictions", name]));
        }
      }
    },
    [dispatch, onChange, field]
  );

  const options = useMemo(() => {
    let options = selectOptions[field.optionsType || ""] || [];
    return options;
  },
    [selectOptions, field]
  );

  const booleanFieldValue = useMemo(() => {
    if (value === true) {
      return "yes";
    } else if (value === false) {
      return "no";
    }
    return "";
  }, [value]);

  const renderField = useCallback(() => {
    const { name, type, fieldTitle } = field;
    switch (type) {
      case 'text': 
        return (
          <Input
            disabled={disable}
            label={fieldTitle}
            value={value}
            onChange={onChangeInputHandler(name)}
            fontSize="16px"
            variant="filled"
            size="medium"
            error={!!error}
            errorMessage={error}
          />
        )
      case "sellerType":
        return (
          <SellerType
            disable={disable}
            label={fieldTitle}
            error={error}
            value={value}
          />
        )
      case "shipmentType":
        return (
          <ShipmentType
            disable={disable}
            label={fieldTitle}
            error={error}
            value={value}
          />
        );
      case "brand":
        return (
          <BrandsSearchableInput
            disable={disable}
            label={fieldTitle}
            onChange={onChangeBrandHandler}
            selectedItem={selectedBrand}
            error={error}
          />
        );
      case "category":
        return (
          <CategoriesSearchableInput
            disable={disable}
            label={fieldTitle}
            onChange={onChangeCategoryHandler}
            selectedItem={selectedCategory}
            error={error}
          />
        );
      case "file":
        return (
          <FileUpload
            name={name}
            disabled={disable}
            onCompleteUpload={onCompleteFileUpload(name)}
            setError={setError}
            buttonTitle="Upload"
            label={fieldTitle}
            fileSelected={value && value.length ? true : false}
            error={error}
          />
        );
      case "number":
        return (
          <Input
            disabled={disable}
            label={fieldTitle}
            value={value}
            onChange={onChangeNumberValue(name)}
            fontSize="16px"
            variant="filled"
            size="medium"
            error={!!error}
            errorMessage={error}
            testId='number-min-order-value'
          />
        );
      case "float":
        return (
          <Input
            disabled={disable}
            label={fieldTitle}
            value={value}
            onChange={onChangeNumberValue(name)}
            fontSize="16px"
            variant="filled"
            size="medium"
            error={!!error}
            errorMessage={error}
          />
        );
      case "select":
        return (
          <Select
            disable={disable}
            fullWidth
            width="100%"
            options={options}
            label={fieldTitle}
            onNativeChange={onChangeSelectHandler(name)}
            nativeValue={value}
            size="medium"
            variant="native"
            error={!!error}
            errorMessage={error}
          />
        );
      case "boolean":
        return (
          <Select
            disable={disable}
            fullWidth
            width="100%"
            options={booleanOptions}
            label={fieldTitle}
            onNativeChange={onChangeBooleanHandler(name)}
            nativeValue={booleanFieldValue}
            size="medium"
            variant="native"
            error={!!error}
            errorMessage={error}
          />
        );
      default:
        return null;
    }
  }, [
    disable,
    options,
    onChangeNumberValue,
    onChangeSelectHandler,
    onCompleteFileUpload,
    onChangeBrandHandler,
    onChangeCategoryHandler,
    onChangeBooleanHandler,
    onChangeInputHandler,
    value,
    field,
    selectedBrand,
    selectedCategory,
    booleanFieldValue,
    error,
  ]);

  const fieldComponent = useMemo(() => {
    return (
      <>
        <>{renderField()}</>
        {(!isFeatured ||
          isEditingPausedCampaign(creationForm)) && (
            <Box ml="5px">
              <IconButton
                disabled={disable}
                data-testid="remove-button"
                onClick={onRemoveElement(field)}
                size="small"
              >
                <RemoveCircle color="secondary" />
              </IconButton>
            </Box>
          )}
      </>
    );
  }, [onRemoveElement, renderField, field, isFeatured, disable]);

  return (
    <>
      <Box
        display="flex"
        alignContent="center"
        alignItems="center"
        width="100%"
      >
        {field.description ? (
          <Tooltip
            isIconOnly={true}
            title={field.description}
            withHelpIcon
            wrapperWidth="100%"
          >
            {fieldComponent}
          </Tooltip>
        ) : (
          fieldComponent
        )}
      </Box>
    </>
  );
};

export default Field;

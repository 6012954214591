import { withStyles, AppBar, Toolbar, Box, Theme } from "@material-ui/core";

export const StyledAppBar = withStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(9),
    borderTop: `6px solid ${theme.palette.primary.main}`,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "0px 0px 16px 0px rgb(0 0 0 / 14%)",
  },
}))(AppBar);

export const StyledToolBar = withStyles((theme: Theme) => ({
  root: {
    maxWidth: "1520px",
    margin: "auto",
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
  },
}))(Toolbar);

export const NavigationsWrapper = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0px 30px",
  },
})(Box);

export const DesktopNavigationContainer = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))(Box);

export const MobileNavigationContainer = withStyles((theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
}))(Box);

import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: "#fff",
  },
  stepNumber: {
    width: "28px",
    height: "28px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      width: "22px",
      height: "22px",
    },
  },
  stepNumberActive: {
    backgroundColor: theme.palette.common.black,
  },
  stepNumberInActive: {
    backgroundColor: "#B2B2B2",
  },
  stepNumberDone: {
    backgroundColor: "#2ED47A",
  },
  stepWrapper: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: "16px",
      lineHeight: "24px",
      marginLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        whiteSpace: "nowrap",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  stepWrapperActive: {
    "& span": {
      color: theme.palette.common.black,
      fontWeight: 600,
    },
  },
  stepWrapperInActive: {
    "& span": {
      color: "#979797",
      fontWeight: 400,
    },
  },
  stepWrapperDone: {
    "& span": {
      color: "#979797",
      fontWeight: 400,
    },
  },
}));

import { Theme, withStyles } from "@material-ui/core";
import { Box, Text } from "common";

export const TransactionHistory = withStyles((theme: Theme) => ({
  root: {
    marginBottom: "32px",
  },
}))(Box);

export const Transactions = withStyles((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "2fr 1.5fr 1fr 1fr 1fr 1.5fr",
    rowGap: "16px",
    columnGap: "16px",
  },
}))(Box);

export const TransactionHistoryHeading = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "24px",
  },
}))(Text);

export const ColumnHeading = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 700,
    fontSize: "14px",
  },
}))(Text);

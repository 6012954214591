import React, { ChangeEvent, FC, useCallback } from "react";
import SearchableInput, { IEntry } from "components/SearchableInput";
import { useApiInfo } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandsByKeyword,
  setBrandsEntries,
} from "store/actions/config.actions";
import { IState } from "store/store.interface";
import { CONFIG_GET_BRANDS } from "store/store.types";
import { debounce } from "lodash";

interface IProps {
  disable?: boolean;
  onChange?: (item: IEntry) => void;
  selectedItem?: string;
  label?: string;
  error?: string;
}
const Brands: FC<IProps> = ({ disable, onChange, selectedItem, label, error }) => {
  const { brands = [] } = useSelector((state: IState) => state.config);
  const { isLoading } = useApiInfo(CONFIG_GET_BRANDS);
  const dispatch = useDispatch();

  const debouncedGetBrands = useCallback(
    debounce((search: string) => {
      if (search) {
        dispatch(getBrandsByKeyword(search));
      } else {
        dispatch(setBrandsEntries([]));
      }
    }, 500),
    []
  );

  const onTypingHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debouncedGetBrands(event.target.value);
    },
    [debouncedGetBrands]
  );
  return (
    <SearchableInput
      disable={disable}
      entries={brands}
      onChange={onTypingHandler}
      label={label}
      onSelectItem={onChange}
      selectedItem={selectedItem}
      isLoading={isLoading}
    />
  );
};

Brands.defaultProps = {
  label: "Search for brands ...",
};

export default Brands;

import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PromotionItem from "./PromotionItem";
import { IState } from "store/store.interface";
import { isEmpty } from "utils/app.utils";
import LoaderCampaings from "Loader/Dashboard";
import { useApiInfo } from "hooks";
import { PROMOTION_FETCH_CAMPAIGNS } from "store/store.types";
import { fetchCampaigns } from "store/actions/promotion.actions";
import { Box } from "common";
import EmptyState from "./EmptyState";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useEmptyContainerStyles = makeStyles((theme: Theme) => ({
  emptyContainer: {
    gridTemplateColumns: ({ listing }: { listing?: "list" | "grid" }) =>
     "1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr !important",
    },
  }
}))

const useContainerStyles = makeStyles((theme: Theme) => ({
  container: {
    gridTemplateColumns: ({ listing }: { listing?: "list" | "grid" }) =>
      listing && listing === "grid" ? "1fr 1fr" : "1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr !important",
    },
  },
}));
const PromotionListing: FC<{ listing?: "list" | "grid" }> = ({ listing }) => {
  const { dashboard, newCampaign } = useSelector(
    (state: IState) => state.promotion
  );
  const { container } = useContainerStyles({ listing });
  const { emptyContainer } = useEmptyContainerStyles({listing});

  const dispatch = useDispatch();
  const { isLoading } = useApiInfo(PROMOTION_FETCH_CAMPAIGNS);

  useEffect(() => {
    if (newCampaign) {
      dispatch(fetchCampaigns());
    }
  }, [newCampaign, dispatch]);

  useEffect(() => {
    if (!dashboard) {
      dispatch(fetchCampaigns());
    }
  }, [dispatch, dashboard]);

  if (isEmpty(dashboard) && isLoading) {
    return <LoaderCampaings />;
  }
  return (
    <Box className={(dashboard && dashboard.length > 0) ? container : emptyContainer} width="100%" gridGap="25px">
      {dashboard && dashboard.length > 0 ? (
        <>
          {dashboard.map((promotion, index) => {
            const { code } = promotion;
            return (
              <PromotionItem
                promotion={promotion}
                key={`${code}_${index}`}
                listing={listing}
              />
            );
          })}
        </>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
};

export default PromotionListing;

import {
  countryNameMapping,
  countryNameToStoreIdMapping,
} from "components/MyProfile/MyProfile.interface";
import { EStores } from "store/reducers/auth.reducers";
import { EPromotionTypes } from "store/store.interface";

export const getCountryDetails = (storeIds: EStores[]) => {
  if (storeIds.length === 15) return "All";
  if (storeIds.length === 0) return "None";
  let str = "";
  storeIds.forEach((country) => (str += countryNameMapping[country] + ", "));
  return str.slice(0, -2);
};

export const getCampaignNames = (
  permissions: {
    [storeId: string]: EPromotionTypes[];
  },
  storeIds: EStores[]
) => {
  let resArray: string[] = [];
  storeIds.forEach((country) => {
    if (permissions && permissions[country]) {
      resArray = [...resArray, ...permissions[country]];
    }
    return resArray;
  });
  const finalArr = resArray.filter(
    (item, index) => resArray.indexOf(item) === index
  );
  return finalArr.length >= 6 ? "All" : finalArr.join();
};

export const setParamTemplate = (
  arr: string[],
  campaign: boolean,
  params: string
) => {
  const strLiteral = campaign ? "&campaigns=" : "&storeIds=";
  let str = params.indexOf(strLiteral) === -1 ? params : "";
  if (arr.length) {
    arr.forEach((item) => {
      const param = campaign ? item : countryNameToStoreIdMapping[item];
      str += strLiteral + param;
      return str;
    });
    return str;
  }
  return "";
};
export const refineValues = ( permissions: {
  [storeId: string]: EPromotionTypes[];
}) => {
  Object.keys(permissions).forEach(item => {
     if(permissions[item] === undefined  || permissions[item].length === 0 )
     delete permissions[item];
  })
  return permissions;
}

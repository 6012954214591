/* eslint-disable no-use-before-define */
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
import React, { useCallback, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  AutocompleteRenderGroupParams,
} from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Text from "../Text";
import { useListStyles, useVirtualizedStyles } from "./Select.style";
import {
  useStyleFilledInput,
  useStyleOutlinedInput,
  useStyleLabelInput,
} from "../common.style";
import { ISelectProps, TSelectOption } from "./Select.interface";
import Loader from "./Loader";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NativeSelect from "./NativeSelect";
import InputAdornment from "@material-ui/core/InputAdornment";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = React.forwardRef<HTMLDivElement, {children: React.ReactNode}>((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const VirtualizeSelect: React.FC<ISelectProps> = (props) => {
  const {
    disable,
    disableUnderline,
    fontSize,
    fontWeight,
    fontColor,
    color,
    error,
    errorMessage,
    label,
    variant,
    withCountryFlagLabel,
    withCountryFlagList,
    noCloseIcon,
    name,
    listItemFontSize,
    listItemFontWeight,
    inputVariant,
    customRenderOption,
    listWidth,
    searchIconPosition,
    placeholder,
    searchIconSpacing,
    width,
    height,
    testId,
    id,
    ...rest
  } = props;
  // Classes applied to autocomplete component
  const classes = useVirtualizedStyles({
    width,
    height,
    searchIconSpacing,
    variant,
    color,
  });

  const listClasses = useListStyles({ listWidth });

  // classes applied to the filled input
  const filledInputClasses = useStyleFilledInput({
    ...props,
    isSelect: true,
    isLabeled: props.label ? true : false,
  });

  // classes applied to the outlined input
  const outlinedInputClasses = useStyleOutlinedInput({
    ...props,
    isSelect: true,
    isLabeled: props.label ? true : false,
  });

  // classes applied to the outlined input
  // const outlinedInputClasses = useStyleOutlinedInput();

  const labelFilledClasses = useStyleLabelInput({
    ...props,
    inputVariant: "filled",
  });

  const labelOutlinedClasses = useStyleLabelInput({
    ...props,
    inputVariant: "outlined",
  });

  const theme = useTheme();

  // function to get label based on props
  const getOptionLabel = ({ label = "" }: TSelectOption) => {
    return label;
  };

  //render option in the list if with country flag or not
  const renderOption = ({ label }: TSelectOption) => {
    return (
      <Text
        noWrap
        fontSize={listItemFontSize}
        fontWeight={listItemFontWeight}
        color={fontColor}
      >
        {label}
      </Text>
    );
  };

  // styles applied to chips if multiple is true
  const chipProps = useMemo(() => {
    if (color === "primary") {
      return {
        style: {
          backgroundColor: theme.palette.primary.light,
          marginTop: "10px",
        },
      };
    }
    return {
      style: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: "10px",
      },
    };
  }, [color, theme]);

  // render Text fieeld based on input variant , TextField cannot accept re-rendering based on inputVariant
  // need to be stored in a memo
  const renderInputComponent = useCallback(
    (params: any) => {
      if (inputVariant === "filled") {
        return (
          <TextField
            {...params}
            helperText={error ? errorMessage : ""}
            data-testid={testId || "common-filled-select-input"}
            error={error}
            disabled={disable}
            InputLabelProps={{
              classes: labelFilledClasses,
            }}
            inputProps={{
              ...params.inputProps,
              name,
              autoComplete: "off",
              id,
            }}
            InputProps={{
              disabled: disable,
              disableUnderline: error ? false : disableUnderline,
              error: error,
              classes: filledInputClasses,
              placeholder: placeholder,
              ...params.InputProps,
              startAdornment: variant === "search" &&
                searchIconPosition === "start" && (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
            }}
            variant="filled"
            label={label}
            color={error ? "primary" : color}
          />
        );
      }
      return (
        <TextField
          {...params}
          helperText={error ? errorMessage : ""}
          error={error}
          data-testid={testId || "common-outlined-select-input"}
          InputLabelProps={{
            classes: labelOutlinedClasses,
          }}
          disabled={disable}
          inputProps={{
            ...params.inputProps, 
            name,
            autoComplete: "off",
            id,
          }}
          InputProps={{
            disabled: disable,
            disableUnderline: error ? false : disableUnderline,
            error: error,
            classes: outlinedInputClasses,
            placeholder: placeholder,
            ...params.InputProps,
            startAdornment: variant === "search" &&
              searchIconPosition === "start" && (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
          }}
          variant="outlined"
          label={label}
          color={color}
        />
      );
    },
    [
      disable,
      inputVariant,
      color,
      error,
      disableUnderline,
      errorMessage,
      label,
      filledInputClasses,
      outlinedInputClasses,
      name,
      labelFilledClasses,
      labelOutlinedClasses,
      searchIconPosition,
      variant,
      classes,
      placeholder,
      testId,
      id,
    ]
  );

  const autoCompleteClasses = useMemo(() => {
    if (listWidth) {
      return {
        ...classes,
        ...listClasses,
      };
    }
    return classes;
  }, [classes, listClasses, listWidth]);

  const popupIcon = useMemo(() => {
    if (variant === "search" && searchIconPosition === "end") {
      return <SearchIcon />;
    } else if (variant === "default") {
      return <ArrowDropDownIcon />;
    }

    return null;
  }, [variant, searchIconPosition]);
  if (variant !== "native") {
    return (
      <Autocomplete
        disabled={disable}
        disableListWrap
        classes={autoCompleteClasses}
        ListboxComponent={
          ListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        loadingText={<Loader />}
        renderGroup={renderGroup}
        getOptionLabel={getOptionLabel as any}
        ChipProps={chipProps}
        popupIcon={popupIcon}
        closeIcon={noCloseIcon && ""}
        autoComplete={true}
        renderInput={renderInputComponent}
        renderOption={customRenderOption || renderOption}
        {...rest}
        size={rest.size !== "tiny" ? rest.size : "small"}
      />
    );
  }
  return <NativeSelect {...props} />;
};

VirtualizeSelect.defaultProps = {
  variant: "default",
  disableUnderline: false,
  inputVariant: "filled",
  searchIconPosition: "end",
  searchIconSpacing: "0px",
};

export default VirtualizeSelect;

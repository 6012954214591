import { Box ,Text} from "common";
import { useStyles } from "./MyProfile.style";
import React from 'react'

export const AccountDetails = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => {
  const { profileSubContainer, headingSubText, headingText } = useStyles();
  return (
    <Box className={profileSubContainer}>
      <Box gridGap={10} ml={1}>
        <Text className={headingText}>{heading}</Text>
        <Text className={headingSubText}>{subheading}</Text>
      </Box>
    </Box>
  );
};

import { Modal } from "@material-ui/core";
import React from "react";
import { useStyles } from "./Users.style";
import { Box, Button, Text } from "common";
import { deleteUserById } from "store/actions/user.actions";
import { useDispatch } from "react-redux";

const DelteUserModal = ({
  isOpen,
  handleClose,
  email,
  id,
}: {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
  id: string;
}) => {
  const { deleteModal, normalText, boldText } = useStyles();
  const dispatch = useDispatch();
  const deleteUser = () => {
    dispatch(deleteUserById(id));
    handleClose();
  };
  return (
    <Modal open={isOpen} onClose={handleClose} disableAutoFocus>
      <Box className={deleteModal}>
        <Text className={normalText}>
          Are you sure you want to delete this user?
        </Text>
        <Text className={boldText}>{email}</Text>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Button
            fontSize="14px"
            fontWeight={600}
            padding="10px"
            round
            width="90%"
            color="primary"
            variant="outlined"
            margin="10px"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            fontSize="14px"
            fontWeight={600}
            padding="10px"
            width="90%"
            round
            onClick={deleteUser}
            color="secondary"
            margin="10px"
            variant="contained"
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DelteUserModal;

export const ECampaignsType = [
  "DISCOUNT_COUPON",
  "BADGE_CAMPAIGN",
  "BIN_CAMPAIGN",
  "BMSM_CAMPAIGN",
  "DISCOUNT_COUPON2",
];
export const campaignNameMapping: { [key: string]: string } = {
  DISCOUNT_COUPON: "Custom Coupons",
  BIN_CAMPAIGN: "Bank (BIN) Discount",
  BADGE_CAMPAIGN: "Promo Badges",
  BMSM_CAMPAIGN: "Buy More Save More",
  DISCOUNT_COUPON2: "Bulk Coupons",
};

export const countryNameMapping: { [key: string]: string } = {
  mafuae: "UAE",
  mafjor: "JOR",
  maflbn: "LEB",
  mafegy: "EGP",
  mafsau: "KSA",
  mafpak: "PAK",
  mafkwt: "KUW",
  mafbhr: "BAH",
  mafomn: "OM",
  mafken: "KEN",
  mafgeo: "GEO",
  mafqat: "QAT",
  mafirq: "IRQ",
  mafirn: "IRN",
  mafuzb: "UZB",
};

export const countryNameToStoreIdMapping: { [key: string]: string } = {
  UAE: "mafuae",
  Jordan: "mafjor",
  Lebanon: "maflbn",
  Egypt: "mafegy",
  "Saudi Arabia": "mafsau",
  Pakistan: "mafpak",
  Kuwait: "mafkwt",
  Bahrain: "mafbhr",
  Oman: "mafomn",
  Kenya: "mafken",
  Georgia: "mafgeo",
  Qatar: "mafqat",
  Iraq: "mafirq",
  Iran: "mafirn",
  Uzbekistan: "mafuzb",
};

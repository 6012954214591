import { EStores, ILoginState } from "../reducers/auth.reducers";
import { Dispatch } from "redux";
import { apiAction } from "../middlewares/api.middleware.helper";
import { endpoints } from "constant";
import Cookie from "universal-cookie";
import {
  AUTH_LOGIN,
  AUTH_VERIFY_SESSION,
  AUTH_SET_COUNTRY,
  AUTH_GET_USER,
  AUTH_LOGOUT,
  AUTH_UPDATE_LOCATION,
} from "store/store.types";
import { EMethods, IState } from "store/store.interface";

const cookie = new Cookie();

const getAndSetUserInfo = (loginData: ILoginState) =>
  apiAction({
    label: AUTH_GET_USER,
    method: EMethods.GET,
    url: endpoints.USER_INFO,
    isAbsoluteUrl: false,
    data: {},
    headers: {
      Authorization: `${loginData.token_type} ${loginData.access_token}`,
    },
    onSuccess: ({ data }) =>
      setLoginToState({ ...loginData, ...data } as ILoginState),
  });

export const setLocation = (path: string) => ({
  type: AUTH_UPDATE_LOCATION,
  payload: path,
});

export const login = (email: string, password: string) =>
  apiAction({
    label: AUTH_LOGIN,
    method: EMethods.POST,
    url: endpoints.LOGIN,
    isAbsoluteUrl: false,
    data: {
      username: email,
      password: password,
    },
    onSuccess: ({ data }) => getAndSetUserInfo(data),
  });

export const setStoreId =
  (storeId: EStores) => (dispatch: Dispatch, getState: () => IState) => {
    const { selectOptions = {} } = getState().config;
    const { countries } = selectOptions;
    let timeZone = "Asia/Dubai";
    if (countries && countries.length > 0) {
      timeZone =
        (
          countries.find((country) => country.value === storeId) || {
            timeZone: "Asia/Dubai",
          }
        ).timeZone || "Asia/Dubai";
    }
    cookie.set("pe_storeid", storeId);
    cookie.set("pe_tz", timeZone);
    dispatch({ type: AUTH_SET_COUNTRY, payload: { storeId, timeZone } });
  };

export const validateSession =
  () => (dispatch: Dispatch, getState: () => IState) => {
    const {
      auth: { refresh_token, access_token, token_type = "Bearer" },
    } = getState();
    dispatch(
      apiAction({
        label: AUTH_VERIFY_SESSION,
        url: endpoints.REFRESH_TOKEN,
        data: {
          refreshToken: refresh_token,
        },
        headers: {
          storeid: "mafuae",
          Authorization: `${token_type} ${access_token}`,
        },
        method: EMethods.POST,
        onSuccess:
          ({ data: loginData }) =>
          (dispatch: Dispatch) => {
            dispatch(getAndSetUserInfo(loginData));
          },
        onFailure: () => (dispatch: Dispatch) => {
          dispatch(signout() as any);
        },
      })
    );
  };

export const signout = () => (dispatch: Dispatch) => {
  cookie.remove("pe_refresh_token");
  cookie.remove("pe_expires_at");
  cookie.remove("pe_access_token");
  cookie.remove("pe_token_type");
  cookie.remove("pe_storeid");
  cookie.remove("pe_tz");
  dispatch({ type: AUTH_LOGOUT });
};

const setLoginToState = (result: ILoginState) => (dispatch: Dispatch) => {
  setSession(result);
  dispatch({
    type: AUTH_LOGIN,
    payload: result,
  });
};

const setSession = (loginState: ILoginState) => {
  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify(
    loginState.expires_in * 1000 + new Date().getTime()
  );
  cookie.set("pe_refresh_token", loginState.refresh_token);
  cookie.set("pe_access_token", loginState.access_token);
  cookie.set("pe_token_type", loginState.token_type);
  cookie.set("pe_expires_at", expiresAt);
};

import React from "react";
import Layout from "Layout";
import Section from "Layout/Section";
import UserComponent from "components/Users";


const Users: React.FC = () => {
  
  return (
    <Layout>
      <Section>
        <UserComponent />
      </Section>
    </Layout>
  );
};

export default Users;

import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface IStylesProps {
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  withOddHighlight?: boolean;
}
const useStyles = makeStyles(
  createStyles({
    root: {
      backgroundColor: ({ backgroundColor = "#fff" }: IStylesProps) =>
        backgroundColor,
      width: ({ width = "100%" }) => width,
      height: ({ height = 400 }) => height,
      maxWidth: ({ maxWidth = 300 }) => maxWidth,
      minWidth: ({ minWidth = "auto" }) => minWidth,
      minHeight: ({ minHeight = "auto" }) => minHeight,
      maxHeight: ({ maxHeight = "auto" }) => maxHeight,
    },
  })
);

const renderRow = (props: ListChildComponentProps) => {
  const { index, style, data } = props;
  const item = data[index];

  return (
    <ListItem style={{ ...style, paddingLeft: "0px" }} key={index}>
      <ListItemText primary={item} />
    </ListItem>
  );
};

const VirtualizedList: React.FC<{
  itemData: any;
  height: number | string;
  width: number | string;
  itemCount: number;
  itemSize: number;
  containerStyles?: IStylesProps;
  button?: boolean;
}> = (props) => {
  const classes = useStyles({ ...props.containerStyles });

  return (
    <div className={classes.root}>
      <FixedSizeList {...props}>{renderRow}</FixedSizeList>
    </div>
  );
};

export default VirtualizedList;

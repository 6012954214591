import { Divider } from "@material-ui/core";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { Box, Button, Card, Text } from "common";
import COLORS from "common/Theme/colors";
import React, { useState } from "react";
import { IUserData } from "store/reducers/users.reducers";
import DeleteUserModal from "./DeleteUserModal";
import UserHeaderTemplate from "./UserHeaderTemplate";
import { getCampaignNames, getCountryDetails } from "./user.helper";
import { getUserById, getUsers } from "store/actions/user.actions";
import { useDispatch } from "react-redux";
import { useStyles } from "./Users.style";
import EditUserDrawer from "./EditUser/EditUserDrawer";

const UserTable = ({
  userDetails,
  pageNumber,
  params,
}: {
  userDetails: IUserData[];
  pageNumber: number;
  params: string;
}) => {
  const [open, setOpen] = useState(new Array(8).fill(false));
  const [isEditIconOpen, setIsEditIconOpen] = useState(
    new Array(8).fill(false)
  );
  const handleClose = (index: number) => () => {
    let arr = [...open];
    arr[index] = !open[index];
    setOpen(arr);
  };
  const handleEditIconClose = (index: number) => () => {
    let arr = [...isEditIconOpen];
    arr[index] = !isEditIconOpen[index];
    if(arr[index]) dispatch(getUserById(userDetails[index].id))
    setIsEditIconOpen(arr);
  };
  const dispatch = useDispatch();

  const handleNextClick = () => {
    dispatch(getUsers(pageNumber + 1, params));
  };
  const handlePrevClick = () => {
    dispatch(getUsers(pageNumber - 1, params));
  };
  const { arrowButton } = useStyles();
  return (
    <Card
      padding="10px"
      borderRadius="8px"
      boxShadow=" 0px 2px 4px rgba(0, 0, 0, 0.1)"
      marginTop="15px"
    >
      <Box
        display="grid"
        gridTemplateColumns="2fr 2fr 1fr 2fr 2fr 1fr"
        gridGap={2}
        alignItems="center"
        p={2}
        justifyContent="space-between"
      >
        <UserHeaderTemplate title="User ID/Email" variant={"bold"} />
        <UserHeaderTemplate title="Name" variant={"bold"} />
        <UserHeaderTemplate title="Role" variant={"bold"} />
        <UserHeaderTemplate title="Campaign Type" variant={"bold"} />
        <UserHeaderTemplate title="Country" variant={"bold"} />
        <UserHeaderTemplate title="Actions" variant={"bold"} />
      </Box>
      <Divider />
      {userDetails.map((user: IUserData, index: number) => {
        return (
          <>
            <Box
              display="grid"
              gridTemplateColumns="2fr 2fr 1fr 2fr 2fr 1fr"
              alignItems="center"
              p={2}
              gridGap={2}
              justifyContent="space-between"
            >
              <UserHeaderTemplate
                title={user.email}
                variant="bold"
                colorVariant="blue"
              />
              <UserHeaderTemplate title={user.name} />
              <UserHeaderTemplate
                title={user.roles[0]}
                style={{ textTransform: "capitalize" }}
              />
              <UserHeaderTemplate
                title={getCampaignNames(user.permissions, user.storeIds)}
                showTooltip={true}
              />
              <UserHeaderTemplate
                title={getCountryDetails(user.storeIds)}
                showTooltip={true}
              />
              <Box display="flex" color={COLORS.BLUE} ml={1}>
                <EditUserDrawer
                  isOpen={isEditIconOpen[index]}
                  userDetails={userDetails[index]}
                  drawerHandler={handleEditIconClose(index)}
                />
                <div
                  onClick={handleEditIconClose(index)}
                  style={{ cursor: "pointer" }}
                  data-testid="editIcon"
                >
                  <EditOutlined />
                </div>
                <Box ml={2}></Box>
                <DeleteUserModal
                  isOpen={open[index]}
                  handleClose={handleClose(index)}
                  email={user.email}
                  id={user.id}
                />
                <div
                  onClick={handleClose(index)}
                  style={{ cursor: "pointer" }}
                  data-testid="deleteIcon"
                >
                  <DeleteOutlineOutlined />
                </div>
              </Box>
            </Box>
            <Divider />
          </>
        );
      })}
      {userDetails.length === 0 && (
        <Text
          fontSize="18px"
          fontWeight={600}
          lineHeight="32px"
          mt={1}
          color={COLORS.GRAY_SCALE_500}
          textAlign="center"
          marginTop={2}
        >
          No more Users
        </Text>
      )}
      <Box my={3} mr={4} display={"flex"} justifyContent="flex-end">
        <Button
          color="primary"
          onClick={handlePrevClick}
          disabled={pageNumber === 0}
          className={arrowButton}
          id="previousBtn"
        >
          <KeyboardArrowLeft />
        </Button>
        <Button
          color="primary"
          onClick={handleNextClick}
          className={arrowButton}
          disabled={userDetails.length === 0}
          id="nextBtn"
        >
          <KeyboardArrowRight />
        </Button>
      </Box>
    </Card>
  );
};

export default UserTable;

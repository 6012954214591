import { endpoints } from "constant";
import { Dispatch } from "react";
import { apiAction } from "store/middlewares/api.middleware.helper";
import { EMethods } from "store/store.interface";
import { CHANGE_PASSWORD } from "store/store.types";

export const changePassword = (oldPassword: string, newPassword: string,confirmPassword:string,access_token:string) => (dispatch: Dispatch<any>) => {
 dispatch(
  apiAction({
    label: CHANGE_PASSWORD,
    method: EMethods.PUT,
    url: endpoints.CHANGE_PASSWORD,
    isAbsoluteUrl: false,
    withToken: true,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    data: {
        oldPassword,
        newPassword,
        confirmPassword
    },
    onSuccess: (data) => (
      {
      type: CHANGE_PASSWORD,
      payload: {data},
    }),
  }))
}


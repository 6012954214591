import { TSelectOption } from "common/Select/Select.interface";
import {
  ECampaignStatus,
  IPromotionForm,
  TRestrictionType,
} from "store/reducers/promotion.reducers";
import { restrictionNoDisableFields } from "store/store.interface";

const mutualFieldsMapping: any = {
  sellerType: "shipmentType",
  shipmentType: "sellerType",
};

export const filterAlreadySelectedRestriction = (
  options: TSelectOption[],
  restrictionFieldsNames: string[]
) => {
  return options.filter((option) => {
    return restrictionFieldsNames.indexOf(option.value) === -1;
  });
};

export const filterAlternateRestriction = (
  options: TSelectOption[],
  restrictionFieldsNames: string[]
) => {
  return options.filter((option) => {
    const restrictedField = mutualFieldsMapping[option.value];
    return !(restrictionFieldsNames.indexOf(restrictedField) > -1);
  });
};

export const isEditingPausedCampaign = (creationForm: IPromotionForm) => {
  return creationForm.edit && creationForm.status === ECampaignStatus.paused;
};

export const filterRestrictionForPausedCampaign = (
  field: TRestrictionType,
  creationForm: IPromotionForm
) => {
  const option = { label: field.label, value: field.name };

  if (isEditingPausedCampaign(creationForm)) {
    if (field.type === "file" || field.name === 'numberOfUsage' || field.name === 'budget') {
      return option;
    } else {
      return { label: "", value: "" };
    }
  }

  return option;
};

export const overrideRestrictionDisable = (
  restriction: TRestrictionType,
  creationForm: IPromotionForm,
  disable: boolean
) => {
  const { name, type } = restriction;
  if (
    restrictionNoDisableFields.includes(name) ||
    (isEditingPausedCampaign(creationForm) && type === "file")
  ) {
    return false;
  }

  return disable;
};

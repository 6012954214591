import React ,{useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/store.interface";
import { Box, Text, Button } from "common";
import { MagnifyGlass } from "@mafc/icon";
import { resetFilters } from "store/actions/promotion.actions";

const EmptyState: React.FC = () => {
  const { filters, selectOptions, storeId } = useSelector((state: IState) => ({
    ...state.promotion,
    ...state.config,
    ...state.auth,
  }));

  const countryName = useMemo(() => {
    if (selectOptions) {
      const { countries = [] } = selectOptions;
      return (countries.find((country) => country.value === storeId) || {})
        .label;
    }
    return "";
  }, [storeId, selectOptions]);

  const dispatch = useDispatch();

  const onResetFilter = () => {
    dispatch(resetFilters());
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      height="300px"
      alignItems="center"
      flexDirection="column"
      data-testid="empty-state-container"
    >
      <MagnifyGlass size="XL" fill="#4D4D4D" />
      <Text
        my="20px"
        fontSize="18px"
        color="text.secondary"
        data-testid="empty-state-text"
      >
        No campaign found{" "}
        {(!filters || filters.length === 0) && countryName
          ? `in ${countryName}`
          : ""}
      </Text>
      {filters && filters.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          round
          onClick={onResetFilter}
          minWidth="200px"
          data-testid="clear-filter-button"
        >
          CLEAR FILTERS
        </Button>
      )}
    </Box>
  );
};

export default EmptyState;

import React, { useState } from "react";

import { Button, Box } from "common";
import { Checkbox, MenuItem } from "@material-ui/core";
import { useStyles } from "./Users.style";
import { setParamTemplate } from "./user.helper";
import { useDispatch } from "react-redux";
import { getUsers } from "store/actions/user.actions";

const MenuDropdown = ({
  options,
  isMenuOpen,
  campaign = false,
  menuOpenHandler,
  params,
}: {
  options: string[];
  isMenuOpen: boolean;
  campaign?: boolean;
  menuOpenHandler: any;
  params: string;
}) => {
  const { dropdownBox } = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const dispatch = useDispatch();
  const handleChange = (event: any) => {
    const index = selected.indexOf(event.target.name);
    if (index <= -1) {
      setSelected([...selected, event.target.name]);
    } else {
      setSelected([
        ...selected.slice(0, index),
        ...selected.slice(index + 1, selected.length),
      ]);
    }
  };
  const clearState = () => {
    setSelected([]);
  };
  const applyFilters = () => {
    menuOpenHandler(!isMenuOpen);
    dispatch(getUsers(0, setParamTemplate(selected, campaign, params)));
  };

  return (
    <Box
      display={isMenuOpen ? "" : "none"}
      className={dropdownBox}
      style={campaign ? { right: "10%" } : {}}
    >
      {options.map((item) => {
        return (
          <MenuItem>
            <Checkbox
              data-testid="checkbox"
              checked={selected.includes(item)}
              onChange={handleChange}
              name={item}
              color="primary"
            />
            {item}
          </MenuItem>
        );
      })}
      <Box display={"flex"} alignItems="center" justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          color="primary"
          round
          margin="10px"
          onClick={clearState}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="secondary"
          round
          margin="10px"
          onClick={applyFilters}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default MenuDropdown;

import { Drawer } from "@material-ui/core";
import { Box, Text } from "common";
import React, { useState } from "react";
import { IUserData } from "store/reducers/users.reducers";
import AddUserComponent from "../AddUser";
import { BottomButtonTemplate } from "../AddUser/InputFieldTemplate";
import { CountryCampaignField, TextField } from "./TextFieldTemplate";

const EditUserDrawer = ({
  isOpen,
  userDetails,
  drawerHandler,
}: {
  isOpen: boolean;
  userDetails: IUserData;
  drawerHandler: any;
}) => {
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const handleEditClick = () => {
    drawerHandler(false);
    setAddUserDrawerOpen(true);
  };
  return (
    <>
      <AddUserComponent
        isDrawerOpen={addUserDrawerOpen}
        drawerHandler={setAddUserDrawerOpen}
        isEditable={true}
      />
      <Drawer open={isOpen} anchor={"right"}>
        <Box
          display="flex"
          flexDirection="column"
          width={"580px"}
          padding={3}
          justifyContent="space-between"
          style={{ position: "relative" }}
        >
          <Text fontSize={24} fontWeight={700}>
            EDIT USER
          </Text>
          <Text fontSize={16} fontWeight={700} my={2}>
            Basic Details
          </Text>
          <Box display="flex">
            <TextField title={"Name"} value={userDetails.name} />
            <TextField title={"Email"} value={userDetails.email} />
          </Box>
          <Text fontSize={16} fontWeight={700} my={2}>
            Access Information
          </Text>
          <TextField title={"Role"} value={userDetails.roles[0]} />
          {userDetails.storeIds.map((country) => {
            return (
              <CountryCampaignField
                country={country}
                campaigns={userDetails.permissions[country]}
                isLast={
                  country ===
                  userDetails.storeIds[userDetails.storeIds.length - 1]
                }
              />
            );
          })}
          <BottomButtonTemplate
            onCancelClick={drawerHandler}
            onAddUserHandler={handleEditClick}
            title="Edit User"
            testid="edit-user-btn"
          />
        </Box>
      </Drawer>
    </>
  );
};

export default EditUserDrawer;

import { Divider } from "@material-ui/core";
import { Box, Text } from "common";
import { countryNameMapping } from "components/MyProfile/MyProfile.interface";
import React from "react";
import { EPromotionTypes } from "store/store.interface";
import { useStyles } from "../Users.style";

export const TextField = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Box width={"50%"} gridGap={4}>
      <Text fontSize={14}>{title}</Text>
      <Text fontSize={14}>{value}</Text>
    </Box>
  );
};

export const CountryCampaignField = ({
  country,
  campaigns,
  isLast,
}: {
  country: string;
  campaigns: EPromotionTypes[];
  isLast: boolean;
}) => {
  const { campaignNameChip } = useStyles();
  return (
    <Box mb={isLast ? 5 : 0}>
      <Box my={2} gridGap={4}>
        <TextField title="Country" value={countryNameMapping[country]} />
        <Text fontSize={14}>Campaigns</Text>
        <Box display={"flex"} flexWrap="wrap">
          {campaigns &&
            campaigns.map((campaign) => {
              return <Box className={campaignNameChip}>{campaign}</Box>;
            })}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

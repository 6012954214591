import React, { FC, useMemo } from "react";
import CampaignsLoader from "Loader/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/store.interface";
import {
  fetchCampaigns,
  searchForCampaigns,
  updatePageNumber,
} from "store/actions/promotion.actions";
import { useApiInfo } from "hooks";
import { PROMOTION_FETCH_CAMPAIGNS } from "store/store.types";
import { isEmpty } from "utils/app.utils";
import { Box, Link } from "common";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useStyles } from "./Dashboard.style";

const LoadMore: FC<{ title?: string; testId?: string }> = ({
  title = "Show more",
  testId = "load-more-link",
}) => {
  const classes = useStyles({});
  const { dashboard, total, limit, page, searchTerm, filters } = useSelector(
    (state: IState) => ({
      ...state.promotion,
      ...state.config,
      ...state.auth,
    })
  );
  const { isLoading: isFetchingCampaigns } = useApiInfo(
    PROMOTION_FETCH_CAMPAIGNS
  );
  const dispatch = useDispatch();

  const onClick = () => {
    if (shouldShowLoadMore) {
      const newPageNumber = page + 1;
      dispatch(updatePageNumber(newPageNumber));
      if (searchTerm) {
        dispatch(searchForCampaigns({ searchTerm, page: newPageNumber }));
      } else {
        dispatch(fetchCampaigns({ page: newPageNumber, filters }));
      }
    }
  };

  const isLoading = useMemo(() => {
    return isFetchingCampaigns && !isEmpty(dashboard);
  }, [isFetchingCampaigns, dashboard]);

  const shouldShowLoadMore = useMemo(() => {
    // the condition is total + limit , because only in the next round of getting campaigns will
    // know if the list will be empty then the total will not increase,
    return (page + 1) * limit <= total + limit;
  }, [page, limit, total]);

  if (shouldShowLoadMore) {
    if (isLoading) {
      return <CampaignsLoader />;
    }
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        data-testid={testId}
        mt="30px"
      >
        <Link
          variant="default"
          color="primary"
          onClick={onClick}
          data-testid="load-more-button"
          className={classes.loadMore}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            fontSize="16px"
          >
            <span>{title}</span>
            <ExpandMore color="primary" />
          </Box>
        </Link>
      </Box>
    );
  }
  return null;
};

export default LoadMore;

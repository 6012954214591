import {
    UAEFlag,
    JordanFlag,
    LebanonFlag,
    EgyptFlag,
    KSAFlag,
    PakistanFlag,
    KuwaitFlag,
    BahrainFlag,
    OmanFlag,
    KenyaFlag,
    GeorgiaFlag,
    QatarFlag,
  } from "@mafc/icon";
  import { EStores } from "store/reducers/auth.reducers";
  import React from 'react'
  
  export const getIcon = (iconName: string | undefined,width?:string,height?:string) => {
    switch (iconName) {
      case "UAEFlag":
      case EStores.mafuae:
        return <UAEFlag size="CUSTOM" width={width} height={height} />;
      case "JordanFlag":
      case EStores.mafjor:
        return <JordanFlag size="CUSTOM" width={width} height={height} />;
      case "LebanonFlag":
      case EStores.maflbn:
        return <LebanonFlag size="CUSTOM" width={width} height={height} />;
      case "EgyptFlag":
      case EStores.mafegy:
        return <EgyptFlag size="CUSTOM" width={width} height={height} />;
      case "KSAFlag":
      case EStores.mafsau:
        return <KSAFlag size="CUSTOM" width={width} height={height} />;
      case "PakistanFlag":
      case EStores.mafpak:
        return <PakistanFlag size="CUSTOM" width={width} height={height} />;
      case "KuwaitFlag":
      case EStores.mafkwt:
        return <KuwaitFlag size="CUSTOM" width={width} height={height} />;
      case "BahrainFlag":
      case EStores.mafbhr:
        return <BahrainFlag size="CUSTOM" width={width} height={height} />;
      case "OmanFlag":
      case EStores.mafomn:
        return <OmanFlag size="CUSTOM" width={width} height={height} />;
      case "KenyaFlag":
      case EStores.mafken:
        return <KenyaFlag size="CUSTOM" width={width} height={height} />;
      case "GeorgiaFlag":
      case EStores.mafgeo:
        return <GeorgiaFlag size="CUSTOM" width={width} height={height} />;
      case "QatarFlag":
      case EStores.mafqat:
        return <QatarFlag size="CUSTOM" width={width} height={height} />;
      default:
        return null;
    }
  };

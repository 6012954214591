import { Cross } from "@mafc/icon";
import { Modal } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { Box, Button, Text } from "common";
import COLORS from "common/Theme/colors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearApi } from "store/actions/api.actions";
import { changePassword } from "store/actions/changePassword.actions";
import { IState } from "store/store.interface";
import { CHANGE_PASSWORD } from "store/store.types";
import { useStyles } from "../MyProfile.style";
import PasswordInputField from "./PasswordInputField";

const ChangePassword = ({
  isOpen,
  handleClose = () => {},
  forceChange = false,
}: {
  isOpen: boolean;
  handleClose?: () => void;
  forceChange?: boolean;
}) => {
  const [values, setValues] = useState<any>({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const dispatch = useDispatch();
  const {
    access_token = "",
    error = "",
    success = false,
  } = useSelector((state: IState) => ({
    ...state.auth,
    ...state.api[CHANGE_PASSWORD],
  }));
  const { changePasswordModal, normalText, submitButton, dangerText } =
    useStyles();

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setValues((values: any) => ({ ...values, [name]: value }));
  };
  const handleClickShowPassword = (type: string) => {
    setValues({ ...values, [type]: !values[type] });
  };
  const closeModal = () => {
    if (error) dispatch(clearApi(CHANGE_PASSWORD));
    handleClose();
  };
  const onSubmitClick = () => {
    dispatch(
      changePassword(
        values.CurrentPassword,
        values.NewPassword,
        values.ConfirmPassword,
        access_token
      )
    );
  };

  const isDisabled =
    !values.CurrentPassword || !values.NewPassword || !values.ConfirmPassword;
  return (
    <Modal open={isOpen && !success} onClose={closeModal}>
      <Box className={changePasswordModal}>
        <Box display="flex" flexDirection="row-reverse">
         {!forceChange && <Button onClick={closeModal} padding="16px" data-testid='close-icon'>
            <Cross size="M" />
          </Button>}
        </Box>
        <Text fontSize={20} fontWeight={700} lineHeight="20px">
          {forceChange
            ? "You must change your password to continue"
            : "Change Password"}
        </Text>
        <Text className={normalText}>
          Use the form below to change the password for your Carrefour account.
        </Text>
        <PasswordInputField
          name="CurrentPassword"
          label="Current Password"
          error={error}
          value={values.CurrentPassword}
          onChangeHandler={onChangeHandler}
          showPassword={values.showCurrentPassword}
          showPasswordHandler={handleClickShowPassword}
          testId='old-password'
        />
        <PasswordInputField
          name="NewPassword"
          label="New Password"
          error={error}
          value={values.NewPassword}
          onChangeHandler={onChangeHandler}
          showPassword={values.showNewPassword}
          showPasswordHandler={handleClickShowPassword}
          testId='new-password'
        />
        {!error && (
          <Box color={COLORS.GRAY_SCALE_500} display="flex" alignItems="center" mt={-1}>
            <Error />
            <Text className={normalText} ml={1}>
              8 Characters, 1 Uppercase, 1 Lowercase and 1 Numeric
            </Text>
          </Box>
        )}
        <PasswordInputField
          name="ConfirmPassword"
          label="Confirm Password"
          error={error}
          value={values.ConfirmPassword}
          onChangeHandler={onChangeHandler}
          showPassword={values.showConfirmPassword}
          showPasswordHandler={handleClickShowPassword}
          testId='confirm-password'
        />
        {error && (
          <Box color={COLORS.ERROR_RED} display="flex" alignItems="center" mt={-1}>
            <Error />
            <Text className={dangerText} ml={1}>
              {error}
            </Text>
          </Box>
        )}

        <Button
          className={submitButton}
          variant="contained"
          disabled={isDisabled}
          onClick={onSubmitClick}
          data-testid='submit-btn'
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default ChangePassword;

import React, { FC, Suspense, useState } from "react";
import Loader from "Loader/Form";
import { useStyles } from "./PromotionForm.style";
import { Button, Dialog, Link, Box, Card } from "common";
import { useDispatch, useSelector } from "react-redux";
import { useApiInfo } from "hooks";
import { PROMOTION_CREATE, PROMOTION_UPDATE_STATUS } from "store/store.types";
import { sendCampaignForApproval } from "store/actions/promotion.actions";
import { clearApi } from "store/actions/api.actions";
import { IState } from "store/store.interface";
import ApprovalScreen from "./ApprovalScreen";
import { CircularProgress } from "@material-ui/core";

const formTypes = {
  BASIC: React.lazy(() => import("./BasicInformation")),
  RESTRICTIONS: React.lazy(() => import("./Restrictions")),
  SUMMARY: React.lazy(() => import("./Summary")),
};

export enum EFormTypes {
  BASIC = "BASIC",
  RESTRICTIONS = "RESTRICTIONS",
  SUMMARY = "SUMMARY",
}

interface IProps {
  disable: boolean;
  formType: EFormTypes;
  onNext: () => void;
  onBack: () => void;
  onValidateInputs: (error: any) => void;
  registerField: (field: any) => void;
  getValue: (keys: string[]) => string | Date;
  canGoNext: boolean;
  isLastStep: boolean;
  fieldsErrors?: any;
  onEditBasicInfo?: () => void;
  onEditRestrictions?: () => void;
  unRegisterField: (id: string) => void;
}

const PromotionForm: FC<IProps> = ({
  disable,
  formType = EFormTypes.BASIC,
  onNext,
  onBack,
  onValidateInputs,
  registerField,
  getValue,
  canGoNext,
  isLastStep,
  fieldsErrors,
  onEditBasicInfo,
  onEditRestrictions,
  unRegisterField,
}) => {
  const classes = useStyles();
  const [openApproval, setOpenApproval] = useState(false);
  const { creationForm } = useSelector((state: IState) => state.promotion);
  const Component = formTypes[formType];
  const { fileUploading } = useSelector((state:IState)=>state.promotion); 
  const { isLoading = false, error } = useApiInfo(PROMOTION_CREATE);
  const dispatch = useDispatch();

  const updateApprovalScreen = (status: boolean) => () => {
    setOpenApproval(status);
    onCloseNotification();
  };

  const onSubmit = () => {
    dispatch(clearApi(PROMOTION_CREATE));
    dispatch(sendCampaignForApproval());
  };

  const onBackHandler = () => {
    if (!isLoading) {
      dispatch(clearApi(PROMOTION_CREATE));
      onBack();
    }
  };

  const onCloseNotification = () => {
    dispatch(clearApi(PROMOTION_CREATE));
    dispatch(clearApi(PROMOTION_UPDATE_STATUS));
  };

  return (
    <Card className={classes.container}>
      <Dialog
        open={openApproval}
        onBackdropClick={updateApprovalScreen(false)}
        onClose={updateApprovalScreen(false)}
      >
        <ApprovalScreen
          isLoading={isLoading}
          onCancelHandler={updateApprovalScreen(false)}
          onSendForApproval={onSubmit}
          error={error}
          onCloseNotification={onCloseNotification}
        />
      </Dialog>
      <Suspense fallback={<Loader />}>
        <Component
          disable={disable}
          onValidateInputs={onValidateInputs}
          registerField={registerField}
          getValue={getValue}
          formValues={creationForm}
          fieldsErrors={fieldsErrors}
          onEditBasicInfo={onEditBasicInfo}
          onEditRestrictions={onEditRestrictions}
          unRegisterField={unRegisterField}
        />
      </Suspense>
      <Box
        display="flex"
        justifyContent={["center", "flex-end", "flex-end", "flex-end"]}
        alignItems="center"
        flexDirection={["column-reverse", "row", "row", "row"]}
        flex={1}
        width="100%"
      >
        <Link
          onClick={onBackHandler}
          fontWeight={500}
          mr="15px"
          color="primary"
        >
          Back
        </Link>
        <Box className={classes.buttonWrapper}>
          <Button
            color="secondary"
            variant="contained"
            round
            width="auto"
            minWidth="240px"
            type="button"
            onClick={isLastStep ? updateApprovalScreen(true) : onNext}
            disabled={isLastStep ? isLoading : !canGoNext}
          >
            {
             fileUploading ? <CircularProgress/> : isLastStep ? "Send for approval" : "Continue"
            }
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default PromotionForm;
